import * as React from 'react';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import {
  actionReloadSession,
  dispatchActionAppUpdateState,
  SantaAppState,
  store,
  UserAccountInfoState,
} from '@src/state';
import { AccountOutput, SessionObject } from '@lub0v/santa-utils';
import { ColumnStart, FormPage, FormPageGroup, Hint, SantaLink, TextMedium } from '@src/components';
import { santaClient } from '@src/state/client';
import { UsernameField } from '@src/pages/account/UsernameField';
import { PasswordFields } from '@src/pages/account/PasswordFields';
import { SocialLink } from '@src/pages/account/SocialLink';
import { EmailField } from '@src/pages/account/EmailField';
import { t } from '@src/translations';
import { LoadingBarMedium, LoadingBarSmall } from '@src/loading/LoadingComponents';
import { NotificationsSettings } from '@src/pages/account/NotificationSettings';
import { DeleteSection } from '@src/helpers/DeleteSection';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface AccountPageProps extends BasePageProps {
  session: SessionObject;
  account: UserAccountInfoState;
}

function AccountPageLoadingComponent(props: BasePageProps) {
  return (
    <FormPage
      title="account_profile_settings"
      onSubmit={() => {}}
      translator={props.translator}
      headerRight={<LoadingBarSmall width={100} />}
    >
      <FormPageGroup title="account_personal_data" translator={props.translator}>
        <ColumnStart>
          <LoadingBarMedium />
          <LoadingBarMedium />
        </ColumnStart>
      </FormPageGroup>
      <FormPageGroup title="account_password">
        <ColumnStart>
          <LoadingBarMedium />
          <LoadingBarMedium />
        </ColumnStart>
      </FormPageGroup>
      <FormPageGroup title="account_social_links">
        <ColumnStart>
          <LoadingBarMedium />
          <LoadingBarMedium />
          <LoadingBarMedium />
        </ColumnStart>
      </FormPageGroup>
    </FormPage>
  );
}

function AccountPageComponent(props: AccountPageProps) {
  const [error, setError] = useState(undefined);
  const [deleteError, setDeleteError] = useState(undefined);

  useEffect(() => {
    santaClient
      .account()
      .then((acc: AccountOutput) => {
        dispatchActionAppUpdateState({
          account: {
            account: acc.data,
            usernameInput: { username: acc.data.username },
            emailInput: { email: acc.data.email },
          },
        });
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  if (props.loading || !props.account || !props.account.account || !props.account.account.id) {
    return <AccountPageLoadingComponent {...props} />;
  }

  const deleteAccount = async () => {
    const res = await santaClient.accountDelete();
    if (res.ok) {
      store.dispatch(actionReloadSession());
      props.history.push('/');
    } else {
      setDeleteError(res.error.message);
    }
  };

  const logoutSection = (
    <SantaLink
      onClick={async () => {
        const res = await santaClient.logout();
        if (res.ok) {
          window.localStorage.removeItem('refresh');
          store.dispatch(actionReloadSession());
          props.history.push('/');
        }
      }}
    >
      <TextMedium orange> {t('account_logout')}</TextMedium>
    </SantaLink>
  );

  return (
    <FormPage
      title="account_profile_settings"
      onSubmit={() => {}}
      translator={props.translator}
      headerRight={logoutSection}
    >
      <FormPageGroup title="account_personal_data" translator={props.translator}>
        <UsernameField {...props} />
        <EmailField {...props} />
      </FormPageGroup>
      <FormPageGroup title="account_notifications_settins">
        <NotificationsSettings />
      </FormPageGroup>
      <FormPageGroup title="account_social_links">
        <SocialLink />
      </FormPageGroup>
      <FormPageGroup title="account_password">
        <PasswordFields {...props} />
      </FormPageGroup>
      <AdLookIBV numAdsDesktop={4} numAdsMobile={1} />
      <FormPageGroup title="account_delete">
        <ColumnStart>
          <DeleteSection
            translator={props.translator}
            button="account_delete_button"
            confirmDeleteWord="account_confirm_delete_word"
            onClick={deleteAccount}
            error={deleteError}
          >
            <Hint style={{ marginBottom: '1rem' }}>account_delete_hint</Hint>
          </DeleteSection>
        </ColumnStart>
      </FormPageGroup>
    </FormPage>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    session: state.session,
    account: state.account,
  };
}

export const AccountPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(AccountPageComponent, {
    headerShadow: false,
    authenticatedOnly: true,
  }),
);
