import * as React from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ColorPanel,
  GridColumn,
  GridContainer,
  Select,
  TextMedium,
  UserCardMini,
  UserCardMiniSimple,
} from '@src/components';
import { CardInfo } from '@lub0v/santa-utils';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { useState } from 'react';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState } from '@src/state';
import _ from 'lodash-es';
import { t, TRANSLATIONS } from '@src/translations';
import { getWardOptions } from '@src/pages/box/boxHelpers';

type Props = BoxPageProps & {
  onError: (error) => void;
};

export function DrawParticipantsListExtended(props: Props) {
  const boxCards: CardInfo[] = props.box.cards;
  const [colorCard, setColorCard] = useState(undefined);
  const [colorIndex, setColorIndex] = useState(-1);
  const [selectWardCard, setSelectWardCard] = useState(undefined);

  const COLOR_CLEAR = '#FFFFFF';

  const setColors = async (card: CardInfo, drawColors: string[]) => {
    const res = await santaClient.updateOtherCard({
      key: props.box.info.box.key,
      id: card.id,
      drawColors,
    });
    if (res.ok) {
      const cards = _.cloneDeep(props.box.cards);
      const crd = cards.find((c) => c.id === card.id);
      crd.drawColors = drawColors;
      dispatchActionAppUpdateState({ box: { cards } });
      props.onError(undefined);
    } else {
      props.onError(res.error.message);
    }
  };

  const getColors = (card: CardInfo) => {
    if (!card.drawColors.length) {
      return ['#FFF', '#FFF'];
    }
    if (card.drawColors.length === 1) {
      return ['#FFF', card.drawColors[0]];
    }
    return card.drawColors;
  };

  const getColorPanel = (card: CardInfo) => {
    if (!colorCard) {
      return null;
    }
    if (card.id === colorCard.id) {
      return (
        <ColorPanel
          style={{ top: '6.3rem' }}
          onChange={async (color: string, isClear: boolean) => {
            setColorCard(undefined);
            setColorIndex(-1);
            const colors = [COLOR_CLEAR, COLOR_CLEAR];
            card.drawColors.forEach((c, i) => {
              colors[i] = c || COLOR_CLEAR;
            });
            colors[colorIndex] = isClear ? '' : color;
            if (colors[0] === colors[1] && colors[0] !== COLOR_CLEAR) {
              return;
            }
            await setColors(card, colors);
          }}
        />
      );
    }
    return null;
  };

  const setWard = async (card: CardInfo, wardId: number) => {
    if (card.wardId === wardId) {
      return;
    }
    const res = await santaClient.updateOtherCard({
      key: props.box.info.box.key,
      id: card.id,
      wardId,
    });
    if (res.ok) {
      const cards = _.cloneDeep(props.box.cards);
      const crd = cards.find((c) => c.id === card.id);
      crd.wardId = wardId;
      dispatchActionAppUpdateState({ box: { cards } });
      props.onError(undefined);
    } else {
      props.onError(res.error.message);
    }
  };

  const getWardCard = (card: CardInfo) => {
    const ward = props.box.cards.find((c) => +c.id === +card.wardId);
    if (!ward) {
      return null;
    }
    return (
      <UserCardMini
        onCloseClick={() => {
          setWard(card, 0);
        }}
        picture={ward.picture}
        avatar={ward.avatar}
        name={ward.username}
        verified={ward.isVerified}
      />
    );
  };

  const DRAW_SELECT_WARD = t(TRANSLATIONS.draw_select_ward);

  const getRow = (card: CardInfo) => (
    <React.Fragment key={card.id}>
      <GridColumn xs={12} sm={6}>
        <UserCardMini
          picture={card.picture}
          avatar={card.avatar}
          name={card.username}
          verified={card.isVerified}
          colors={getColors(card)}
          onColorClick={(index) => {
            setColorCard(card);
            setColorIndex(index);
          }}
          line
        >
          {getColorPanel(card)}
        </UserCardMini>
      </GridColumn>
      <GridColumn xs={12} sm={6}>
        {card.wardId && getWardCard(card) ? (
          getWardCard(card)
        ) : (
          <UserCardMiniSimple>
            {selectWardCard && selectWardCard.id === card.id ? (
              <Select
                options={getWardOptions(card, props.box.cards)}
                value={card.wardId}
                onChange={(wardId) => {
                  setWard(card, wardId);
                }}
              />
            ) : (
              <TextMedium
                orange
                underline
                onClick={() => {
                  setSelectWardCard(card);
                }}
              >
                {DRAW_SELECT_WARD}
              </TextMedium>
            )}
          </UserCardMiniSimple>
        )}
      </GridColumn>
    </React.Fragment>
  );
  const cardHeight = 105;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemCount={boxCards.length}
          itemSize={props.css.mobile ? cardHeight * 2 + 5 : cardHeight}
          width={width}
        >
          {({ index, style }: { index: number; style: any }) => {
            if (boxCards.length > index) {
              const card = boxCards[index];
              return (
                <GridContainer spacing={2} style={style}>
                  {getRow(card)}
                </GridContainer>
              );
            }
            return null;
          }}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}
