import * as React from 'react';
import { CatAnnoyed, ColumnCenter, Header, Layout1, PictureNotice } from '@src/components';
import { t } from '@src/translations';
import { HeaderComponent } from '@src/pages/base/HeaderComponent';
import { FooterComponent } from '@src/pages/base/FooterComponent';
import { LoadingBarSmall } from '@src/loading/LoadingComponents';
import { BoxPageProps } from '@src/pages/box/BoxPage';

export function BoxPageNotFoundComponent(props: BoxPageProps) {
  return (
    <Layout1
      translator={t}
      headerFixed
      header={
        <Header shadow={false} lang={props.lang} link="/">
          {props.loading ? <LoadingBarSmall /> : <HeaderComponent {...props} />}
        </Header>
      }
      footer={<FooterComponent />}
    >
      <ColumnCenter style={{ height: '100%', marginBottom: '4rem' }}>
        <PictureNotice
          translator={props.translator}
          title="box_not_found_title"
          hint="box_not_found_hint"
          button="back_to_home"
          onClick={() => props.history.push('/')}
        >
          <CatAnnoyed />
        </PictureNotice>
      </ColumnCenter>
    </Layout1>
  );
}
