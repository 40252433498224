import * as React from 'react';
import { GridColumn, GridContainer } from '@lub0v/ui-components';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { LoadingCardBig } from '@src/loading/LoadingComponents';

export const BoxParticipantsLoadingComponent = (props: BoxPageProps) => (
  <>
    <GridContainer spacing={props.css.mobile ? 2 : 3}>
      {Array(9)
        .fill(0)
        .map((v, i) => (
          <GridColumn key={i} item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
        ))}
    </GridContainer>
  </>
);
