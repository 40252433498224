import * as React from 'react';
import { useState } from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ButtonMain, ColumnCenter, PictureNotice, UserCard } from '@src/components';
import { CardInfo } from '@lub0v/santa-utils';
import { dispatchActionAppUpdateState, loadBoxCards, loadCard } from '@src/state';
import { santaClient } from '@src/state/client';
import { shuffleArray } from '@lub0v/utils/dist/src/random';

export const BoxWardQuestionComponent = (props: BoxPageProps) => {
  const [showWard, setShowWard] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [currCardIndex, setCurrCardIndex] = useState(-1);

  const { ward } = props.box;
  if (!props.box.cards.length) {
    return <></>;
  }
  const cWard = props.box.cards.find((c) => c.isYourWard);
  const shuffled = shuffleArray(props.box.cards.filter((c) => !c.isYourWard && !c.isYou));
  const l = shuffled.length / 2;
  const h1 = shuffled.slice(0, l);
  const h2 = shuffled.slice(l, shuffled.length);
  const arr = [...h1, cWard, ...h2].filter((c) => !!c);
  const showName = props.box.info.box.useNames || props.box.info.box.useNames;
  const cards = arr.map((card: CardInfo) => (
    // eslint-disable-next-line react/jsx-key
    <UserCard
      picture={card.picture}
      avatar={card.avatar}
      name={showName ? card.username : ''}
      verified={true}
      fixed
      style={{ margin: 'auto' }}
    />
  ));
  const wardCard = (
    <UserCard
      picture={ward.picture}
      avatar={ward.avatar}
      name={ward.username}
      verified={true}
      fixed
      style={{ margin: 'auto' }}
    />
  );

  const setWardKnownOnServer = async () => {
    await santaClient.saveCard({
      key: props.box.info.box.key,
      username: props.box.card.username,
      isWardKnown: true,
    });
  };
  const setWardKnown = async () => {
    setShowWard(true);
    dispatchActionAppUpdateState({ showConfetti: true });
    const res = await santaClient.saveCard({
      key: props.box.info.box.key,
      username: props.box.card.username,
      isWardKnown: true,
    });
    if (res.ok) {
      setTimeout(() => {
        setShowButton(true);
        dispatchActionAppUpdateState({ showConfetti: false });
      }, 5000);
    }
  };
  const showTitles = !(currCardIndex >= 0 || showWard);
  return (
    <ColumnCenter style={{ marginTop: '4rem' }}>
      <PictureNotice
        translator={props.translator}
        title={showTitles ? 'ward_get_ward_title' : null}
        hint={showTitles ? 'ward_get_ward_hint' : null}
        button={showTitles ? 'ward_get_ward_button' : null}
        onClick={() => {
          setWardKnownOnServer();
          if (cards.length > 2) {
            const speeds = [120, 150, 200, 300, 400, 700, 1000];
            const countsPerSpeed = [8, 7, 6, 5, 4, 2, 1];
            let prevCount = 0;
            let prevSpeed = 0;
            let delay = 0;
            for (let s = 0; s < speeds.length; s += 1) {
              delay += prevSpeed * prevCount;
              for (let i = 0; i < countsPerSpeed[s]; i += 1) {
                setTimeout(() => setCurrCardIndex(i % cards.length), delay + i * speeds[s]);
              }
              prevCount = countsPerSpeed[s];
              prevSpeed = speeds[s];
            }
            delay += prevSpeed * prevCount;
            setTimeout(setWardKnown, delay + 900);
          } else {
            setWardKnown();
          }
        }}
      >
        {showWard ? (
          <ColumnCenter>
            {wardCard}
            {showButton ? (
              <ButtonMain
                style={{ marginTop: '2rem' }}
                onClick={() => {
                  loadBoxCards(props.box.info.box.key);
                  loadCard(props.box.info.box.key);
                }}
                translator={props.translator}
              >
                go_to_ward_card
              </ButtonMain>
            ) : null}
          </ColumnCenter>
        ) : currCardIndex >= 0 ? (
          cards[currCardIndex]
        ) : (
          <UserCard
            noName
            picture={'question'}
            name=""
            verified={true}
            fixed
            style={{ margin: 'auto' }}
          />
        )}
      </PictureNotice>
    </ColumnCenter>
  );
};
