import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage } from '@src/pages/withBasePage';
import {
  actionReloadSession,
  CssProps,
  dispatchActionAppUpdateState,
  SantaAppState,
  store,
  UserAccountInfoState,
} from '@src/state';
import { ButtonService, Input, QuickNotification, RowStart, withBase } from '@lub0v/ui-components';
import { validateField } from '@src/utils/validators';
import { santaClient } from '@src/state/client';
import { AccountOutput } from '@lub0v/santa-utils';
import { useState } from 'react';

interface AccountPageProps extends BasePageProps {
  account: UserAccountInfoState;
  css: CssProps;
}

function UserNameFieldComponent(props: AccountPageProps) {
  const [saved, setSaved] = useState(false);

  const savePassword = () => {
    santaClient.accountUpdateUsername(props.account.usernameInput).then((acc: AccountOutput) => {
      dispatchActionAppUpdateState({
        account: { account: acc.data, usernameInput: { username: acc.data.username } },
      });
      store.dispatch(actionReloadSession());
      setSaved(true);
    });
  };
  return (
    <>
      <QuickNotification
        translator={props.translator}
        autoHide={2000}
        green
        onClick={() => {
          setSaved(false);
        }}
        opened={saved}
        style={{ top: props.css.mobile ? '-5.5rem' : '-3rem' }}
      >
        account_username_saved
      </QuickNotification>
      <RowStart translator={props.translator}>
        <Input
          // translator={props.translator}
          label="your_name"
          value={props.account.usernameInput.username}
          validators={[validateField(props.account.usernameInput, 'username')]}
          onChange={(username) => {
            dispatchActionAppUpdateState({ account: { usernameInput: { username } } });
          }}
        />
        <ButtonService
          disabled={props.account.account.username === props.account.usernameInput.username}
          onClick={savePassword}
          style={{ marginLeft: '0.5rem' }}
        >
          save
        </ButtonService>
      </RowStart>
    </>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    session: state.session,
    account: state.account,
    css: state.css,
  };
}

export const UsernameField = withBase(connect(mapStateToProps)(UserNameFieldComponent));
