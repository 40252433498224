import * as React from 'react';
import {
  ColumnCenter,
  GridColumn,
  GridContainer,
  ToggleMenu,
  ToggleMenuButton,
  ToggleMenuItem,
} from '@src/components';
import { LoadingCardBig } from '@src/loading/LoadingComponents';
import { t } from '@src/translations';

export function BoxesLoadingPageComponent() {
  return (
    <>
      <ColumnCenter alignCenter style={{ maxWidth: '30rem', margin: 'auto', marginTop: '2rem' }}>
        <ToggleMenu disabled active={0} button={<ToggleMenuButton loading disabled type="plus" />}>
          <ToggleMenuItem>{t('account_my_boxes')}</ToggleMenuItem>
          <ToggleMenuItem>{t('account_archive')}</ToggleMenuItem>
        </ToggleMenu>
      </ColumnCenter>
      <div style={{ marginTop: '3rem' }}>
        <GridContainer spacing={4}>
          <GridColumn xs={12} sm={6} md={4}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn xs={12} sm={6} md={4}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn xs={12} sm={6} md={4}>
            <LoadingCardBig />
          </GridColumn>
        </GridContainer>
      </div>
    </>
  );
}
