export function reachGoalYaMetrika(event) {
  // @ts-ignore
  if (window.yaCounter) {
    // @ts-ignore
    window.yaCounter.reachGoal(event);
  }
  // @ts-ignore
  if (window.ym && window.ymID) {
    // @ts-ignore
    window.ym(window.ymID, 'reachGoal', event);
  }
}
