import * as React from 'react';
import { useState } from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonService,
  FormPage,
  FormPageGroup,
  Hint,
  HintError,
  Input,
  RowEnd,
  Validator,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { santaClient } from '@src/state/client';
import { DeleteSection } from '@src/helpers/DeleteSection';

export const BoxDeleteComponent = (props: BoxPageProps) => {
  const [error, setError] = useState(undefined);

  const archiveBox = async () => {
    const res = await santaClient.updateBox({
      key: props.box.info.box.key,
      name: props.box.info.box.name,
      isArchived: !props.box.info.box.isArchived,
    });
    if (res.ok) {
      props.history.push('/account/boxes');
    } else {
      setError(res.error.message);
    }
  };

  const deleteBox = async () => {
    const res = await santaClient.deleteBox({
      key: props.box.info.box.key,
    });
    if (res.ok) {
      props.history.push('/account/boxes');
    } else {
      setError(res.error.message);
    }
  };

  const archiveBoxSection = (
    <FormPageGroup title="box_delete_archive_title">
      <Hint style={{ marginBottom: '1rem' }}>box_delete_archive_hint</Hint>
      <DeleteSection
        translator={props.translator}
        button="box_delete_archive_button"
        confirmDeleteWord="box_delete_confirm_archive_word"
        onClick={archiveBox}
        error={error}
      />
    </FormPageGroup>
  );

  const restoreBoxSection = (
    <FormPageGroup title="box_restore_title">
      <Hint style={{ marginBottom: '1rem' }}>box_restore_hint</Hint>
      <DeleteSection
        translator={props.translator}
        button="box_restore_button"
        confirmDeleteWord="box_restore_confirm_archive_word"
        onClick={archiveBox}
        error={error}
      />
    </FormPageGroup>
  );

  return (
    <FormPage title="box_delete_title" translator={props.translator} error={error}>
      {props.box.info.box.isArchived ? restoreBoxSection : archiveBoxSection}
      <FormPageGroup title="box_delete_delete_title">
        <HintError errors={['box_delete_delete_hint']} style={{ marginBottom: '1rem' }} />
        <DeleteSection
          translator={props.translator}
          button="box_delete_delete_button"
          confirmDeleteWord="box_delete_confirm_delete_word"
          onClick={deleteBox}
          error={error}
        />
      </FormPageGroup>
    </FormPage>
  );
};
