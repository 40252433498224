import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ButtonMain } from '@src/components';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState, loadBoxInfo, loadNotifications, loadWard } from '@src/state';
import * as React from 'react';
import { useRef, useState } from 'react';
import { CardInfo, randomize } from '@lub0v/santa-utils';

export function DrawButton(props: BoxPageProps & { onSetError: (err: string) => void }) {
  const [drawConfirm, setDrawConfirm] = useState(false);
  const drawing = useRef(null); // useState(false);

  const drawCards = async (cards: CardInfo[], circleDraw: boolean) => {
    const rCards = cards.map((c) => ({
      id: `${c.id}`,
      wardId: c.wardId > 0 ? `${c.wardId}` : undefined,
      drawColors: c.drawColors.map((col) =>
        col.toUpperCase() === '#FFF' || col.toUpperCase() === '#FFFFFF' ? '' : col,
      ),
    }));
    try {
      const result = randomize(rCards, circleDraw);
      const { key } = props.box.info.box;
      const res = await santaClient.draw({
        key,
        cards: result.map((r) => ({ id: +r.id, wardId: +r.wardId })),
      });
      if (res.ok) {
        props.onSetError(undefined);
        await loadBoxInfo(key);
        await loadWard(key);
        await loadNotifications();
      } else {
        props.onSetError(res.error.message);
      }
    } catch (e) {
      props.onSetError('RANDOMIZER_FAILED');
    } finally {
      drawing.current = false;
      // setDrawing(false);
    }
  };

  const setConfirmDraw = (confirmDraw: boolean) => {
    setDrawConfirm(confirmDraw);

    dispatchActionAppUpdateState({
      modal: {
        opened: confirmDraw,
        title: 'modal_draw_title',
        message: props.box.cards.length > 300 ? 'modal_draw_message_slow' : 'modal_draw_message',
        buttonYes: 'modal_draw_button_yes',
        buttonNo: 'modal_cancel',
        loading: false,
        onCancel: () => {
          setConfirmDraw(false);
        },
        onSubmit: async () => {
          if (!drawing.current) {
            drawing.current = true;
            dispatchActionAppUpdateState({ modal: { loading: true } });
            await drawCards(props.box.cards, props.box.info.box.useCircleDraw);
            setConfirmDraw(false);
          }
        },
      },
    });
    dispatchActionAppUpdateState({ box: { modals: { confirmDraw } } });
  };

  return (
    <>
      <ButtonMain
        loading={drawing.current}
        translator={props.translator}
        onClick={() => {
          if (!drawConfirm) {
            setConfirmDraw(true);
          }
        }}
      >
        draw_button
      </ButtonMain>
    </>
  );
}
