import { BannersInfoOutput } from '@lub0v/santa-utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { santaClient } from '@src/state/client';

import { Banner, GoodsCarousel } from '@src/components';
import { shuffleArray } from '@lub0v/utils/dist/src/random';
import { CssProps, SantaAppState } from '@src/state';
import { connect } from 'react-redux';
import { reachGoalYaMetrika } from '@src/utils/metrika';

type BannerCarouselProps = {
  css: CssProps;
  lang: string;
};

function BannerCarouselInternal(props: BannerCarouselProps) {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    santaClient.getBanners().then((res: BannersInfoOutput) => {
      const b = [...res.data];
      const banns = [];
      for (const banner of b) {
        for (let i = 0; i < banner.weight; i += 1) {
          banns.push(banner);
        }
      }
      setBanners(Array.from(new Set(shuffleArray(banns))));
    });
  }, []);

  if (!banners.length) {
    return null;
  }
  const bannerComp = (banner) => (
    <Banner
      horizontal
      imageHorizontal={banner.imageHorizontal}
      imageVertical={banner.imageVertical}
      imageHorizontalSize={banner.imageHorizontalSize}
      imageVerticalSize={banner.imageVerticalSize}
      imageHorizontalPosition={banner.imageHorizontalPosition}
      imageVerticalPosition={banner.imageVerticalPosition}
      displayName={banner.displayName}
      link={banner.link}
      description={banner.description}
      button={'Перейти на сайт'}
      lang={props.lang}
      adInfo={banner.advertiser_info}
      onClick={() => {
        reachGoalYaMetrika(banner.name);
        reachGoalYaMetrika('banner_clicked');
      }}
    />
  );
  return (
    <GoodsCarousel minPadding={props.css.mobile ? 4 : 0}>{banners.map(bannerComp)}</GoodsCarousel>
  );
}

function mapStateToProps(state: SantaAppState) {
  return {
    lang: state.language,
    css: state.css,
  };
}

export const SantaBannersCarousel = connect(mapStateToProps)(BannerCarouselInternal);
