import * as React from 'react';

import { connect } from 'react-redux';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { SantaAppState } from '@src/state';
import { FormCard } from '@lub0v/ui-components';
import { LoadingBarMedium } from '@src/loading/LoadingComponents';

function NewBoxStepLoadingComponent() {
  return (
    <FormCard title="..." step={''}>
      <LoadingBarMedium />
      <LoadingBarMedium />
      <LoadingBarMedium />
      <LoadingBarMedium />
    </FormCard>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    register: state.register,
    boxCreate: state.boxCreate,
  };
}

export const NewBoxStepLoading = connect(mapStateToProps)(NewBoxStepLoadingComponent);
