import * as React from 'react';
import { useEffect, useState } from 'react';
import { BaseComponentProps, InputTable, validators } from '@src/components';
import { RandomizerInput } from '@lub0v/santa-utils';
import { validateLongUsername } from '@src/utils/validators';

interface RandomizerInputUsersTableProps extends BaseComponentProps {
  randomizerInput: RandomizerInput;
  orgParticipate: boolean;
  onChange: (users: any) => void;
}

function getInitialUsers(props: RandomizerInputUsersTableProps) {
  const initialUsers = [];
  if (props.orgParticipate) {
    initialUsers.push({
      username: props.randomizerInput.organizerUsername,
      email: props.randomizerInput.organizerEmail,
    });
  }
  if (props.randomizerInput.users.length) {
    props.randomizerInput.users.forEach((u) => {
      if (props.randomizerInput.organizerEmail === u.email) {
        return;
      }
      initialUsers.push({ username: u.username, email: u.email });
    });
  }
  return initialUsers;
}

export function RandomizerInputUsersTable(props: RandomizerInputUsersTableProps & any) {
  const initialUsers = getInitialUsers(props);
  useEffect(() => {
    if (initialUsers.length !== props.randomizerInput.users.length) {
      props.onChange(initialUsers);
    }
  }, []);

  const [values, setValues] = useState(initialUsers);

  const validateUniqueName = (val: string) => {
    const sameNameLen = values.map((v) => v.username).filter((n) => n === val).length;
    return sameNameLen > 1 ? 'validations.not_unique_name' : null;
  };

  const validateUniqueEmail = (val: string) => {
    const sameEmailLen = values.map((v) => v.email).filter((n) => n === val).length;
    return sameEmailLen > 1 ? 'validations.not_unique_email' : null;
  };

  const handlePaste = (val: string) => {
    const newValues = [];
    const edited = val
      .replace(/,/g, ' ')
      .replace(/(@[^\s]+)(\s)/g, '$1|') // replace space after email to the "|"
      .replace(/(\s)([^\s]+@)/g, '_$2'); // replace space before email to the "_"
    const lines = edited.split('|');
    for (let i = 0; i < lines.length; i += 1) {
      const arr = lines[i].split('_');
      newValues.push({
        username: arr[0].trim(),
        email: arr.length > 1 ? arr[1].trim() : '',
      });
    }
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 20);
    return newValues;
  };

  const columns = {
    username: {
      title: 'user_name',
      validators: [
        validators.required(),
        (v: string) => validateLongUsername(v),
        validateUniqueName,
      ],
    },
    email: {
      title: 'user_email',
      validators: [validators.required(), validators.email(), validateUniqueEmail],
    },
  };

  return (
    <InputTable
      registerCallback={props.registerCallback}
      unregisterCallback={props.unregisterCallback}
      addNumber={true}
      meta={[{ readonly: props.orgParticipate }]}
      value={values}
      onChange={(vals: any[]) => {
        setValues(vals);
        props.onChange(vals);
      }}
      onFormChange={props.onFormChange}
      handlePaste={handlePaste}
      translator={props.translator}
      max={100}
      min={3}
      columns={columns}
    />
  );
}
