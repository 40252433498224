import * as React from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ColumnStart, FormPage, FormPageGroup } from '@lub0v/ui-components';
import { LoadingBarBig } from '@src/loading/LoadingComponents';

export const BoxInviteLoading = (props: BoxPageProps) => (
  <FormPage title="box_invite_title" translator={props.translator} onBack={() => {}}>
    <FormPageGroup title="box_invite_organizer_participating">
      <ColumnStart>
        <LoadingBarBig />
      </ColumnStart>
    </FormPageGroup>
    <FormPageGroup title="box_invite_users_with_link" overflowScroll>
      <ColumnStart>
        <LoadingBarBig />
      </ColumnStart>
    </FormPageGroup>
    <FormPageGroup title="box_invite_users">
      <ColumnStart>
        <LoadingBarBig />
        <LoadingBarBig />
      </ColumnStart>
    </FormPageGroup>
  </FormPage>
);
