export function groupBy(obj: any, ...keys) {
  return obj.reduce((rv, x) => {
    let v = x;
    for (const k of keys) {
      v = v[k];
    }
    // eslint-disable-next-line no-param-reassign
    (rv[v] = rv[v] || []).push(x);
    return rv;
  }, {});
}
