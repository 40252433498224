import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import {
  ButtonSocial,
  ButtonSocialGroup,
  ColumnCenter,
  Divider,
  FormAuth,
  Input,
  SantaLink,
  TextSecondaryMedium,
  TextSecondaryRegular,
} from '@src/components';
import { AdLook } from '@src/components/AdLook';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { validateField } from '@src/utils/validators';
import {
  actionAppUpdateState,
  actionLoadAppState,
  dispatchActionAppUpdateState,
  SantaAppState,
} from '@src/state';
import { store } from '@src/state/store';
import { LoginInput, SessionOutput } from '@lub0v/santa-utils';
import { santaClient } from '@src/state/client';
import { socialLogin, telegramLogin, updateStoreEmail } from '@src/pages/auth/auth';
import { isDotCom } from '@src/host';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface LoginPageProps extends BasePageProps {
  login: LoginInput;
}

function LoginPageComponent(props: LoginPageProps) {
  const [error, setError] = useState(undefined);

  return (
    <FormAuth
      style={{ marginBottom: '5rem' }}
      title="site_login"
      button="login"
      formTestId="login"
      subTitle={
        <>
          no_account_q{' '}
          <SantaLink to="/register">
            <TextSecondaryMedium orange underline>
              register
            </TextSecondaryMedium>
          </SantaLink>
        </>
      }
      error={error}
      translator={props.translator}
      onSubmit={async () => {
        const res: SessionOutput = await santaClient.login(props.login);
        if (res.ok) {
          dispatchActionAppUpdateState({ login: { email: null, password: null } });
          store.dispatch(actionLoadAppState());
          props.history.push(props.login.redirect);
        } else {
          setError(res.error.message);
        }
      }}
      footer={
        <ColumnCenter>
          <TextSecondaryRegular grey>policy_agreement_hint</TextSecondaryRegular>
          <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
        </ColumnCenter>
      }
    >
      <ButtonSocialGroup
        style={{ gridTemplateColumns: isDotCom() ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr' }}
      >
        <ButtonSocial type="google" onClick={() => socialLogin('google', props.login)}>
          login_with_google
        </ButtonSocial>
        <ButtonSocial type="vk" onClick={() => socialLogin('vk', props.login)}>
          login_with_vk
        </ButtonSocial>
        {isDotCom() ? (
          <ButtonSocial type="fb" onClick={() => socialLogin('fb', props.login)}>
            login_with_fb
          </ButtonSocial>
        ) : null}

        <ButtonSocial
          type="tg"
          onClick={() => {
            telegramLogin(props.login.redirect, () => {});
          }}
        >
          login_with_tg
        </ButtonSocial>
      </ButtonSocialGroup>
      <Divider>or</Divider>
      <Input
        label="your_email"
        name="email"
        value={props.login.email}
        validators={[validateField(props.login, 'email')]}
        onChange={(email) => {
          setError(undefined);
          updateStoreEmail(email);
        }}
      />
      <Input
        label="your_password"
        name="password"
        value={props.login.password}
        password
        validators={[validateField(props.login, 'password')]}
        onChange={(password) => {
          setError(undefined);
          store.dispatch(actionAppUpdateState({ login: { password } }));
        }}
        additionalText={
          <SantaLink to="/recover">
            <TextSecondaryRegular grey underline>
              forgot_password_q
            </TextSecondaryRegular>
          </SantaLink>
        }
      />
    </FormAuth>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    login: state.login,
  };
}

export const LoginPage = connect(mapStateToProps)(withBasePage<BasePageProps>(LoginPageComponent));
