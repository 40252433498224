import * as React from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  DotJoined,
  GridColumn,
  GridContainer,
  SantaLink,
  TextSecondaryRegular,
  UserCard,
} from '@src/components';
import { BoxInfo, CardInfo, FullCardInfo } from '@lub0v/santa-utils';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { t, TRANSLATIONS } from '@src/translations';
import { dispatchActionModal, loadBoxCards } from '@src/state';
import { santaClient } from '@src/state/client';
import { useState } from 'react';

type Props = {
  translator: any;
  boxInfo: BoxInfo;
  card: FullCardInfo;
  cards: CardInfo[];
  mobile: boolean;
};

export function ParticipantsList(props: Props) {
  const showNames = props.boxInfo.isAdmin || props.boxInfo.box.useNames;
  const getUserCard = (card: CardInfo, index, fixed = false) => {
    const noWard = props.boxInfo.isAdmin && props.boxInfo.isActiveDraw && !card.wardId;
    const showCardName = card.isYourWard || showNames;
    const yourCard = props.cards.find((c) => c.isYou);
    const [modalOpened, setModalOpened] = useState(false);

    const explainModal = async (opened: boolean) => {
      dispatchActionModal({
        opened: opened,
        title: 'modal_wait_for_filling_title',
        message: 'modal_wait_for_filling_message',
        buttonYes: 'modal_wait_for_filling_button',
        loading: false,
        onSubmit: async () => {
          explainModal(false);
        },
      });
    };

    const onClickWhatIsThat = () => {
      explainModal(true);
    };

    const status = (
      <DotJoined>
        {card.isYou ? <TextSecondaryRegular grey>{t('box_it_is_you')}</TextSecondaryRegular> : null}
        {!card.isVerified &&
        (props.boxInfo.isActiveDraw || !props.boxInfo.box.isInviteAfterDraw) ? (
          <TextSecondaryRegular grey>
            {t('box_wait_for_filling')}
            <TextSecondaryRegular orange underline onClick={onClickWhatIsThat}>
              {t('box_wait_for_filling_what_is_it')}
            </TextSecondaryRegular>
          </TextSecondaryRegular>
        ) : null}
        {!card.isVerified && !props.boxInfo.isActiveDraw && props.boxInfo.box.isInviteAfterDraw ? (
          <TextSecondaryRegular grey>{t('box_wait_for_draw')}</TextSecondaryRegular>
        ) : null}
        {noWard ? <TextSecondaryRegular grey>{t('box_no_giftee')}</TextSecondaryRegular> : null}
        {card.isWardKnown ? (
          <TextSecondaryRegular grey>{t('box_knows_giftee')}</TextSecondaryRegular>
        ) : noWard || !card.isVerified || !card.wardId ? null : (
          <TextSecondaryRegular grey>{t('box_does_not_know_giftee')}</TextSecondaryRegular>
        )}
        {card.isYourWard && yourCard && yourCard.isWardKnown ? (
          <TextSecondaryRegular grey>{t('box_your_giftee')}</TextSecondaryRegular>
        ) : null}
        {card.isGiftReceived ? (
          <TextSecondaryRegular grey>{t('box_gift_received')}</TextSecondaryRegular>
        ) : card.isGiftOnItsWay ? (
          <TextSecondaryRegular grey>{t('box_gift_was_sent')}</TextSecondaryRegular>
        ) : null}
      </DotJoined>
    );

    const UCard = (
      <UserCard
        picture={card.picture}
        avatar={card.avatar}
        name={showCardName ? card.username : ''}
        verified={card.isVerified}
        giftInactive={card.isGiftOnItsWay}
        giftActive={card.isGiftReceived}
        fixed={fixed}
        style={{ margin: 'auto' }}
        noWard={noWard}
      >
        {status}
      </UserCard>
    );
    if (props.boxInfo.isAdmin) {
      return (
        <SantaLink to={card.isYou ? getBoxPath('/card') : getBoxPath(`/card/${card.id}/edit`)}>
          {UCard}
        </SantaLink>
      );
    }
    if (card.isYou) {
      return <SantaLink to={getBoxPath('/card')}>{UCard}</SantaLink>;
    }
    if (card.isYourWard) {
      return <SantaLink to={getBoxPath('/ward')}>{UCard}</SantaLink>;
    }
    return UCard;
  };

  const cardWidth = props.mobile ? 300 : 352;
  const cardHeight = props.mobile ? 96 : 230;

  const getCardsPerRow = (ww) => Math.floor(ww / cardWidth) || 1;

  const getXS = (ww) => {
    const numCards = getCardsPerRow(ww);
    if (numCards === 2) {
      return 6;
    }
    if (numCards === 3) {
      return 4;
    }
    if (numCards === 4) {
      return 3;
    }
    return 12;
  };

  const renderRowFunc = (h: number, w: number) =>
    // eslint-disable-next-line react/display-name
    ({ index, style }: { index: number; style: any }) => {
      const numCards = getCardsPerRow(w);
      if (props.cards.length > index) {
        const cards = [];
        const cardIndex = index * numCards;
        for (let i = cardIndex; i < cardIndex + numCards; i += 1) {
          if (props.cards.length > i) {
            cards.push(props.cards[i]);
          }
        }
        return (
          <GridContainer spacing={props.mobile} style={style}>
            {cards.map((c) => (
              <GridColumn key={`card-${c.id}`} item xs={getXS(w)}>
                {getUserCard(c, index)}
              </GridColumn>
            ))}
          </GridContainer>
        );
      }
      return null;
    };

  const renderFunc = ({ height, width }) => (
    <FixedSizeList
      height={height - 75}
      itemCount={Math.ceil(props.cards.length / getCardsPerRow(width))}
      itemSize={cardHeight}
      width={width}
    >
      {renderRowFunc(height, width)}
    </FixedSizeList>
  );
  return <AutoSizer>{renderFunc}</AutoSizer>;
}
