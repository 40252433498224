import { BoxHeaderInfo, BoxPic, DotJoined, TextMedium, TextRegular } from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { LoadingPicture } from '@src/loading/LoadingComponents';
import * as React from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { dispatchActionAppUpdateState, dispatchActionModal, loadBoxInfo } from '@src/state';
import { santaClient } from '@src/state/client';
import { useState } from 'react';

export const BoxHeaderLeft = (props: BoxPageProps) => {
  const boxInfo = props.box.info;

  const [modal, setSendOrgMessage] = useState(false);

  if (!boxInfo) {
    return (
      <BoxHeaderInfo title={'...'} pic={<LoadingPicture />}>
        <DotJoined>
          <TextMedium grey translator={props.translator}>
            {t(TRANSLATIONS.box_participants_count)} ...
          </TextMedium>
        </DotJoined>
      </BoxHeaderInfo>
    );
  }

  if (props.box.isUnauthorized) {
    return <BoxHeaderInfo title={'...'} pic={<BoxPic type="santa" />} />;
  }

  const copyEmailToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(boxInfo.admin.email);
      dispatchActionAppUpdateState({
        box: {
          saved: 'box_org_email_copied',
        },
      });
    } catch (err) {
      console.error(`Failed to copy email to clipboard :( ${err}`);
    }
  };

  let pic = null;
  if (boxInfo.box.logo) {
    pic = (
      <img
        alt="custom_image"
        style={{ borderRadius: '100%', width: '5rem', height: '5rem' }}
        src={boxInfo.box.logo}
      />
    );
  }

  const setSendOrgMessageModal = async (open: boolean) => {
    setSendOrgMessage(open);
    dispatchActionModal({
      opened: open,
      title: 'modal_ask_org_title',
      textarea: ' ',
      message: `${t('modal_ask_org_hint')} ${boxInfo.admin.email}\n\n`,
      buttonYes: 'modal_ask_org_submit',
      buttonNo: 'modal_ask_org_cancel',
      loading: false,
      onSubmit: async (value) => {
        await santaClient.sendMessageToAdmin({ key: boxInfo.box.key, message: value });
        await setSendOrgMessageModal(false);
        dispatchActionAppUpdateState({
          box: {
            saved: 'box_org_message_sent',
          },
        });
      },
      onCancel: () => {
        setSendOrgMessageModal(false);
      },
    });
  };

  return (
    <BoxHeaderInfo
      title={boxInfo.box.name}
      pic={pic}
      picture={boxInfo.box.picture}
      onClick={() => props.history.push(`/box/${boxInfo.box.key}`)}
    >
      <DotJoined translator={props.translator}>
        <TextRegular grey>
          {t(TRANSLATIONS.box_participants_count)} {boxInfo.participantsNumber}
        </TextRegular>
        {boxInfo.isAdmin ? (
          <TextRegular grey>{t(TRANSLATIONS.you_admin)}</TextRegular>
        ) : (
          <TextRegular grey>
            {/* {t(TRANSLATIONS.box_organizer)} */}
            <TextRegular
              orange
              underline
              onClick={() => {
                setSendOrgMessageModal(true);
              }}
            >
              {t('modal_ask_org_title')} {boxInfo.admin.username}
            </TextRegular>
          </TextRegular>
        )}
        {boxInfo.box.isArchived ? (
          <TextRegular grey translator={props.translator}>
            {t(TRANSLATIONS.box_is_archived)}
          </TextRegular>
        ) : null}
      </DotJoined>
    </BoxHeaderInfo>
  );
};
