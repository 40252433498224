import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, withBasePage } from '@src/pages/withBasePage';
import { mapStateToProps } from '@src/pages/DonatePage';
import { ColumnCenter } from '@src/components';
import { isDotCom } from '@src/host';

function PolicyPageComponent(props: BasePageProps) {
  if (isDotCom()) {
    return null;
  }

  return (
    <ColumnCenter style={{ marginTop: '3rem' }} translator={props.translator}>
      <div className="policy">
        <div className="col">
          <div>
            <h4>Согласие физического лица на обработку персональных данных</h4>

            <div className="row mb-4">
              <div className="col">
                <span>
                  Физическое лицо (далее – Пользователь), регистрируясь на сайте{' '}
                  <mark>https://santa-secret.ru/</mark> в соответствии со своей волей и в своем
                  интересе, а также подтверждая свою полную дееспособность, в соответствии с
                  требованиями Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных
                  данных» (далее – Закон о персональных данных) предоставляет{' '}
                  <mark>Питько Любови Андреевне</mark> (далее - Оператор) настоящее согласие на{' '}
                  <mark>политику в отношении обработки персональных данных</mark>.
                </span>
              </div>
            </div>

            <h4>Политика в отношении обработки персональных данных</h4>

            <div className="row mb-4">
              <div className="col">
                <h5 id="list-1">1. Общие положения</h5>
                <span>
                  Настоящая политика обработки персональных данных составлена в соответствии с
                  требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и
                  определяет порядок обработки персональных данных и меры по обеспечению
                  безопасности персональных данных <mark>Питько Любови Андреевны</mark> (далее –
                  Оператор).
                </span>
                <ol>
                  <li>
                    Оператор ставит своей важнейшей целью и условием осуществления своей
                    деятельности соблюдение прав и свобод человека и гражданина при обработке его
                    персональных данных, в том числе защиты прав на неприкосновенность частной
                    жизни, личную и семейную тайну.
                  </li>
                  <li>
                    Настоящая политика Оператора в отношении обработки персональных данных (далее –
                    Политика) применяется ко всей информации, которую Оператор может получить о
                    посетителях веб-сайта <mark>https://santa-secret.ru</mark>.
                  </li>
                </ol>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <h5 id="list-2">2. Основные понятия, используемые в Политике</h5>

                <ol>
                  <li>
                    Автоматизированная обработка персональных данных – обработка персональных данных
                    с помощью средств вычислительной техники;
                  </li>
                  <li>
                    Блокирование персональных данных – временное прекращение обработки персональных
                    данных (за исключением случаев, если обработка необходима для уточнения
                    персональных данных);
                  </li>
                  <li>
                    Веб-сайт – совокупность графических и информационных материалов, а также
                    программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по
                    сетевому адресу <mark>https://santa-secret.ru</mark>;
                  </li>
                  <li>
                    Информационная система персональных данных — совокупность содержащихся в базах
                    данных персональных данных, и обеспечивающих их обработку информационных
                    технологий и технических средств;
                  </li>
                  <li>
                    Обезличивание персональных данных — действия, в результате которых невозможно
                    определить без использования дополнительной информации принадлежность
                    персональных данных конкретному Пользователю или иному субъекту персональных
                    данных;
                  </li>
                  <li>
                    Обработка персональных данных – любое действие (операция) или совокупность
                    действий (операций), совершаемых с использованием средств автоматизации или без
                    использования таких средств с персональными данными, включая сбор, запись,
                    систематизацию, накопление, хранение, уточнение (обновление, изменение),
                    извлечение, использование, передачу (распространение, предоставление, доступ),
                    обезличивание, блокирование, удаление, уничтожение персональных данных;
                  </li>
                  <li>
                    Оператор – государственный орган, муниципальный орган, юридическое или
                    физическое лицо, самостоятельно или совместно с другими лицами организующие и
                    (или) осуществляющие обработку персональных данных, а также определяющие цели
                    обработки персональных данных, состав персональных данных, подлежащих обработке,
                    действия (операции), совершаемые с персональными данными;
                  </li>
                  <li>
                    Персональные данные – любая информация, относящаяся прямо или косвенно к
                    определенному или определяемому Пользователю веб-сайта{' '}
                    <mark>https://santa-secret.ru</mark>;
                  </li>
                  <li>
                    Пользователь – любой посетитель веб-сайта <mark>https://santa-secret.ru</mark>;
                  </li>
                  <li>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Коробка – виртуальная "коробка" для игры в Тайного Санту на сайте{' '}
                    <mark>https://santa-secret.ru</mark>;
                  </li>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <li>Организатор – Пользователь, создавший "Коробку";</li>
                  <li>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Участник – Пользователь, принимающий участие в игре "Тайный Санта" в
                    определенной Коробке.
                  </li>
                  <li>
                    Тайный Санта – Участник получивший уведомление о том, кому необходимо дарить
                    подарок; У каждого Участника есть свой Тайный Санта.
                  </li>
                  <li>
                    Предоставление персональных данных – действия, направленные на раскрытие
                    персональных данных определенному лицу или определенному кругу лиц;
                  </li>
                  <li>
                    Распространение персональных данных – любые действия, направленные на раскрытие
                    персональных данных неопределенному кругу лиц (передача персональных данных) или
                    на ознакомление с персональными данными неограниченного круга лиц, в том числе
                    обнародование персональных данных в средствах массовой информации, размещение в
                    информационно-телекоммуникационных сетях или предоставление доступа к
                    персональным данным каким-либо иным способом;
                  </li>
                  <li>
                    Трансграничная передача персональных данных – передача персональных данных на
                    территорию иностранного государства органу власти иностранного государства,
                    иностранному физическому или иностранному юридическому лицу;
                  </li>
                  <li>
                    Уничтожение персональных данных – любые действия, в результате которых
                    персональные данные уничтожаются безвозвратно с невозможностью дальнейшего
                    восстановления содержания персональных данных в информационной системе
                    персональных данных и (или) результате которых уничтожаются материальные
                    носители персональных данных.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <h5 id="list-3">
                  3. Оператор может обрабатывать следующие персональные данные Пользователя
                </h5>
                <ol>
                  <li>
                    <mark>Электронный адрес;</mark>
                  </li>
                  <li>
                    <mark>Фамилия, имя, отчество;</mark>
                  </li>
                  <li>
                    <mark>Почтовый адрес (индекс, город, улица);</mark>
                  </li>
                  <li>
                    <mark>Номер телефона;</mark>
                  </li>
                  <li>
                    Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в
                    т.ч. файлов «cookie») с помощью сервисов интернет-статистики Яндекс Метрика.
                  </li>
                  <li>
                    Вышеперечисленные данные далее по тексту Политики объединены общим понятием
                    Персональные данные.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <h5 id="list-4">4. Цели обработки персональных данных</h5>
                <ol>
                  <li>
                    Цель обработки персональных данных Пользователя —{' '}
                    <mark>
                      информирование Пользователя о событиях, связанных с его аккаунтом посредством
                      отправки электронных писем и/или уведомлений Вконтакте и/или телеграм;
                      предоставление доступа Пользователю к сервисам, информации и/или материалам,
                      содержащимся на веб-сайте. Возможность отправки посылки-подарка Участнику на
                      его физический почтовый адрес другим Участником.
                    </mark>
                  </li>
                  <li>
                    Пользователь всегда может отказаться от получения информационных сообщений,
                    направив Оператору письмо на адрес электронной почты{' '}
                    <mark>postman@santa-secret.ru</mark>с пометкой «Отказ от уведомлений».
                  </li>
                  <li>
                    Обезличенные данные Пользователей, собираемые с помощью сервисов
                    интернет-статистики, служат для сбора информации о действиях Пользователей на
                    сайте, улучшения качества сайта и его содержания.
                  </li>
                </ol>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <h5 id="list-5">5. Правовые основания обработки персональных данных</h5>
                <ol>
                  <li>
                    Оператор обрабатывает персональные данные Пользователя только в случае их
                    заполнения и/или отправки Пользователем самостоятельно через специальные формы,
                    расположенные на сайте <mark>https://santa-secret.ru</mark>. Заполняя
                    соответствующие формы и/или отправляя свои персональные данные Оператору,
                    Пользователь выражает свое согласие с данной Политикой.
                  </li>
                  <li>
                    Оператор обрабатывает обезличенные данные о Пользователе в случае, если это
                    разрешено в настройках браузера Пользователя (включено сохранение файлов
                    «cookie» и использование технологии JavaScript).
                  </li>
                </ol>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <h5 id="list-6">
                  6. Порядок сбора, хранения, передачи и других видов обработки персональных данных
                </h5>
                <span>
                  Безопасность персональных данных, которые обрабатываются Оператором,
                  обеспечивается путем реализации правовых, организационных и технических мер,
                  необходимых для выполнения в полном объеме требований действующего
                  законодательства в области защиты персональных данных.
                </span>
                <ol>
                  <li>
                    Оператор обеспечивает сохранность персональных данных и принимает все возможные
                    меры, исключающие доступ к персональным данным неуполномоченных лиц.
                  </li>
                  <li>
                    Персональные данные Пользователя никогда, ни при каких условиях не будут
                    переданы третьим лицам, за исключением Организатора Коробки и Тайного Санты для
                    Участника а также за исключением случаев, связанных с исполнением действующего
                    законодательства.
                  </li>
                  <li>
                    В случае выявления неточностей в персональных данных, Пользователь может
                    актуализировать их самостоятельно, путем направления Оператору уведомление на
                    адрес электронной почты Оператора <mark>postman@santa-secret.ru</mark> с
                    пометкой «Актуализация персональных данных».
                  </li>
                  <li>
                    Срок обработки персональных данных является неограниченным. Пользователь может в
                    любой момент попросить удалить свои персональные данные, направив Оператору
                    уведомление посредством электронной почты на электронный адрес Оператора{' '}
                    <mark>postman@santa-secret.ru</mark>с пометкой «Удаление персональных данных».
                  </li>
                </ol>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <h5 id="list-8">7. Заключительные положения</h5>
                <ol>
                  <li>
                    Пользователь может получить любые разъяснения по интересующим вопросам,
                    касающимся обработки его персональных данных, обратившись к Оператору с помощью
                    электронной почты <mark>postman@santa-secret.ru</mark>.
                  </li>
                  <li>
                    В данном документе будут отражены любые изменения политики обработки
                    персональных данных Оператором. Политика действует бессрочно до замены ее новой
                    версией.
                  </li>
                  <li>
                    Актуальная версия Политики в свободном доступе расположена в сети Интернет по
                    адресу <mark>https://santa-secret.ru/policy</mark>.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ColumnCenter>
  );
}

export const PolicyPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(PolicyPageComponent),
);
