import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage } from '@src/pages/withBasePage';
import {
  CssProps,
  dispatchActionAppUpdateState,
  SantaAppState,
  UserAccountInfoState,
} from '@src/state';
import {
  ButtonService,
  ColumnStart,
  HintError,
  Input,
  RowEnd,
  Validator,
  withBase,
  QuickNotification,
} from '@src/components';
import { validateField } from '@src/utils/validators';
import { santaClient } from '@src/state/client';

interface PasswordFieldsProps extends BasePageProps {
  account: UserAccountInfoState;
  css: CssProps;
}

function PasswordFieldsComponent(props: PasswordFieldsProps) {
  const [passwordConfirm, setPasswordConfirm] = useState(undefined);
  const [passwordRef, setPasswordRef] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [saved, setSaved] = useState(false);

  const validateSamePasswords: Validator = () => {
    if (!passwordConfirm) {
      return null;
    }
    if (passwordConfirm === props.account.passwordInput.password) {
      return null;
    }
    return 'validations.passwordsDiffer';
  };

  const savePassword = () => {
    passwordRef
      .validate()
      .then(async () => {
        const res = await santaClient.accountUpdatePassword(props.account.passwordInput);
        if (res.ok) {
          dispatchActionAppUpdateState({
            account: {
              account: res.data,
              passwordInput: { password: '' },
            },
          });
          setError(undefined);
          setPasswordConfirm('');
          setSaved(true);
        } else {
          setError(res.error.message);
        }
      })
      .catch(() => {
        setError('validations.form_errors');
      });
  };

  return (
    <>
      <QuickNotification
        translator={props.translator}
        autoHide={2000}
        green
        onClick={() => {
          setSaved(false);
        }}
        opened={saved}
        style={{ top: props.css.mobile ? '-5.5rem' : '-3rem' }}
      >
        account_password_saved
      </QuickNotification>
      <ColumnStart translator={props.translator}>
        <Input
          registerCallback={(r) => {
            setPasswordRef(r);
          }}
          name="password"
          label="new_password"
          autocomplete="new-password"
          password
          value={props.account.passwordInput.password}
          validators={[
            validateField(props.account.passwordInput, 'password'),
            validateSamePasswords,
          ]}
          onChange={(password) => {
            setError(undefined);
            dispatchActionAppUpdateState({ account: { passwordInput: { password } } });
          }}
        />
        <Input
          label="confirm_password"
          name="confirm_password"
          autocomplete="new-password"
          password
          value={passwordConfirm}
          validators={[
            validateField(props.account.passwordInput, 'password'),
            validateSamePasswords,
          ]}
          onChange={(p) => {
            setError(undefined);
            setPasswordConfirm(p);
          }}
        />
        {error ? <HintError center errors={[error]} translator={props.translator} /> : null}
        <RowEnd>
          <ButtonService
            disabled={!passwordConfirm || passwordConfirm !== props.account.passwordInput.password}
            onClick={savePassword}
          >
            save
          </ButtonService>
        </RowEnd>
      </ColumnStart>
    </>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    account: state.account,
    css: state.css,
  };
}

export const PasswordFields = withBase(connect(mapStateToProps)(PasswordFieldsComponent));
