import * as React from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ColumnStart, FormPage, FormPageGroup } from '@lub0v/ui-components';
import { LoadingBarBig, LoadingBarMedium } from '@src/loading/LoadingComponents';

export const EditOtherCardLoading = (props: BoxPageProps) => (
  <FormPage title="edit_other_card_title" translator={props.translator} onBack={() => {}}>
    <FormPageGroup title="edit_other_card_email">
      <ColumnStart>
        <LoadingBarMedium />
      </ColumnStart>
    </FormPageGroup>
    <FormPageGroup title="edit_other_card_username_title">
      <ColumnStart>
        <LoadingBarMedium />
      </ColumnStart>
    </FormPageGroup>
    <FormPageGroup title="edit_other_card_picture" overflowScroll>
      <ColumnStart>
        <LoadingBarBig />
      </ColumnStart>
    </FormPageGroup>
    <FormPageGroup title="edit_other_card_wish">
      <ColumnStart>
        <LoadingBarBig />
      </ColumnStart>
    </FormPageGroup>
    <FormPageGroup title="edit_other_card_postal_address">
      <ColumnStart>
        <LoadingBarMedium />
        <LoadingBarMedium />
      </ColumnStart>
    </FormPageGroup>
  </FormPage>
);
