import { t, TRANSLATIONS } from '@src/translations';
import { isDotCom } from '@src/host';

export function getCurrencies() {
  if (isDotCom()) {
    return [
      { key: 'eur', value: t(TRANSLATIONS.currency.eur.title) },
      { key: 'usd', value: t(TRANSLATIONS.currency.usd.title) },
      { key: 'sec', value: t(TRANSLATIONS.currency.sec.title) },
    ];
  }
  return [
    { key: 'rub', value: t(TRANSLATIONS.currency.rub.title) },
    { key: 'eur', value: t(TRANSLATIONS.currency.eur.title) },
    { key: 'uah', value: t(TRANSLATIONS.currency.uah.title) },
    { key: 'byn', value: t(TRANSLATIONS.currency.byn.title) },
    { key: 'kzt', value: t(TRANSLATIONS.currency.kzt.title) },
    { key: 'usd', value: t(TRANSLATIONS.currency.usd.title) },
    { key: 'sec', value: t(TRANSLATIONS.currency.sec.title) },
    { key: 'amd', value: t(TRANSLATIONS.currency.amd.title) },
  ];
}
