import * as React from 'react';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import {
  BaseComponentProps,
  BoxPic,
  CatCute,
  CatWalking,
  ColumnCenter,
  ColumnStart,
  Divider,
  DotJoined,
  GridColumn,
  GridContainer,
  PictureNotice,
  SantaLink,
  TextSecondaryRegular,
  ToggleMenu,
  ToggleMenuButton,
  ToggleMenuItem,
  UserCard,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { santaClient } from '@src/state/client';
import { BoxesInfoOutput, BoxInfo } from '@lub0v/santa-utils';
import { groupBy } from '@src/utils/groupby';
import { BoxesLoadingPageComponent } from '@src/pages/boxes/BoxesLoading';
import { AdLook } from '@src/components/AdLook';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface BoxesPageProps extends BaseComponentProps {
  archived: boolean;
  boxes: BoxInfo[];
}

function BoxesPageComponent(props: BoxesPageProps & BasePageProps) {
  const [loadingBoxes, setLoadingBoxes] = useState(true);
  useEffect(() => {
    santaClient.accountBoxes().then((res: BoxesInfoOutput) => {
      if (res.ok) {
        dispatchActionAppUpdateState({ accountBoxes: { boxes: res.data } });
        setLoadingBoxes(false);
      } else {
        console.log(res.error);
      }
    });
  }, []);

  if (props.loading || loadingBoxes) {
    return <BoxesLoadingPageComponent />;
  }
  const getBoxSections = () => {
    const boxesByYear = groupBy(
      props.boxes.filter((b) => b.box.isArchived === props.archived),
      'box',
      'year',
    );
    const years = Object.keys(boxesByYear).sort((a, b) => (a > b ? -1 : 1));
    const sections = [];
    years.forEach((year) => {
      const boxes = boxesByYear[year];
      const boxCards = boxes.map((box: BoxInfo) => {
        const userState = (
          box.isAdmin && box.isParticipant ? t(TRANSLATIONS.you_admin_and_participant) : box.isAdmin
        )
          ? t(TRANSLATIONS.you_admin)
          : t(TRANSLATIONS.you_participant);

        const completed = box.isActiveDraw ? t(TRANSLATIONS.draw_completed) : null;
        const participants = t(TRANSLATIONS.participants_number, { count: box.participantsNumber });
        let pic = null;
        if (box.box.logo) {
          pic = (
            <img
              alt="custom"
              style={{ borderRadius: '100%', width: '6rem', height: '6rem' }}
              src={box.box.logo}
            />
          );
        } else {
          pic = <BoxPic type={box.box.picture} />;
        }

        return (
          <GridColumn key={box.box.key} xs={12} sm={6} md={4} lg={3}>
            <SantaLink
              to={`/box/${box.box.key}`}
              onClick={() => {
                // dispatchActionAppUpdateState({ box });
              }}
            >
              <UserCard
                fixed
                translator={props.translator}
                pic={pic}
                name={box.box.name}
                verified={!box.box.isArchived}
                style={{ marginTop: '1rem', margin: 'auto' }}
              >
                <DotJoined translator={props.translator}>
                  <TextSecondaryRegular grey noWrap>
                    {participants}
                  </TextSecondaryRegular>
                  <TextSecondaryRegular grey noWrap>
                    {userState}
                  </TextSecondaryRegular>
                  {completed ? (
                    <TextSecondaryRegular grey noWrap>
                      {completed}
                    </TextSecondaryRegular>
                  ) : null}
                </DotJoined>
              </UserCard>
            </SantaLink>
          </GridColumn>
        );
      });
      sections.push(
        <ColumnStart key={year} paddingBottom>
          <Divider left>{year}</Divider>
          <GridContainer spacing={2}>
            <GridColumn xs={12}>
              <GridContainer spacing={3}>{boxCards}</GridContainer>
            </GridColumn>
          </GridContainer>
        </ColumnStart>,
      );
    });
    return sections;
  };

  const sections = getBoxSections();

  return (
    <>
      <ColumnCenter alignCenter style={{ maxWidth: '30rem', margin: 'auto', marginTop: '2rem' }}>
        <ToggleMenu
          active={props.archived ? 1 : 0}
          button={
            <SantaLink to="/box/new">
              <ToggleMenuButton type="plus" />
            </SantaLink>
          }
        >
          <ToggleMenuItem
            onClick={() => {
              dispatchActionAppUpdateState({ accountBoxes: { archived: false } });
            }}
          >
            {t('account_my_boxes')}
          </ToggleMenuItem>
          <ToggleMenuItem
            onClick={() => {
              dispatchActionAppUpdateState({ accountBoxes: { archived: true } });
            }}
          >
            {t('account_archive')}
          </ToggleMenuItem>
        </ToggleMenu>
      </ColumnCenter>
      {sections.length ? (
        <>
          <div style={{ marginBottom: '5rem' }}>{props.boxes ? getBoxSections() : null}</div>
          <ColumnCenter>
            <AdLookIBV numAdsDesktop={4} numAdsMobile={1} />
          </ColumnCenter>
        </>
      ) : (
        <ColumnCenter style={{ marginTop: '2rem', marginBottom: '3rem' }}>
          {props.archived ? (
            <PictureNotice
              translator={props.translator}
              title="no_archived_boxes"
              hint="no_archived_boxes_hint"
            >
              <CatWalking />
            </PictureNotice>
          ) : (
            <PictureNotice
              translator={props.translator}
              title="no_boxes"
              hint="no_boxes_hint"
              button="create_box"
              onClick={() => {
                props.history.push('/box/new');
              }}
            >
              <CatCute />
            </PictureNotice>
          )}
          <AdLookIBV numAdsDesktop={4} numAdsMobile={1} />
        </ColumnCenter>
      )}
    </>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    boxes: state.accountBoxes.boxes,
    archived: state.accountBoxes.archived,
    ...mapStateToPropsBasePage(state),
  };
}

export const BoxesPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(BoxesPageComponent, { authenticatedOnly: true }),
);
