import { Validator } from '@lub0v/ui-components';
import { getValidate, longUsernameValidation, usernameValidation } from '@lub0v/santa-utils';
import * as yup from 'yup';
import { t, TRANSLATIONS } from '@src/translations';

export function validateField(target: any, ...keys: string[]): Validator {
  return (val: string) => {
    const schema: any = {};
    for (const key of keys) {
      schema[key] = getValidate(target, key);
    }
    const validateObject = {};
    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (i === 0) {
        validateObject[k] = val;
      } else {
        validateObject[k] = target[k];
      }
    }
    try {
      yup.object().shape(schema).validateSync(target, {
        abortEarly: false,
      });
      return undefined;
    } catch (err) {
      const error = err.errors?.length ? err.errors[0] : { transKey: 'validations.invalid' };
      const translated = t(TRANSLATIONS[error.transKey], { field: error.field, ...error.params });
      if (translated) {
        return translated;
      }
      return t(TRANSLATIONS['validations.invalid']);
    }
  };
}

export function validateUsername(value: string) {
  try {
    usernameValidation.validateSync(value);
    return undefined;
  } catch (err) {
    return 'validations.username_invalid';
  }
}

export function validateLongUsername(value: string) {
  try {
    longUsernameValidation.validateSync(value);
    return undefined;
  } catch (err) {
    return 'validations.username_invalid';
  }
}
