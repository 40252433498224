import * as React from 'react';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { FormCard, Hint, Input } from '@src/components';
import { NewBoxProps } from '@src/pages/new_box/NewBoxProps';
import { t, TRANSLATIONS } from '@src/translations';
import { validateField } from '@src/utils/validators';
import { santaClient } from '@src/state/client';
import { AdLookIBV } from '@src/components/AdLookIBV';

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    boxCreate: state.boxCreate,
  };
}

export const NewBoxStepName = connect(mapStateToProps)((props: NewBoxProps) => {
  const [error, setError] = useState(undefined);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = async () => {
    const res = await santaClient.createBox(props.boxCreate.input);
    if (res.ok) {
      dispatchActionAppUpdateState({
        boxCreate: { input: { picture: res.data.box.picture } },
      });
      props.goNext();
    } else {
      setError(res.error.message);
    }
  };

  return (
    <FormCard
      title="new_box_step_name_title"
      translator={props.translator}
      step={t(TRANSLATIONS.new_box_step_title, { step: props.boxCreate.step })}
      onSubmit={onSubmit}
      onBack={props.goBack}
      error={error}
    >
      <Input
        label="new_box_box_name"
        value={props.boxCreate.input.name}
        validators={[validateField(props.boxCreate.input, 'name')]}
        noBottomText
        onChange={(name) => {
          dispatchActionAppUpdateState({ boxCreate: { input: { name } } });
        }}
      />
      <Hint style={{ marginBottom: '2rem' }}>new_box_box_name_hint</Hint>
      <Input
        label="new_box_box_key"
        value={props.boxCreate.input.key}
        validators={[validateField(props.boxCreate.input, 'key')]}
        noBottomText
        onChange={(key) => {
          dispatchActionAppUpdateState({ boxCreate: { input: { key } } });
        }}
      />
      <Hint>new_box_box_key_hint</Hint>

      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </FormCard>
  );
});
