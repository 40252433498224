// eslint-disable-next-line max-classes-per-file
import {
  BoxCreateInput,
  BoxInfo,
  BoxInviteInput,
  CardInfo,
  CreateMyCardInput,
  CreateOtherCardInput,
  DonateInput,
  FeedbackInput,
  FullCardInfo,
  GoodsInfo,
  LoginInput,
  MessageInfo,
  NotificationInfo,
  RandomizerCardInfo,
  RandomizerInput,
  RecoverInput,
  RegisterInput,
  ResendGetNotificationsInput,
  ResetPasswordInput,
  SessionObject,
  UserAccountEmailInput,
  UserAccountInfo,
  UserAccountPasswordInput,
  UserAccountUsernameInput,
} from '@lub0v/santa-utils';
import { Lang } from '@src/types/lang';
import { ModalProps } from '@src/components';
import { isDotCom } from '@src/host';

export class SantaAppState {
  static APP_UPDATE_STATE = 'APP_UPDATE_STATE';
  static APP_RESET_STATE = 'APP_RESET_STATE';
  static APP_UPDATE_URL_PARAMS = 'APP_UPDATE_URL_PARAMS';
  static APP_RESET_NEW_BOX = 'APP_RESET_NEW_BOX';
  static APP_MODAL = 'APP_MODAL';
  css: CssProps;
  loading: boolean;
  jwt: string;
  session: SessionObject;
  language: Lang;
  register: RegisterInput;
  recover: RecoverInput;
  reset: ResetPasswordInput;
  account: UserAccountInfoState;
  feedback: FeedbackInput;
  resend: ResendGetNotificationsInput;
  donate: DonateInput;
  randomizer: RandomizerInput;
  randomizerCards: RandomizerCardInfo[];
  boxCreate: BoxCreate;
  accountBoxes: AccountBoxes;
  notifications: AccountNotifications;
  lastNotificationsLoaded: number;
  box: Box;
  login: LoginInput;
  urlParams: any;
  showConfetti: boolean;
  isMaintenance: boolean;
  counter: number;
  modal: ModalProps;
  showDonatePanel: boolean;
  sentMetrikaShowDonatePanel: boolean;
  showPollPanel: boolean;
  showAds: boolean;
  sentMetrikaShowPollPanel: boolean;
  goods: GoodsInfo[];
  emailToOrg: EmailToOrganizer;

  constructor() {
    this.jwt = null;
    this.counter = 0;
    this.isMaintenance = false;
    this.loading = false;
    this.showConfetti = false;
    this.language = isDotCom() ? Lang.EN : Lang.RU;
    this.session = null;
    this.register = new RegisterInput();
    this.recover = new RecoverInput();
    this.reset = new ResetPasswordInput();
    this.login = new LoginInput();
    this.feedback = new FeedbackInput();
    this.resend = new ResendGetNotificationsInput();
    this.randomizer = new RandomizerInput();
    this.lastNotificationsLoaded = null;
    this.account = {
      account: new UserAccountInfo(),
      usernameInput: new UserAccountUsernameInput(),
      passwordInput: new UserAccountPasswordInput(),
      emailInput: new UserAccountEmailInput(),
    };
    this.notifications = {
      notifications: [],
      opened: false,
    };
    this.randomizerCards = [];
    this.boxCreate = {
      input: new BoxCreateInput(),
      step: 0,
    };
    this.accountBoxes = {
      boxes: [],
      archived: false,
    };
    this.css = {
      fontSize: 12,
      mobile: true,
    };
    this.urlParams = {};
    this.box = {
      isUnauthorized: false,
      join: '',
      info: null,
      invite: new BoxInviteInput(),
      cards: [],
      card: null,
      cardChatOpened: false,
      ward: null,
      wardChatOpened: false,
      editCard: null,
      otherCard: null,
      editOtherCard: null,
      createCard: {
        input: new CreateMyCardInput(),
        step: -1,
        showCreateCardNotice: false,
      },
      editBox: null,
      saved: '',
      santaMessages: null,
      wardMessages: null,
      isNotFound: false,
      modals: {
        confirmDraw: false,
        confirmResetDraw: false,
      },
    };
    const donate = new DonateInput();
    donate.amount = 100;
    donate.method = 'AC';
    this.donate = donate;
    this.modal = {
      opened: false,
    };
    this.showDonatePanel = false;
    this.showAds = true;
    this.sentMetrikaShowDonatePanel = false;
    this.showPollPanel = false;
    this.sentMetrikaShowPollPanel = false;
    this.goods = [];
    this.emailToOrg = null;
  }
}

export interface Box {
  isNotFound: boolean;
  isUnauthorized: boolean;
  join: string;
  info: BoxInfo;
  invite: BoxInviteInput;
  cards: CardInfo[];
  card: FullCardInfo;
  cardChatOpened: boolean;
  ward: FullCardInfo;
  wardChatOpened: boolean;
  createCard: CardCreate;
  editCard: CreateMyCardInput;
  otherCard: FullCardInfo;
  editOtherCard: CreateOtherCardInput;
  editBox: BoxCreateInput;
  saved: string;
  santaMessages: MessageInfo[];
  wardMessages: MessageInfo[];
  modals: {
    confirmDraw?: boolean;
    confirmResetDraw?: boolean;
  };
}

export interface CardCreate {
  input: CreateMyCardInput;
  showCreateCardNotice: boolean;
  step: number;
}

export interface AccountBoxes {
  archived: boolean;
  boxes: BoxInfo[];
}

export interface BoxCreate {
  input: BoxCreateInput;
  step: number;
}

export interface UserAccountInfoState {
  account: UserAccountInfo;
  usernameInput: UserAccountUsernameInput;
  passwordInput: UserAccountPasswordInput;
  emailInput: UserAccountEmailInput;
}

export interface CssProps {
  fontSize?: number;
  mobile?: boolean;
}

export interface AccountNotifications {
  opened: boolean;
  notifications: NotificationInfo[];
}

export interface EmailToOrganizer {
  box: BoxInfo;
  adminEmail: string;
}
