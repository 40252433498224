import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { randomize, RandomizerInput } from '@lub0v/santa-utils';
import { AdLook } from '@src/components/AdLook';

import {
  ColumnCenter,
  FormCard,
  GridColumn,
  GridContainer,
  Input,
  PenguinWithRaisedHands,
  PictureNotice,
  SwitchPanel,
  TextSecondaryMedium,
  Tip,
} from '@src/components';
import { validateField } from '@src/utils/validators';
import { RandomizerInputUsersTable } from '@src/pages/randomizer/RandomizerInputUsersTable';
import { RandomizerConfirmUsersTable } from '@src/pages/randomizer/RandomizerConfirmUsersTable';
import { santaClient } from '@src/state/client';
import { t, TRANSLATIONS } from '@src/translations';
import { RandomizerPageProps } from '@src/pages/randomizer/RandomizerPageProps';
import { AdLookIBV } from '@src/components/AdLookIBV';

function RandomizerPageComponent(props: RandomizerPageProps) {
  const [drawDone, setDrawDone] = useState(false);
  const [orgParticipate, setOrgParticipate] = useState(true);
  const [step, setStep] = useState(1);
  const [error, setError] = useState(undefined);

  if (drawDone) {
    return (
      <FormCard
        title="quick_draw"
        translator={props.translator}
        step=""
        btnRightTitle="back_to_home"
        onSubmit={() => {
          props.history.push('/');
        }}
        onBack={() => {
          props.history.push('/');
        }}
        btnLeft={<div />}
      >
        <ColumnCenter>
          <PictureNotice
            translator={props.translator}
            title="randomizer_done"
            hint="randomizer_done_hint"
          >
            <PenguinWithRaisedHands />
          </PictureNotice>
          <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
        </ColumnCenter>
      </FormCard>
    );
  }

  const onSubmit = async () => {
    setError(undefined);
    if (step === 1) {
      setStep(2);
    }
    if (step === 2) {
      const cards = props.randomizer.users.map((u) => ({ id: u.email }));
      const userMap = {};
      props.randomizer.users.forEach((u) => {
        userMap[u.email] = u;
      });
      const res = randomize(cards);
      const users = [];
      for (const c of res) {
        const user = userMap[c.id];
        const ward = userMap[c.wardId];
        users.push({
          username: user.username,
          email: user.email,
          wardUsername: ward.username,
          wardEmail: ward.email,
        });
      }
      dispatchActionAppUpdateState({ randomizer: { users } });
      setStep(3);
    }
    if (step === 3) {
      const res = await santaClient.randomizeCards(props.randomizer);
      if (res.ok) {
        dispatchActionAppUpdateState({ randomizer: new RandomizerInput() });
        setDrawDone(true);
      } else if (res.error.name === 'ValidationError') {
        const err = res.error.errors[0];
        setError(t(TRANSLATIONS[err.transKey], { field: err.field, ...err.params }));
      } else {
        setError(res.error.message);
      }
    }
  };

  const onBack = () => {
    setError(undefined);
    if (step > 1) {
      setStep(step - 1);
    } else {
      props.history.push('/');
    }
  };

  const stepOneComponent = (
    <>
      <Tip blueberry icon="quick">
        randomizer_tip
      </Tip>
      <GridContainer spacing={2}>
        <GridColumn xs={12} md={6}>
          <Input
            label="your_name"
            value={props.randomizer.organizerUsername}
            validators={[validateField(props.randomizer, 'organizerUsername')]}
            onChange={(organizerUsername) => {
              dispatchActionAppUpdateState({ randomizer: { organizerUsername } });
            }}
          />
        </GridColumn>
        <GridColumn xs={12} md={6}>
          <Input
            label="your_email"
            value={props.randomizer.organizerEmail}
            validators={[validateField(props.randomizer, 'organizerEmail')]}
            onChange={(organizerEmail) => {
              dispatchActionAppUpdateState({ randomizer: { organizerEmail } });
            }}
          />
        </GridColumn>
      </GridContainer>
      <SwitchPanel
        title="i_will_also_participate"
        hint="i_will_also_participate_hint"
        value={orgParticipate}
        onChange={(val) => setOrgParticipate(val)}
      />
      <ColumnCenter>
        <TextSecondaryMedium grey style={{ textAlign: 'center' }}>
          randomizer_policy_agreement_hint
        </TextSecondaryMedium>
      </ColumnCenter>
    </>
  );

  const stepTwoComponent = (
    <>
      <Tip blueberry icon="group">
        randomizer_many_users
      </Tip>
      <RandomizerInputUsersTable
        randomizerInput={props.randomizer}
        orgParticipate={orgParticipate}
        onChange={(users) => {
          dispatchActionAppUpdateState({ randomizer: { users } });
        }}
      />
    </>
  );

  const stepThreeComponent = (
    <>
      <Tip green icon="info">
        randomizer_confirm_users
      </Tip>
      <RandomizerConfirmUsersTable
        randomizerInput={props.randomizer}
        orgParticipate={orgParticipate}
      />
    </>
  );

  let stepComponent = stepOneComponent;
  if (step === 2) {
    stepComponent = stepTwoComponent;
  } else if (step === 3) {
    stepComponent = stepThreeComponent;
  }
  return (
    <FormCard
      title="quick_draw"
      translator={props.translator}
      step={t(TRANSLATIONS.randomizer_step, { step })}
      onSubmit={onSubmit}
      onBack={onBack}
      error={error}
    >
      {stepComponent}
      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </FormCard>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    randomizer: state.randomizer,
  };
}

export const RandomizerPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(RandomizerPageComponent, { noFooter: true, wide: true }),
);
