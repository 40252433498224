import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import {
  ButtonNav,
  HeaderSecondary,
  InfoPanel,
  RowStart,
  SantaLink,
  TextH3Semi,
  Expandable,
  ExpandableGroup,
  ButtonSecondary,
  Input,
  ColumnStart,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { useState } from 'react';
import { Lang } from '@src/types/lang';
import { AdLookIBV } from '@src/components/AdLookIBV';

const navStyle = { marginRight: '1rem', whiteSpace: 'noWrap' };

function filterQuestion(query: string, question: string, search: string) {
  const searchWords = query.split(' ');
  const str = `${question} ${search}`.toLowerCase();
  for (const w of searchWords) {
    if (!str.includes(w)) {
      return false;
    }
  }
  return true;
}

function FAQPageComponent() {
  const [activeId, setActiveId] = useState('orgs');
  const [searchQuery, setSearchQuery] = useState('');
  const allQuestions = TRANSLATIONS.questions.map((q) => q.questions);
  const all = [];
  for (const qsts of allQuestions) {
    all.push(...qsts);
  }
  const translatedQuestions = all.map((q) => ({
    id: q.question.translations[Lang.RU],
    search: q.search,
    question: t(q.question),
    answer: t(q.answer),
  }));
  const questions = searchQuery.length
    ? translatedQuestions.filter((q) => filterQuestion(searchQuery, q.question, q.search))
    : [];
  const uniqueQ = [];
  for (const q of questions) {
    const alreadyHas = uniqueQ.find((x) => x.question === q.question);
    if (!alreadyHas) {
      uniqueQ.push(q);
    }
  }

  const questionsRender =
    uniqueQ.length || searchQuery.length
      ? uniqueQ.map((q) => (
          <Expandable key={q.id} title={q.question}>
            {q.answer}
          </Expandable>
        ))
      : TRANSLATIONS.questions
          .find((q) => q.id === activeId)
          .questions.map((q) => (
            <Expandable key={q.question.translations[Lang.RU]} title={t(q.question)}>
              {t(q.answer)}
            </Expandable>
          ));

  return (
    <div>
      <section style={{ paddingTop: '3rem', paddingBottom: '4rem' }}>
        <div>
          <ColumnStart>
            <RowStart alignCenter style={{ overflowX: 'auto', marginBottom: '1rem' }}>
              {TRANSLATIONS.questions.map((q) => (
                <ButtonNav
                  key={q.id}
                  active={searchQuery.length ? false : activeId === q.id}
                  style={navStyle}
                  onClick={() => setActiveId(q.id)}
                >
                  {t(q.title)}
                </ButtonNav>
              ))}
            </RowStart>
            <Input label={t(TRANSLATIONS.faq_search)} name="search" onChange={setSearchQuery} />
          </ColumnStart>
          <ExpandableGroup>{questionsRender}</ExpandableGroup>
        </div>
        <AdLookIBV numAdsMobile={1} numAdsDesktop={4} />
        <InfoPanel
          title={t('faq_more_questions_title')}
          message={t('faq_more_questions_message')}
          style={{ marginTop: '5rem' }}
        >
          <SantaLink to="/feedback">
            <ButtonSecondary>{t('faq_more_questions_write')}</ButtonSecondary>
          </SantaLink>
        </InfoPanel>
      </section>
    </div>
  );
}

export const FAQPage = connect(mapStateToPropsBasePage)(
  withBasePage<BasePageProps>(FAQPageComponent, {
    headerSecondary: (
      <HeaderSecondary shadow={true} leftItem={<TextH3Semi>{t('faq_title')}</TextH3Semi>} />
    ),
    headerShadow: false,
  }),
);
