import * as React from 'react';
import { store } from '@src/state/store';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { App } from '@src/App';
import { BrowserRouter } from 'react-router-dom';

declare let module: Record<string, unknown>;

class Root extends React.Component<Record<string, unknown>, undefined> {
  public render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    );
  }
}

export const AppHot = hot(module)(Root);
