import Cookies from 'universal-cookie';
import * as CryptoJS from 'crypto-js';

export { removeCookie, setCookie, getCookie };

const SECRET_KEY = '5$6^%!@31`232142%4d#F9*^!23&';

function removeCookie(key: string) {
  try {
    new Cookies().remove(key);
  } catch (e) {
    // console.error(e);
  }
}

function setCookie(key: string, value: string, simple = false) {
  try {
    const cookies = new Cookies();
    if (simple) {
      cookies.set(key, value);
    } else {
      const encrypted = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
      cookies.set(key, encrypted);
    }
  } catch (e) {
    console.log(e);
  }
}

function getCookie(key: string, simple = false) {
  try {
    const cookies = new Cookies();
    const value = cookies.get(key);
    if (simple) {
      return value;
    }
    if (value !== null) {
      return CryptoJS.AES.decrypt(value, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    }
    return null;
  } catch (e) {
    return null;
  }
}
