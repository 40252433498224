import * as React from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonMain,
  CatCute,
  CatPlayful,
  ColumnCenter,
  ColumnStart,
  ErrorBoundary,
  GridColumn,
  GridContainer,
  PictureNotice,
  SantaLink,
  TextMedium,
  TextSecondaryMedium,
  Tip,
} from '@src/components';
import { BoxWardQuestionComponent } from '@src/pages/box/ward/BoxWardQuestion';
import { BoxCardLoadingComponent } from '@src/pages/box/loading/BoxCardLoading';
import { WardCardComponent } from '@src/pages/box/ward/WardCardComponent';
import { Gifts } from '@src/components/Gifts';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { t, TRANSLATIONS } from '@src/translations';
import { SantaDonatePanel } from '@src/components/SantaDonatePanel';
import { isDotCom } from '@src/host';
import { ChatWithSanta } from '@src/pages/box/card/ChatWithSanta';
import { ChatWithWard } from '@src/pages/box/ward/ChatWithWard';

const YouAreNotLoggedInProperlyComponent = (props: BoxPageProps) => (
  <ColumnCenter style={{ maxWidth: '40rem', marginLeft: 'auto', marginRight: 'auto' }}>
    <CatPlayful style={{ maxWidth: '15rem' }} />
    <TextMedium translator={props.translator} style={{ marginTop: '1rem' }}>
      {t(TRANSLATIONS.card_no_card_active_draw_hint_not_registered, {
        loginLink: `/login?redirect=/box/${props.box.info.box.key}`,
        isLoggedIn: props.session,
      })}
    </TextMedium>
    <ButtonMain
      translator={props.translator}
      onClick={() => {
        props.history.push(`/login?redirect=/box/${props.box.info.box.key}`);
      }}
    >
      card_login_to_site
    </ButtonMain>
  </ColumnCenter>
);

export const BoxWardComponent = (props: BoxPageProps) => {
  if (!props.box.info.isActiveDraw) {
    if (props.box.info.isAdmin) {
      return (
        <ColumnCenter style={{ marginTop: '4rem' }}>
          <PictureNotice
            translator={props.translator}
            title="ward_no_ward_admin_title"
            hint="ward_no_ward_admin_hint"
            button="draw_not_active_button"
            onClick={() => {
              props.history.push(`/box/${props.box.info.box.key}/draw`);
            }}
          >
            <CatCute />
          </PictureNotice>
        </ColumnCenter>
      );
    }

    return (
      <ColumnCenter style={{ marginTop: '4rem' }}>
        <PictureNotice
          translator={props.translator}
          title="ward_no_ward_title"
          hint="ward_no_ward_hint"
          button="back_to_box"
          onClick={() => {
            props.history.push(`/box/${props.box.info.box.key}`);
          }}
        >
          <CatPlayful />
        </PictureNotice>
      </ColumnCenter>
    );
  }

  if (!props.box.ward) {
    return <YouAreNotLoggedInProperlyComponent {...props} />;
  }

  const { card } = props.box;
  if (!card) {
    return <BoxCardLoadingComponent {...props} />;
  }

  if (!card.isWardKnown) {
    return <BoxWardQuestionComponent {...props} />;
  }

  const cashLimit = props.box.info.box.useCashLimit && props.box.info.box.cashLimit;
  const cashLimitCurrency = props.box.info.box.useCashLimit && props.box.info.box.cashLimitCurrency;

  const rightSection = isDotCom() ? (
    <>
      <GridColumn xs={12} md={6} style={props.css.mobile ? {} : { minHeight: '40rem' }}>
        {props.css.mobile ? null : <ChatWithWard {...props} onClose={null} />}
      </GridColumn>
    </>
  ) : (
    <GridColumn xs={12} md={6} style={{ minHeight: '44rem' }}>
      <Gifts />
    </GridColumn>
  );
  return (
    <ColumnStart>
      {props.box.info.box.key !== 'sanofi' ? <SantaDonatePanel /> : null}
      {cashLimit && cashLimitCurrency ? (
        <Tip noIcon translator={props.translator} blueberry>
          <>
            {t(TRANSLATIONS.create_card_step_wish_hint_cash_limit)}
            <b>
              {t(TRANSLATIONS.currency[cashLimitCurrency].num, {
                count: cashLimit,
                count1: cashLimit,
              })}
              .
            </b>
          </>
        </Tip>
      ) : null}
      <GridContainer spacing={5}>
        <GridColumn xs={12} md={6} style={{ height: props.css.mobile ? '40rem' : '44rem' }}>
          <ErrorBoundary>
            <WardCardComponent {...props} />
          </ErrorBoundary>
        </GridColumn>
        {rightSection}
      </GridContainer>
    </ColumnStart>
  );
};
