export function getApiHost() {
  const { host } = window.location;
  if (host.includes('localhost')) {
    return 'http://localhost:8000';
  }
  return '';
}

export function getFrontendHost() {
  return window.location.origin;
}

export function isDotCom() {
  //return true;
  return window.location.href.includes('santa-secret.com');
}
