import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import {
  ButtonMain,
  ButtonSecondary,
  ColumnCenter,
  HomePageContainer,
  RowCenter,
  SantaLink,
  SimpleLink,
  TextH3Medium,
  TextH3Semi,
  TextRegular,
} from '@src/components';
import { FAQHome } from '@src/pages/FAQHome';
import { t } from '@src/translations';
import { BoxberryAd } from '@src/pages/box/ward/BoxberryAd';
import { isDotCom } from '@src/host';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { AdLookIBV } from '@src/components/AdLookIBV';

function HomePageComponent(props: BasePageProps & any) {
  return (
    <div className={props.className}>
      <section style={{ marginTop: '2rem' }}>
        <HomePageContainer
          translator={props.translator}
          lang={props.lang}
          buttonLeft={
            <SantaLink to="/box/new">
              <ButtonMain
                translator={props.translator}
                style={{ width: '100%', minWidth: '15rem', maxWidth: '25rem' }}
              >
                create_box
              </ButtonMain>
            </SantaLink>
          }
          buttonRight={
            <SantaLink to="/randomizer">
              <ButtonSecondary
                translator={props.translator}
                style={{ width: '100%', minWidth: '15rem', maxWidth: '25rem' }}
              >
                quick_draw
              </ButtonSecondary>
            </SantaLink>
          }
        >
          <SantaLink to="/box/new">
            <ButtonMain style={{ maxWidth: '15rem', margin: 'auto' }}>create_box</ButtonMain>
          </SantaLink>
          {isDotCom() ? null : (
            <ColumnCenter style={{ marginTop: '3rem' }}>
              <TextH3Semi style={{ marginBottom: '1rem' }}>Или подари себе...</TextH3Semi>
              <div style={{ position: 'relative' }}>
                <TextRegular
                  style={{
                    position: 'absolute',
                    fontSize: props.css.mobile ? '10px' : '12px',
                    color: '#0e327b',
                    bottom: props.css.mobile ? '2px' : '7px',
                    left: '1.3rem',
                  }}
                >
                  Реклама. ИП Суриков Сергей Сергеевич, ИНН 270815293493
                </TextRegular>
                <a
                  href="https://www.ibicheskiy.ru/calendar?erid=LjN8K4zbx&utm_source=santa-secret"
                  target="_blank"
                  onClick={() => reachGoalYaMetrika('ibicheskiy')}
                >
                  <img
                    src="https://static-santa-secret-ru.b-cdn.net/ibichesky.png"
                    style={{ maxWidth: '40rem', width: '96%' }}
                  />
                  {props.css.mobile ? null : (
                    <ButtonMain
                      style={{
                        position: 'absolute',
                        bottom: '3rem',
                        left: '32%',
                        height: '2.6rem',
                      }}
                    >
                      Узнать подробности
                    </ButtonMain>
                  )}
                </a>
              </div>
            </ColumnCenter>
          )}

          <FAQHome {...props} />
          <ColumnCenter style={{ marginBottom: '2rem' }}>
            <BoxberryAd />
          </ColumnCenter>
          <AdLookIBV numAdsDesktop={4} numAdsMobile={1} />
        </HomePageContainer>
      </section>
    </div>
  );
}

export const HomePage = connect(mapStateToPropsBasePage)(
  withBasePage<BasePageProps>(HomePageComponent, {
    headerHome: true,
    headerFixed: true,
    counter: true,
  }),
);
