import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const RESEND_TRANSLATIONS = {
  resend_title: _('Переотправить письмо', 'Resend Email'),
  resend_next: _('Далее', 'Next'),
  resend_confirm: _('Отправить', 'Send'),
  resend_back: _('Назад', 'Back'),
  resend_done: _('Закрыть', 'Close'),
  resend_no_notifications_found: _(
    'По данному адресу не было найдено ни одного письма',
    'No emails were found for this address',
  ),
  resend_tip: _(
    <>
      Прежде чем переотправлять письмо, попробуйте поискать его по отправителю{' '}
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <b>postman@santa-secret.ru</b>, возможно, письмо просто не попало в папку "входящие" и вы его
      пропустили.
      <br />
    </>,
    <>
      Before resending the email, try searching for it by the sender <b>postman@santa-secret.ru</b>.
      It's possible that the email simply didn't arrive in the "inbox" and you missed it.
      <br />
    </>,
  ),
  resend_tip2: _(
    <>
      Вы можете переотправить письмо <b>не более двух раз</b>. Если письмо так и не дошло, то,
      скорее всего, проблема на вашей стороне. Например, часто бывает, что ящик переполнен и
      требудет освобождения места для того чтобы письма начали приходить. Или ваша корпоративная
      почта запрещает принимать письма с подобных сайтов - обратитесь в IT отдел и уточните.
    </>,
    <>
      You can resend the email <b>no more than two times</b>. If the email still does not arrive,
      the issue is likely on your end. For example, it's common for mailboxes to be full and need
      space freed up for new emails to arrive. Or your corporate email may prohibit receiving emails
      from such sites - check with your IT department for clarification.
    </>,
  ),
  resend_counter: _('Уже переотправлено {count раз||а|}', 'Already resent {count time||s|}'),
  resend_sending: _('Отправляется', 'Sending'),
  resend_sent_recently: _('Отправлено недавно', 'Sent recently'),
  resend_send: _('Отправить', 'Send'),
  resend_viewed: _(' Было просмотрено', ' Was viewed'),
  resend_sent: _(
    (at) => `Отправлено: ${at}`,
    (at) => `Sent: ${at}`,
  ),
};
