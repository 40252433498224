import * as React from 'react';
import { BoxPageProps, mapStateToPropsBoxBasePage } from '@src/pages/box/BoxPage';
import { connect } from 'react-redux';
import { BOX_PATH } from '@src/pages/box/boxHelpers';
import { BoxParticipantsComponent } from '@src/pages/box/BoxParticipants';
import { BoxInviteComponent } from '@src/pages/box/BoxInvite';
import { BoxSettingsComponent } from '@src/pages/box/BoxSettings';
import { EditOtherCardForm } from '@src/pages/box/card/EditOtherCardForm';
import { EditCardForm } from '@src/pages/box/card/EditCardForm';
import { BoxCardComponent } from '@src/pages/box/card/BoxCard';
import { BoxWardComponent } from '@src/pages/box/ward/BoxWard';
import { BoxDeleteComponent } from '@src/pages/box/BoxDelete';
import { Switch } from 'react-router';
import { BoxDrawComponent } from '@src/pages/box/draw/BoxDraw';
import { BoxSantasComponent } from '@src/pages/box/BoxSantas';
import { ColumnStart } from '@src/components';
import { Route } from 'react-router-dom';
import { BoxParticipantsTableComponent } from '@src/pages/box/BoxParticipantsTable';

export const BoxPageRoutes = connect(mapStateToPropsBoxBasePage)((props: BoxPageProps) => (
  <ColumnStart style={{ marginTop: '2rem', marginBottom: '4rem', height: '100%' }}>
    <Switch>
      <Route exact path={BOX_PATH}>
        <BoxParticipantsComponent
          translator={props.translator}
          boxInfo={props.box.info}
          cards={props.box.cards}
          card={props.box.card}
          mobile={props.css.mobile}
          historyPush={props.history.push}
        />
      </Route>
      <Route exact path={`${BOX_PATH}/participants`}>
        <BoxParticipantsComponent
          translator={props.translator}
          boxInfo={props.box.info}
          cards={props.box.cards}
          card={props.box.card}
          mobile={props.css.mobile}
          historyPush={props.history.push}
        />
      </Route>
      <Route path={`${BOX_PATH}/invite`}>
        <BoxInviteComponent {...props} />
      </Route>
      <Route path={`${BOX_PATH}/settings`} {...props}>
        <BoxSettingsComponent {...props} />
      </Route>
      <Route path={`${BOX_PATH}/draw`}>
        <BoxDrawComponent {...props} />
      </Route>
      <Route path={`${BOX_PATH}/santas`}>
        <BoxParticipantsTableComponent
          translator={props.translator}
          boxInfo={props.box.info}
          cards={props.box.cards}
          card={props.box.card}
          mobile={props.css.mobile}
          historyPush={props.history.push}
        />
      </Route>
      <Route path={`${BOX_PATH}/delete`}>
        <BoxDeleteComponent {...props} />
      </Route>
      <Route exact path={`${BOX_PATH}/card/:id/edit`}>
        <EditOtherCardForm {...props} />
      </Route>
      <Route exact path={`${BOX_PATH}/card/edit`}>
        <EditCardForm {...props} />
      </Route>
      <Route exact path={`${BOX_PATH}/card`}>
        <BoxCardComponent {...props} />
      </Route>
      <Route path={`${BOX_PATH}/ward`}>
        <BoxWardComponent {...props} />
      </Route>
    </Switch>
  </ColumnStart>
));
