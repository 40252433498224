export enum LocationPageName {
  NEW_BOX = 'newBox',
  RESET_PASSWORD = 'reset',
  LOGIN = 'login',
  REGISTER = 'register',
  RECOVER = 'recover',
  RANDOMIZER = 'randomizer',
  POLICY = 'policy',
  HOME = '/',
}

export class SantaLocation {
  location: any;

  constructor(loc) {
    this.location = loc;
  }

  isLoginRelatedPage() {
    return [
      LocationPageName.LOGIN,
      LocationPageName.REGISTER,
      LocationPageName.RECOVER,
      LocationPageName.RESET_PASSWORD,
    ].includes(this.getPageName());
  }

  isPolicyPage() {
    return [LocationPageName.POLICY].includes(this.getPageName());
  }

  isLoginPage() {
    return [LocationPageName.LOGIN].includes(this.getPageName());
  }

  isHomePage() {
    if (!this.location) {
      return false;
    }
    return this.getPageName() === LocationPageName.HOME;
  }

  getPath(): string {
    if (!this.location) {
      return null;
    }
    return this.location.pathname;
  }

  getPathWithQuery(): string {
    if (!this.location) {
      return null;
    }
    return this.location.pathname + this.location.search;
  }

  getPageName(): LocationPageName {
    if (!this.location) {
      return null;
    }
    const path = this.location.pathname;
    if (path === '/') {
      return LocationPageName.HOME;
    }
    switch (this.location.pathname) {
      case '/box/new':
        return LocationPageName.NEW_BOX;
      case '/reset_password':
        return LocationPageName.RESET_PASSWORD;
      default:
        return path.substring(1);
    }
  }
}
