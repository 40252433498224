import * as React from 'react';

import { BoxInfo, CardInfo, FullCardInfo } from '@lub0v/santa-utils';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import {
  CatSad,
  ColumnCenter,
  ColumnStart,
  ErrorBoundary,
  Heading,
  PictureNotice,
} from '@src/components';
import { ParticipantsTableComponent } from '@src/pages/box/ParticipantsTable';

type BoxParticipantsProps = {
  translator: any;
  historyPush: (path: string) => void;
  boxInfo: BoxInfo;
  card: FullCardInfo;
  cards: CardInfo[];
  mobile: boolean;
};

const NoOneHereComponent = (props: BoxParticipantsProps) => (
  <ColumnCenter style={{ marginTop: '3rem' }}>
    <PictureNotice
      translator={props.translator}
      title="no_participants"
      hint="no_participants_hint"
      button="add_participants"
      onClick={() => {
        props.historyPush(getBoxPath('/invite'));
      }}
    >
      <CatSad />
    </PictureNotice>
  </ColumnCenter>
);

export const BoxParticipantsTableComponent = (props: BoxParticipantsProps) => {
  if (!props.boxInfo.isAdmin) {
    return null;
  }
  if (props.boxInfo.participantsNumber === 0) {
    return <NoOneHereComponent {...props} />;
  }
  return (
    <ColumnStart h100>
      <Heading translator={props.translator}>box_table_title</Heading>
      <ErrorBoundary>
        <ParticipantsTableComponent {...props} />
      </ErrorBoundary>
    </ColumnStart>
  );
};
