import {
  ButtonService,
  RowSpaceBetween,
  RowStart,
  SimpleLink,
  TextMedium,
  TextRegular,
} from '@src/components';
import * as React from 'react';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { t } from '@src/translations';
import { isDotCom } from '@src/host';

export function BoxberryAd() {
  if (isDotCom()) {
    return null;
  }
  const link =
    'https://360.boxberry.ru/?erid=LjN8KCiz3&id_promo=santa_secret&utm_source=santa_secret&utm_medium=cpa&utm_campaign=2023';
  const image =
    'https://habrastorage.org/getpro/moikrug/uploads/company/100/004/839/7/logo/medium_72acded3acd45e4c8b6ed680854b8ab1.jpg';

  return (
    <SimpleLink
      href={link}
      target={'_blank'}
      onClick={() => {
        reachGoalYaMetrika('boxberry');
      }}
    >
      <RowSpaceBetween
        alignCenter
        style={{
          overflow: 'auto',
          minWidth: '100%',
          position: 'relative',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        }}
      >
        <RowStart style={{ minWidth: '30rem', overflow: 'auto' }}>
          <img alt={'Boxberry'} style={{ marginTop: '-0.2rem' }} width={'30px'} src={image} />
          <TextMedium>
            <TextMedium red>Boxberry</TextMedium>&nbsp; {t('boxberry_will_send')} <b>СЕКРЕТ18</b>
          </TextMedium>
          <ButtonService style={{ marginLeft: '0.5rem', width: '7rem' }}>
            {t('boxberry_more')}
          </ButtonService>
        </RowStart>
        <TextRegular
          grey
          style={{ position: 'absolute', bottom: '-0.35rem', right: '1rem', fontSize: '11px' }}
        >
          реклама ООО "Боксберри Софт", ИНН: 6685157931
        </TextRegular>
      </RowSpaceBetween>
    </SimpleLink>
  );
}
