import * as React from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonMain,
  ColumnStart,
  FormPage,
  FormPageGroup,
  GridColumn,
  GridContainer,
  Hint,
  RowEnd,
  SantaLink,
  TextSecondaryRegular,
  Tip,
  UserCard,
} from '@src/components';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState, loadBoxInfo } from '@src/state';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { DeleteSection } from '@src/helpers/DeleteSection';
import { BoxDrawSwapWard } from '@src/pages/box/draw/BoxDrawSwapWard';
import { BoxDrawProblemReset } from '@src/pages/box/draw/BoxDrawProblemReset';
import { BoxDrawProblemAdditionalDraw } from '@src/pages/box/draw/BoxDrawProblemAdditionalDraw';

export const BoxDrawProblemComponent = (props: BoxPageProps) => {
  const noWards = props.box.cards.filter((c) => !c.wardId);
  const noWardsLength = noWards.length;

  const card = noWards[0];

  const deleteCard = async () => {
    const res = await santaClient.deleteOtherCard({
      key: props.box.info.box.key,
      id: card.id,
    });
    if (res.ok) {
      dispatchActionAppUpdateState({
        box: { otherCard: null, editOtherCard: null },
      });
      props.history.push(`/box/${props.box.info.box.key}`);
      await loadBoxInfo(props.box.info.box.key, props.box.join);
    }
  };

  const userCards = noWards.map((c) => (
    <GridColumn key={`card-${c.id}`} item xs={12} sm={6} md={4} lg={3}>
      <UserCard
        picture={c.picture}
        name={c.username}
        verified={c.isVerified}
        fixed
        style={{ margin: 'auto' }}
      >
        {!c.isVerified ? (
          <TextSecondaryRegular grey translator={props.translator}>
            draw_wait_for_filling
          </TextSecondaryRegular>
        ) : null}
      </UserCard>
    </GridColumn>
  ));

  return (
    <ColumnStart>
      <Tip yellow icon={'info'} translator={props.translator}>
        {noWardsLength === 1 ? 'draw_problem_one_user' : 'draw_problem_several_users'}
      </Tip>
      <GridContainer>{userCards}</GridContainer>
      <FormPage title="draw_problem_solve_title" translator={props.translator} onBack={() => {}}>
        <BoxDrawProblemReset {...props} />
        {noWards.length === 1 ? (
          <>
            <FormPageGroup title="draw_problem_invite_title" overflowScroll>
              <ColumnStart>
                <Hint>draw_problem_invite_hint</Hint>
                <RowEnd>
                  <SantaLink to={getBoxPath('/invite')}>
                    <ButtonMain>box_invite_title</ButtonMain>
                  </SantaLink>
                </RowEnd>
              </ColumnStart>
            </FormPageGroup>
            <FormPageGroup title="draw_problem_delete_title" overflowScroll>
              <ColumnStart>
                <DeleteSection
                  translator={props.translator}
                  button="edit_other_card_delete_button"
                  confirmDeleteWord="edit_other_card_delete_confirm_word"
                  onClick={deleteCard}
                >
                  <Hint>draw_problem_delete_hint</Hint>
                </DeleteSection>
              </ColumnStart>
            </FormPageGroup>
          </>
        ) : (
          <BoxDrawProblemAdditionalDraw {...props} />
        )}
        <BoxDrawSwapWard {...props} />
      </FormPage>
    </ColumnStart>
  );
};
