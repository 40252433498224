import { browserClient } from '@lub0v/santa-client';
import { getApiHost } from '@src/host';
import { dispatchActionModal } from '@src/state/actions';

const API_HOST = getApiHost();

const onUnauthorized = (): Promise<void> => {
  dispatchActionModal({
    opened: true,
    title: 'modal_logged_out_title',
    message: 'modal_logged_out_message',
    buttonYes: 'modal_logged_out_button',
    loading: false,
    onSubmit: async () => {
      window.location.href = '/login';
      // window.location.reload();
    },
  });
  return Promise.resolve();
};
export const santaClient = browserClient(API_HOST, async (status, response) => {
  if (status === 401) {
    await onUnauthorized();
  }
  if (status === 429) {
    console.log(response);
    if (response && response.data && !response.data.includes('Too many requests')) {
      dispatchActionModal({
        opened: true,
        title: 'modal_server_busy_title',
        message: 'modal_server_busy_message',
        buttonYes: 'modal_server_busy_button',
        loading: false,
        onSubmit: async () => {
          window.location.href = '/';
        },
      });
    } else {
      dispatchActionModal({
        opened: true,
        title: 'modal_too_many_requests_title',
        message: 'modal_too_many_requests_message',
        buttonYes: 'modal_too_many_requests_button',
        loading: false,
        onSubmit: async () => {
          window.location.href = '/';
        },
      });
    }
  }
});
