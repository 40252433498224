import * as React from 'react';
import {
  ColumnCenter,
  RowSpaceAround,
  RowSpaceBetween,
  TextMedium,
  TextRegular,
} from '@src/components';
import { CssProps, SantaAppState } from '@src/state';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getApiHost } from '@src/host';

const SRC = 'https://cdn.adlook.me/js/vbf.js';

type Props = {
  showAds?: boolean;
  numAdsDesktop?: number;
  numAdsMobile?: number;
  css: CssProps;
  row?: boolean;
};

function AdLookIBVComponent({
  showAds = true,
  numAdsDesktop = 1,
  numAdsMobile = 1,
  css,
  row = true,
}: Props) {
  if (!showAds) {
    return null;
  }

  const numAds = css.mobile ? numAdsMobile : numAdsDesktop;
  useEffect(() => {
    const adlookScript = document.querySelector(`script[src="${SRC}"]`);
    if (adlookScript) {
      adlookScript.remove();
    }

    setTimeout(() => {
      const scriptElement = document.createElement('script');
      scriptElement.src = SRC;
      scriptElement.defer = true;
      scriptElement.async = true;
      scriptElement.charset = 'UTF-8';
      document.body.appendChild(scriptElement);
    }, 500);
  }, []);

  const ids = getApiHost().includes('localhost') ? [2, 2, 2] : [24355, 24356, 24357, 24441];

  const adBlock = (id) => {
    return (
      <ColumnCenter
        key={id}
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
          width: '300px',
          background: '',
        }}
      >
        <div
          className="tgx-vbf"
          data-vbf-id={id}
          data-vbf-exp="1"
          data-vbf-hide="0"
          data-vbf-loop="10"
          data-vbf-w="300"
          data-vbf-h="250"
        />
      </ColumnCenter>
    );
  };

  const ads = [];
  for (let i = 0; i < numAds; i++) {
    if (i < ids.length) {
      ads.push(adBlock(ids[i]));
    }
  }
  if (row) {
    return <RowSpaceAround>{ads}</RowSpaceAround>;
  }
  return <ColumnCenter>{ads}</ColumnCenter>;
}

export function mapStateToProps(state: SantaAppState) {
  return {
    css: state.css,
    showAds: state.showAds,
  };
}

export const AdLookIBV = connect(mapStateToProps)(AdLookIBVComponent);
