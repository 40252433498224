import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { Gifts } from '@src/components/Gifts';
import { ColumnCenter } from '@src/components';

export const GiftsPage = connect(mapStateToPropsBasePage)(
  withBasePage<BasePageProps>(() => (
    <ColumnCenter style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <Gifts />
    </ColumnCenter>
  )),
);
