import * as React from 'react';
import { useState } from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonMain,
  FormPage,
  FormPageGroup,
  Hint,
  RowCenter,
  SwitchPanel,
  TextSecondaryMedium,
  Tip,
} from '@src/components';
import { dispatchActionAppUpdateState, loadBoxInfo } from '@src/state';
import { BoxInviteUsersTable } from '@src/pages/box/BoxInviteUsersTable';
import { t, TRANSLATIONS } from '@src/translations';
import { santaClient } from '@src/state/client';
import { BoxInviteInput, CreateMyCardInput } from '@lub0v/santa-utils';
import { getBoxPath } from '@src/pages/box/boxHelpers';

export const BoxInviteComponent = (props: BoxPageProps) => {
  const [invited, setInvited] = useState(false);
  const [error, setError] = useState(undefined);

  if (props.box.info.box.isArchived) {
    return (
      <Hint center translator={props.translator}>
        box_invite_archived
      </Hint>
    );
  }

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(props.box.info.inviteLink);
      dispatchActionAppUpdateState({
        box: {
          saved: 'box_invite_link_copied',
        },
      });
    } catch (err) {
      console.error(`Failed to copy link to clipboard :( ${err}`);
    }
  };

  const inviteUsers = async () => {
    const res = await santaClient.invite(props.box.invite);
    if (res.ok) {
      const cardsRes = await santaClient.getCards({ key: props.box.info.box.key });
      const invite = new BoxInviteInput();
      invite.isInviteAfterDraw = props.box.invite.isInviteAfterDraw;
      invite.key = props.box.info.box.key;
      dispatchActionAppUpdateState({ box: { invite, cards: cardsRes.data } });
      setError(undefined);
      setInvited(true);
      await loadBoxInfo(props.box.info.box.key, props.join);
    } else {
      setError(t(res.error.message, { data: res.error.data }));
    }
  };

  return (
    <FormPage
      title="box_invite_title"
      onSubmit={inviteUsers}
      buttonSubmit={invited ? null : 'box_invite_users_button'}
      buttonBack="box_settings_back_button"
      onBack={() => props.history.push(getBoxPath(''))}
      translator={props.translator}
      error={error}
    >
      <FormPageGroup title="box_invite_organizer_participating">
        {props.box.card ? (
          <Tip green icon="info">
            box_invite_org_card_created_hint
          </Tip>
        ) : (
          <>
            <Tip icon="info">box_invite_create_org_card_hint</Tip>
            <RowCenter>
              <ButtonMain
                onClick={() => {
                  const username = props.session ? props.session.username : '';
                  dispatchActionAppUpdateState({
                    box: { createCard: { step: 1, input: { username } } },
                  });
                  props.history.push(`/box/${props.box.info.box.key}/card`);
                }}
              >
                box_invite_create_org_card
              </ButtonMain>
            </RowCenter>
          </>
        )}
      </FormPageGroup>
      <FormPageGroup title="box_invite_users_with_link">
        {props.box.info.isActiveDraw ? (
          <Tip blue icon="link">
            {t(TRANSLATIONS.box_invite_by_link_disabled)}
          </Tip>
        ) : (
          <Tip blue icon="link" style={{ cursor: 'pointer' }} onClick={copyLinkToClipboard}>
            {t(TRANSLATIONS.box_invite_by_link_tip, { link: props.box.info.inviteLink })}
          </Tip>
        )}
      </FormPageGroup>
      <FormPageGroup title="box_invite_users">
        {invited ? (
          <Tip green icon="group">
            {props.box.invite.isInviteAfterDraw
              ? 'box_invite_users_invited_after_draw'
              : 'box_invite_users_invited'}{' '}
            <TextSecondaryMedium underline green onClick={() => setInvited(false)}>
              box_invite_more_users
            </TextSecondaryMedium>
          </Tip>
        ) : (
          <>
            <Tip icon="group">box_invite_by_table_tip</Tip>
            <SwitchPanel
              title="box_invite_after_draw"
              hint="box_invite_after_draw_hint"
              hintBig="box_invite_after_draw_hint_big"
              value={props.box.invite.isInviteAfterDraw}
              onChange={(isInviteAfterDraw) => {
                dispatchActionAppUpdateState({ box: { invite: { isInviteAfterDraw } } });
              }}
            />
            <BoxInviteUsersTable
              inviteInput={props.box.invite}
              onChange={(users) => {
                setError(undefined);
                dispatchActionAppUpdateState({ box: { invite: { users } } });
              }}
            />
          </>
        )}
      </FormPageGroup>
    </FormPage>
  );
};
