import * as React from 'react';
import { useEffect, useState } from 'react';

import { BoxInfo, CardInfo, FullCardInfo } from '@lub0v/santa-utils';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import {
  CatSad,
  ColumnCenter,
  ColumnStart,
  DotJoined,
  ErrorBoundary,
  GridColumn,
  GridContainer,
  PictureNotice,
  SantaLink,
  TextSecondaryMedium,
  TextSecondaryRegular,
  Tip,
  UserCard,
} from '@src/components';
import { LoadingCardBig } from '@src/loading/LoadingComponents';
import { SantaDonatePanel } from '@src/components/SantaDonatePanel';
import { ParticipantsList } from '@src/pages/box/ParticipantsList';
import { sanofiMount, sanofiUnmount } from '@src/pages/sanofi';
import { t } from '@src/translations';
import { AdLookIBV } from '@src/components/AdLookIBV';

type BoxParticipantsProps = {
  translator: any;
  historyPush: (path: string) => void;
  boxInfo: BoxInfo;
  card: FullCardInfo;
  cards: CardInfo[];
  mobile: boolean;
};

const NoOneHereComponent = (props: BoxParticipantsProps) => (
  <ColumnCenter style={{ marginTop: '3rem' }}>
    <PictureNotice
      translator={props.translator}
      title="no_participants"
      hint="no_participants_hint"
      button="add_participants"
      onClick={() => {
        props.historyPush(getBoxPath('/invite'));
      }}
    >
      <CatSad />
    </PictureNotice>
  </ColumnCenter>
);

export const BoxParticipantsComponent = (props: BoxParticipantsProps) => {
  if (props.boxInfo.participantsNumber === 0) {
    return <NoOneHereComponent {...props} />;
  }

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
    if (props.boxInfo.box.key === 'eHhwog' || props.boxInfo.box.key === 'sanofi') {
      sanofiMount();
      return () => sanofiUnmount();
    }
    return () => {};
  }, []);

  const getUserCard = (card: CardInfo, fixed = false) => {
    const noWard = props.boxInfo.isAdmin && props.boxInfo.isActiveDraw && !card.wardId;
    const whenDrawActive = (
      <>
        {noWard ? (
          <TextSecondaryRegular grey translator={props.translator}>
            box_no_giftee
          </TextSecondaryRegular>
        ) : null}
        {card.isWardKnown ? (
          <TextSecondaryRegular grey translator={props.translator}>
            box_knows_giftee
          </TextSecondaryRegular>
        ) : noWard || !card.isVerified || !card.wardId ? null : (
          <TextSecondaryRegular grey translator={props.translator}>
            box_does_not_know_giftee
          </TextSecondaryRegular>
        )}
        {card.isYourWard ? (
          <TextSecondaryRegular grey translator={props.translator}>
            box_your_giftee
          </TextSecondaryRegular>
        ) : null}
        {card.isGiftReceived ? (
          <TextSecondaryRegular grey translator={props.translator}>
            box_gift_received
          </TextSecondaryRegular>
        ) : card.isGiftOnItsWay ? (
          <TextSecondaryRegular grey translator={props.translator}>
            box_gift_was_sent
          </TextSecondaryRegular>
        ) : null}
      </>
    );
    const UCard = (
      <UserCard
        picture={card.picture}
        avatar={card.avatar}
        name={card.username}
        verified={card.isVerified}
        giftInactive={card.isGiftOnItsWay}
        giftActive={card.isGiftReceived}
        fixed={fixed}
        style={{ margin: 'auto' }}
        noWard={noWard}
      >
        <DotJoined>
          {card.isYou ? (
            <TextSecondaryRegular grey translator={props.translator}>
              {t('box_it_is_you')}
            </TextSecondaryRegular>
          ) : null}
          {!card.isVerified ? (
            <TextSecondaryRegular grey translator={props.translator}>
              {t('box_wait_for_filling')}
            </TextSecondaryRegular>
          ) : null}
          {props.boxInfo.isActiveDraw ? whenDrawActive : null}
        </DotJoined>
      </UserCard>
    );
    if (props.boxInfo.isAdmin) {
      return (
        <SantaLink to={card.isYou ? getBoxPath('/card') : getBoxPath(`/card/${card.id}/edit`)}>
          {UCard}
        </SantaLink>
      );
    }
    if (card.isYou) {
      return <SantaLink to={getBoxPath('/card')}>{UCard}</SantaLink>;
    }
    if (card.isYourWard) {
      return <SantaLink to={getBoxPath('/ward')}>{UCard}</SantaLink>;
    }
    return UCard;
  };
  if (
    props.boxInfo.participantsNumber === 1 &&
    props.card &&
    props.cards.length &&
    props.boxInfo.isAdmin
  ) {
    return (
      <ColumnCenter style={{ marginTop: '3rem' }}>
        <PictureNotice
          translator={props.translator}
          title="box_only_you_here"
          hint="box_only_you_here_hint"
          button="add_participants"
          onClick={() => props.historyPush(getBoxPath('/invite'))}
        >
          {getUserCard(
            props.cards.find((c) => c.id === props.card.id),
            true,
          )}
        </PictureNotice>
      </ColumnCenter>
    );
  }

  if (!isMounted || !props.cards || !props.cards.length) {
    return (
      <ColumnStart>
        <GridContainer spacing={props.mobile ? 2 : 3}>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
          <GridColumn item xs={12} sm={6} md={4} lg={3}>
            <LoadingCardBig />
          </GridColumn>
        </GridContainer>
      </ColumnStart>
    );
  }

  const hasNoWards = props.boxInfo.isActiveDraw && !!props.cards.find((c) => !c.wardId);
  const noWardsLength = props.cards.filter((c) => !c.wardId).length;
  const showDrawInfo =
    props.boxInfo.isAdmin && !props.boxInfo.isActiveDraw && props.cards.length !== 1;
  return (
    <ColumnStart h100>
      {props.boxInfo.box.key !== 'sanofi' ? <SantaDonatePanel /> : null}
      {hasNoWards && props.boxInfo.isAdmin ? (
        <Tip yellow noIcon translator={props.translator}>
          {noWardsLength === 1 ? 'box_has_user_without_wards' : 'box_has_users_without_wards'}
        </Tip>
      ) : null}
      {showDrawInfo ? (
        <Tip noIcon translator={props.translator} green={props.cards.length > 2}>
          {props.cards.length > 2 ? 'box_is_ready_for_draw' : 'box_is_not_ready_for_draw'}
          {props.cards.length > 2 ? (
            <SantaLink to={getBoxPath('/draw')}>
              <TextSecondaryMedium orange underline>
                draw_not_active_button
              </TextSecondaryMedium>
            </SantaLink>
          ) : (
            <SantaLink to={getBoxPath('/invite')}>
              <TextSecondaryMedium orange underline>
                add_participants
              </TextSecondaryMedium>
            </SantaLink>
          )}
        </Tip>
      ) : null}
      {!props.boxInfo.isActiveDraw && props.boxInfo.box.isInviteAfterDraw ? (
        <Tip noIcon translator={props.translator} blueberry>
          box_is_invite_after_draw_hint
        </Tip>
      ) : null}
      <ErrorBoundary>
        <ColumnStart
          className={'participants-wrapper'}
          style={{ height: '100%', minHeight: '40rem' }}
        >
          <ParticipantsList {...props} />
        </ColumnStart>
      </ErrorBoundary>
    </ColumnStart>
  );
};
