import * as React from 'react';
import { useEffect } from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ANIMAL_TYPES,
  ButtonMain,
  CatDreaming,
  CatPlayful,
  ColumnCenter,
  ColumnStart,
  ErrorBoundary,
  GridColumn,
  GridContainer,
  Hint,
  PictureNotice,
  RowCenter,
  SimpleLink,
  SuperHero,
  TextButtons,
  TextH3Medium,
  TextMedium,
} from '@src/components';
import { CreateCardForm } from '@src/pages/box/card/CreateCardForm';
import { dispatchActionAppUpdateState } from '@src/state';
import { createCardInputFromCard } from '@src/pages/box/card/cardHelpers';
import { ChatWithSanta } from '@src/pages/box/card/ChatWithSanta';
import { shuffleArray } from '@lub0v/utils/dist/src/random';
import { getCookie, removeCookie } from '@src/utils/cookie';
import { MyCardComponent } from '@src/pages/box/card/MyCardComponent';
import { SantaDonatePanel } from '@src/components/SantaDonatePanel';
import { t, TRANSLATIONS } from '@src/translations';

const YouAreNotLoggedInProperlyComponent = (props: BoxPageProps) => (
  <ColumnCenter style={{ maxWidth: '40rem', marginLeft: 'auto', marginRight: 'auto' }}>
    <CatPlayful style={{ maxWidth: '15rem' }} />
    <TextMedium translator={props.translator} style={{ marginTop: '1rem' }}>
      {t(TRANSLATIONS.card_no_card_active_draw_hint_not_registered, {
        loginLink: `/login?redirect=/box/${props.box.info.box.key}`,
        isLoggedIn: !!props.session,
      })}
    </TextMedium>
    <ButtonMain
      translator={props.translator}
      onClick={() => {
        props.history.push(`/login?redirect=/box/${props.box.info.box.key}`);
      }}
    >
      card_login_to_site
    </ButtonMain>
  </ColumnCenter>
);

const CardCreatedNeedVerify = (props: BoxPageProps) => (
  <ColumnCenter style={{ marginTop: '3rem' }}>
    <PictureNotice
      translator={props.translator}
      title="card_created_need_verify_title"
      hint="card_created_need_verify_hint"
    >
      <SuperHero />
    </PictureNotice>
  </ColumnCenter>
);

export const BoxCardComponent = (props: BoxPageProps) => {
  const { box } = props.box.info;
  useEffect(() => {
    if (
      props.box.card &&
      !props.box.card.isVerified &&
      props.session &&
      props.box.createCard.step < 0
    ) {
      createCardInputFromCard();
      dispatchActionAppUpdateState({ box: { createCard: { showCreateCardNotice: true } } });
    }

    const cardStep = getCookie('cardStep');
    if (cardStep === '1') {
      removeCookie('cardStep');
      dispatchActionAppUpdateState({
        box: { createCard: { showCreateCardNotice: false, step: 1 } },
      });
    }
  }, []);

  const noCard = !props.box.card;

  if (props.box.info.isActiveDraw && noCard) {
    return <YouAreNotLoggedInProperlyComponent {...props} />;
  }

  if (noCard && box.isArchived) {
    return (
      <Hint center translator={props.translator}>
        card_no_card_title_archived_box
      </Hint>
    );
  }

  if (
    (props.box.createCard && props.box.createCard.showCreateCardNotice) ||
    (noCard && props.box.createCard.step < 0)
  ) {
    return (
      <ColumnCenter style={{ marginTop: '0rem' }}>
        <PictureNotice
          translator={props.translator}
          title="card_no_card_title"
          hint="card_no_card_hint"
          button={noCard ? 'card_add_card' : 'card_fill_card'}
          onClick={() => {
            if (noCard) {
              const picture = shuffleArray<string>(ANIMAL_TYPES)[0];
              dispatchActionAppUpdateState({
                box: {
                  createCard: {
                    input: {
                      join: props.box.join,
                      key: props.box.info.box.key,
                      picture,
                    },
                    showCreateCardNotice: false,
                    step: props.session ? 1 : 0,
                  },
                },
              });
            } else {
              createCardInputFromCard();
              dispatchActionAppUpdateState({
                box: { createCard: { showCreateCardNotice: false, step: props.session ? 1 : 0 } },
              });
            }
          }}
        >
          <RowCenter style={{ marginLeft: '2rem' }}>
            <CatDreaming style={{ width: '16rem' }} />
          </RowCenter>
        </PictureNotice>
      </ColumnCenter>
    );
  }

  if (props.box.createCard.step >= 0) {
    return <CreateCardForm {...props} />;
  }

  if (!props.box.card.isVerified) {
    return <CardCreatedNeedVerify {...props} />;
  }

  return (
    <ColumnStart>
      {props.box.info.box.key !== 'sanofi' ? <SantaDonatePanel /> : null}
      <GridContainer spacing={5}>
        <GridColumn
          xs={12}
          md={6}
          style={{
            height: '40rem',
          }}
        >
          <ErrorBoundary>
            <MyCardComponent {...props} />
          </ErrorBoundary>
        </GridColumn>
        {props.css.mobile ? null : (
          <GridColumn xs={12} md={6} style={{ height: '40rem' }}>
            <ChatWithSanta {...props} onClose={null} />
          </GridColumn>
        )}
      </GridContainer>
    </ColumnStart>
  );
};
