import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ButtonService, RowStart, SwitchPanel } from '@src/components';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState, loadBoxInfo } from '@src/state';
import * as React from 'react';
import { hasExtendedSettings } from '@src/pages/box/draw/drawHelpers';

export function DrawExtendedSettings(props: BoxPageProps & { onSetError: (err: string) => void }) {
  const hasExtSettings = hasExtendedSettings(props);

  const resetDraw = async () => {
    const res = await santaClient.resetDrawSettings({ key: props.box.info.box.key });
    if (res.ok) {
      props.onSetError(undefined);
      await loadBoxInfo(props.box.info.box.key);
    } else {
      props.onSetError(res.error.message);
    }
  };
  return (
    <>
      <SwitchPanel
        translator={props.translator}
        value={props.box.info.box.useCircleDraw}
        title="draw_circle_title"
        hint="draw_circle_hint"
        hintBig="draw_circle_hint_big"
        onChange={async (useCircleDraw) => {
          const res = await santaClient.updateBox({
            key: props.box.info.box.key,
            name: props.box.info.box.name,
            useCircleDraw,
          });
          if (res.ok) {
            dispatchActionAppUpdateState({
              box: { info: { box: { useCircleDraw } } },
            });
          }
        }}
      />
      {hasExtSettings ? (
        <RowStart translator={props.translator}>
          <ButtonService style={{ marginLeft: '0' }} onClick={resetDraw}>
            draw_reset_settings
          </ButtonService>
        </RowStart>
      ) : null}
    </>
  );
}
