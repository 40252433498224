import { SantaAppState } from '@src/state/santaAppState';
import { getCookie } from '@src/utils/cookie';
import { santaClient } from '@src/state/client';
import {
  BoxInfoOutput,
  BoxInviteInput,
  CardsInfoOutput,
  CreateMyCardInput,
  FullCardInfoOutput,
  SessionObject,
  SessionOutput,
} from '@lub0v/santa-utils';
import { store } from '@src/state/store';
import { PartialDeep } from 'type-fest';
import { getEditBoxData } from '@src/pages/box/boxHelpers';
import { shuffleArray } from '@lub0v/utils/dist/src/random';
import { ANIMAL_TYPES, ModalProps } from '@src/components';
import { Lang } from '@src/types/lang';
import { isDotCom } from '@src/host';

export function dispatchActionAppUpdateState(state: PartialDeep<SantaAppState>) {
  store.dispatch(actionAppUpdateState(state));
}

export function actionAppUpdateState(state: PartialDeep<SantaAppState>) {
  return { type: SantaAppState.APP_UPDATE_STATE, state };
}

export function actionAppSetUrlParams(params: any) {
  return { type: SantaAppState.APP_UPDATE_URL_PARAMS, params };
}

export function dispatchActionModal(params: ModalProps) {
  return store.dispatch({ type: SantaAppState.APP_MODAL, modal: params });
}

export function dispatchActionResetNewBox(callback: () => void = () => {}) {
  store.dispatch((dispatch) => {
    dispatch({ type: SantaAppState.APP_RESET_NEW_BOX });
    callback();
  });
}

async function loadSession(dispatch: any) {
  const res: SessionOutput = await santaClient.session();
  if (res.ok) {
    const sess: SessionObject = res.data;
    dispatch(
      actionAppUpdateState({
        session: sess,
        randomizer: { organizerUsername: sess.username, organizerEmail: sess.email },
        feedback: { email: sess.email },
        boxCreate: { step: 1 },
        box: {
          createCard: {
            input: {
              username: sess.username,
            },
          },
        },
      }),
    );
    loadNotifications();
  } else {
    dispatch(actionAppUpdateState({ session: null }));
  }
}

export async function loadNotificationsAuto() {
  const state = store.getState() as SantaAppState;
  const now = new Date().getTime();
  const lastLoaded = state.lastNotificationsLoaded;
  if (now - lastLoaded < 20000) {
    return;
  }
  dispatchActionAppUpdateState({ lastNotificationsLoaded: now });
  loadNotifications();
}

export async function loadNotifications() {
  if ((store.getState() as SantaAppState).session) {
    const res = await santaClient.getAccountNotifications();
    if (res.ok) {
      dispatchActionAppUpdateState({ notifications: { notifications: res.data } });
    }
  }
}

export async function loadBoxCards(key: string, join = '') {
  santaClient.getCards({ key, join }).then((res: CardsInfoOutput) => {
    dispatchActionAppUpdateState({ box: { cards: res.data } });
  });
}

export async function loadWard(key: string) {
  santaClient.getWard({ key }).then((res: FullCardInfoOutput) => {
    if (res.ok) {
      dispatchActionAppUpdateState({ box: { ward: res.data } });
    }
  });
}

export async function loadCard(key: string, join = '') {
  santaClient.getCard({ key, join }).then((cardRes: FullCardInfoOutput) => {
    if (cardRes.ok) {
      dispatchActionAppUpdateState({ box: { card: cardRes.data } });
    } else {
      const picture = shuffleArray<string>(ANIMAL_TYPES)[0];
      dispatchActionAppUpdateState({ box: { createCard: { input: { key, picture } } } });
    }
  });
}

export async function loadBoxInfo(key: string, join = '') {
  // @ts-ignore
  const res: BoxInfoOutput = await santaClient.getBoxInfo({ key, join });
  if (!res.ok) {
    if (res.error.message === 'UNAUTHORIZED_FOR_BOX') {
      dispatchActionAppUpdateState({ box: { isUnauthorized: true } });
    }
    if (res.error.message === 'BOX_NOT_FOUND') {
      dispatchActionAppUpdateState({ box: { isNotFound: true } });
    }
  } else {
    const invite = new BoxInviteInput();
    invite.isInviteAfterDraw = res.data.box.isInviteAfterDraw;
    invite.key = res.data.box.key;
    const editBox = getEditBoxData(res.data);
    dispatchActionAppUpdateState({
      box: { isUnauthorized: false, info: res.data, invite, editBox },
    });
  }
  loadBoxCards(key, join);
}

export function resetBox() {
  dispatchActionAppUpdateState({
    box: {
      isUnauthorized: false,
      isNotFound: false,
      join: '',
      info: null,
      invite: new BoxInviteInput(),
      cards: [],
      card: null,
      ward: null,
      editCard: null,
      otherCard: null,
      editOtherCard: null,
      createCard: {
        input: new CreateMyCardInput(),
        step: -1,
        showCreateCardNotice: false,
      },
      editBox: null,
      saved: '',
      wardMessages: null,
      santaMessages: null,
    },
  });
}

export function actionReloadSession() {
  return async (dispatch) => loadSession(dispatch);
}

async function loadDonateAds(dispatch: Function) {
  const [showDonatePanelRes, isDonatedRes] = await Promise.all([
    santaClient.getDonateAsk(),
    santaClient.getIsDonated(),
  ]);
  if (!isDonatedRes.data) {
    loadAds();
  }
  dispatch(
    actionAppUpdateState({
      showAds: !isDonatedRes.data,
      showDonatePanel: showDonatePanelRes.data,
    }),
  );
}

export function actionLoadAppState() {
  return async (dispatch: Function) => {
    dispatch(actionAppUpdateState({ loading: true }));
    if (isDotCom()) {
      dispatch(actionAppUpdateState({ language: Lang.EN }));
    } else {
      const language = getCookie('lang', true);
      if (language) {
        dispatch(actionAppUpdateState({ language }));
      }
    }

    await loadSession(dispatch);
    dispatch(actionAppUpdateState({ loading: false }));
    const counterRes = await santaClient.counter();
    dispatch(actionAppUpdateState({ counter: counterRes.data }));
    const { session } = store.getState() as SantaAppState;
    if (session) {
      await loadDonateAds(dispatch);
    } else {
      loadAds();
    }
  };
}

function hasAds() {
  const adScript1 = document.querySelector(
    'script[src="https://ru.viadata.store/tag/load.js?sid=105806"]',
  );
  const adScript2 = document.querySelector('script[src="https://cdn.adlook.me/js/rlf.js"]');
  const adScript3 = document.querySelector(
    'script[src="https://widget.adplay.ru/fn/launch2283359.js"]',
  );
  return adScript1 || adScript2 || adScript3;
}

function loadAds() {
  if (isDotCom()) return;
  if (hasAds()) {
    return;
  }

  function weightedRandomSelect(options) {
    let totalWeight = options.reduce((sum, { weight }) => sum + weight, 0);
    let random = Math.random() * totalWeight;
    for (const option of options) {
      if (random < option.weight) {
        return option.script;
      }
      random -= option.weight;
    }
  }

  let options = [
    { weight: 30, script: 'https://ru.viadata.store/tag/load.js?sid=105806' },
    { weight: 70, script: 'https://widget.adplay.ru/fn/launch2283359.js' },
  ];
  const s = weightedRandomSelect(options);
  const splitterScript = document.querySelector('script[src="https://cdn.adlook.me/js/split.js"]');
  const scriptElement = document.createElement('script');
  scriptElement.src = s;
  scriptElement.defer = true;
  scriptElement.async = true;
  scriptElement.charset = 'UTF-8';
  document.body.appendChild(scriptElement);
  splitterScript.insertAdjacentElement('afterend', scriptElement);
  let nextElement = scriptElement.nextElementSibling;
  while (nextElement) {
    nextElement.remove();
    nextElement = scriptElement.nextElementSibling;
  }
}
