import { createReducer } from '@reduxjs/toolkit';
import { SantaAppState } from '@src/state/santaAppState';
import { BoxCreateInput } from '@lub0v/santa-utils';
import { ModalProps } from '@lub0v/ui-components';
import { combineReducersWithRoot, updateState } from './util';

export const initialAppState: SantaAppState = new SantaAppState();

/* eslint no-param-reassign: 0 */
export const rootReducer = createReducer(initialAppState, {
  [SantaAppState.APP_UPDATE_STATE]: (state: SantaAppState, action) =>
    updateState(state, action.state),

  [SantaAppState.APP_UPDATE_URL_PARAMS]: (state: SantaAppState, action) => {
    const newState: SantaAppState = updateState(state, {});
    const params = action.params || {};
    newState.urlParams = params;
    newState.reset.a = params.a;
    return newState;
  },

  [SantaAppState.APP_RESET_STATE]: (state: SantaAppState, action) => {
    const newState: SantaAppState = updateState(state, {});
    const paths = action.path.split('.');
    let obj = newState;
    for (const p of paths) {
      obj = obj[p];
    }
    const keys = Object.keys(action.state);
    for (const k of keys) {
      obj[k] = action.state[k];
    }
    return newState;
  },

  [SantaAppState.APP_RESET_NEW_BOX]: (state: SantaAppState) => {
    const newState: SantaAppState = updateState(state, {});
    const input = new BoxCreateInput();
    input.useNames = true;
    input.isPhoneRequired = false;
    input.usePost = false;
    input.useWish = true;
    newState.boxCreate.input = input;
    newState.boxCreate.step = newState.session ? 1 : 0;
    return newState;
  },

  [SantaAppState.APP_MODAL]: (state: SantaAppState, action: { modal: ModalProps }) => {
    const newState: SantaAppState = updateState(state, {});
    newState.modal.opened = action.modal.opened;
    newState.modal.title = action.modal.title;
    newState.modal.message = action.modal.message;
    newState.modal.textarea = action.modal.textarea;
    newState.modal.buttonYes = action.modal.buttonYes;
    newState.modal.buttonNo = action.modal.buttonNo;
    newState.modal.loading = action.modal.loading;
    newState.modal.onCancel = action.modal.onCancel;
    newState.modal.onSubmit = action.modal.onSubmit;
    return newState;
  },
});

/* eslint no-param-reassign: 1 */
export const appReducer = combineReducersWithRoot(rootReducer, {});
