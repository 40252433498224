import * as React from 'react';
import { BasePageProps } from '@src/pages/withBasePage';
import { HeaderItem, RowStart, SantaLink, TextMedium } from '@src/components';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { Lang } from '@src/types/lang';
import { connect } from 'react-redux';
import { setCookie } from '@src/utils/cookie';
import { isDotCom } from '@src/host';

interface Props extends BasePageProps {
  language: string;
}

function BaseHeaderComponent(props: Props) {
  const unreadNotifications = props.notifications.notifications.filter((n) => !n.is_viewed);
  const allNotifications = props.notifications.notifications;

  const lang = isDotCom() ? null : (
    <>
      <TextMedium
        translator={props.translator}
        style={{ marginLeft: '1rem', marginRight: '1rem', color: '#ddd' }}
      >
        |
      </TextMedium>
      <TextMedium
        translator={props.translator}
        orange
        onClick={() => {
          const newLang = props.language === Lang.RU ? Lang.EN : Lang.RU;
          setCookie('lang', newLang, true);
          dispatchActionAppUpdateState({
            language: newLang,
          });
        }}
      >
        {props.language === Lang.RU ? 'RU' : 'EN'}
      </TextMedium>
    </>
  );
  return props.session ? (
    <RowStart translator={props.translator}>
      <SantaLink to="/account/boxes">
        <HeaderItem icon={'box'} onClick={() => {}}>
          boxes
        </HeaderItem>
      </SantaLink>
      <>
        <HeaderItem
          icon={unreadNotifications.length ? 'notifications-active' : 'notifications-inactive'}
          onClick={() => {
            dispatchActionAppUpdateState({
              notifications: { opened: !props.notifications.opened },
            });
            if (!allNotifications.length) {
              setTimeout(() => {
                dispatchActionAppUpdateState({ notifications: { opened: false } });
              }, 1000);
            }
          }}
        >
          notifications
          {unreadNotifications.length ? ` (${unreadNotifications.length})` : ''}
        </HeaderItem>
      </>
      <SantaLink to="/account">
        <HeaderItem icon={'user'} onClick={() => {}} iconRight>
          {props.session.username}
        </HeaderItem>
      </SantaLink>
      {lang}
    </RowStart>
  ) : (
    <>
      <SantaLink to="/login">
        <HeaderItem onClick={() => {}} orange translator={props.translator}>
          login_and_registration
        </HeaderItem>
      </SantaLink>
      {lang}
    </>
  );
}

function mapStateToProps(state: SantaAppState) {
  return {
    language: state.language,
  };
}

export const HeaderComponent = connect(mapStateToProps)(BaseHeaderComponent);
