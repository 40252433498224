import * as React from 'react';
import { ColumnStart, ErrorBoundary } from '@src/components';
import { SantaGoodsCarousel } from '@src/components/SantaGoodsCarousel';
import { SantaBannersCarousel } from '@src/components/SantaBannersCarousel';

function GiftsInternal() {
  return (
    <ColumnStart>
      <ErrorBoundary>
        <SantaGoodsCarousel />
      </ErrorBoundary>
      <ErrorBoundary>
        <SantaBannersCarousel />
      </ErrorBoundary>
    </ColumnStart>
  );
}

export const Gifts = GiftsInternal;
