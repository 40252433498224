import * as React from 'react';
import { _, linkBig } from '@src/translations/utils.translations';
import { getBoxPath } from '@src/pages/box/boxHelpers';

export const BOX_DRAW_TRANSLATIONS = {
  draw_not_enough_title: _(
    'В коробке недостаточно участников',
    'Not Enough Participants in the Box',
  ),
  draw_not_enough_hint: _(
    'Чтобы провести жеребьевку, необходимо по крайней мере три участника.',
    'To conduct the draw, there must be at least three participants.',
  ),
  draw_not_enough_button: _('Добавить участников', 'Add Participants'),
  draw_not_active_title: _('Жеребьевка еще не проведена', 'Draw Not Yet Conducted'),
  draw_not_active_hint: _(
    'Вы сможете посмотреть кто кому попался после жеребьевки',
    'You will be able to see who got whom after the draw.',
  ),
  draw_not_active_button: _('Перейти к жеребьевке', 'Go to the Draw'),
  draw_santas_title: _('Кто чей Санта?', 'Who Is Whose Santa?'),
  draw_santas_hint_reset: _('Чтобы сбросить жеребьевку, перейдите ', 'To reset the draw, go to '),
  draw_active_title: _('Жеребьевка проведена', 'Draw Conducted'),
  draw_active_hint: _(
    'Посмотрите кто чей Санта или сбросьте текущую жеребьевку для проведения новой.',
    'See who is whose Santa or reset the current draw to conduct a new one.',
  ),
  draw_active_button_reset: _('Сбросить жеребьевку', 'Reset Draw'),
  draw_active_button_reset_confirm: _('Подтвердить сброс жеребьевки', 'Confirm Draw Reset'),
  draw_done_title: _('Жеребьевка проведена', 'Draw Conducted'),
  draw_santas_hint: _(
    <>
      На этой странице показан актуальный список подопечных и их Тайных Сант. Чтобы узнать Тайного
      Санту, кликните на соответсвующую надпись напротив карточки участника.
    </>,
    <>
      This page displays the current list of recipients and their Secret Santas. To find out who the
      Secret Santa is, click on the corresponding label next to the participant's card.
    </>,
  ),
  draw_done_hint_archived: _(
    () => (
      <>Вы можете посмотреть, кто чей санта на {linkBig(getBoxPath('/santas'), 'этой')} странице.</>
    ),
    () => <>You can see who is whose Santa on {linkBig(getBoxPath('/santas'), 'this')} page.</>,
  ),
  draw_done_hint: _(
    () => (
      <>
        Вы можете посмотреть, кто чей санта на {linkBig(getBoxPath('/santas'), 'этой')} странице.
        Если вы хотите сбросить жеребьевку, нажмите
      </>
    ),
    () => (
      <>
        You can see who is whose Santa on {linkBig(getBoxPath('/santas'), 'this')} page. If you want
        to reset the draw, click
      </>
    ),
  ),
  draw_extended_settings_hint: _(
    <>
      Чтобы указать подопечного самостоятельно, нажмите на «Выбрать подопечного» напротив участника
      и выберите человека из списка.
      <br />
      <br />
      Чтобы участники не попались друг другу пометьте их карточки одинаковым цветом — нажмите на
      один из кружков и выберите цвет. Испольуйте прозрачный (последний) кружок чтобы снять пометку.
      <br />
      Например, чтобы настроить жеребьевку таким образом, чтобы супруги не дарили подарки друг
      другу, пометьте каждую пару одинаковым цветом. Карточки с одинаковыми цветами не попадутся
      друг другу при жеребьевке.
    </>,
    <>
      To manually assign a recipient, click on "Select giftee" next to a participant and choose a
      person from the list.
      <br />
      <br />
      To ensure participants don't get each other, mark their cards with the same color — click on
      one of the circles and select a color. Use the transparent (last) circle to remove the mark.
      <br />
      For example, to set up the draw so that spouses do not give gifts to each other, mark each
      pair with the same color. Cards with the same colors will not be matched with each other in
      the draw.
    </>,
  ),
  draw_done_reset: _('сюда', 'here'),
  draw_done_button: _('Перейти к участникам', 'Go to Participants'),
  draw_title: _('Жеребьевка', 'Draw'),
  draw_button: _('Провести жеребьевку', 'Conduct Draw'),
  draw_button_confirm: _('Подтвердить жеребьевку', 'Confirm Draw'),
  draw_extended_settings_title: _('Расширенные настройки', 'Advanced Settings'),
  draw_circle_title: _('Круговая жеребьевка', 'Circular Draw'),
  draw_circle_hint: _('Что это?', 'What is this?'),
  draw_circle_hint_big: _(
    'Исключает ситуации, когда два участника дарят подарки друг другу. Например, если есть участники А, Б, В и Г, и мы помечаем стрелочкой (->) кто кому дарит подарок, то при круговой жеребьевке будет например так А -> В -> Б -> Г -> A, а при не круговой, возможна такая ситуация: А -> Г -> A, Б -> В -> Б.',
    'Eliminates situations where two participants give gifts to each other. For example, if there are participants A, B, C, and D, and we mark with an arrow (->) who gives a gift to whom, then in a circular draw, it will look like this: A -> B -> C -> D -> A, while in a non-circular draw, a situation like this can occur: A -> D -> A, B -> C -> B.',
  ),
  draw_warning_unverified: _(
    <>
      <b>Внимание!</b> Не все участники подтвердили своё участие в жеребьёвке, но вы все равно
      можете её провести, если уверены в том, что они будут играть.
      <br />
      <br />
      Если вы хотите удалить участника, перейдите на страницу участников, нажмите на карточку
      участника и удалите её в настройках.
    </>,
    <>
      <b>Attention!</b> Not all participants have confirmed their participation in the draw, but you
      can still conduct it if you are sure that they will play.
      <br />
      <br />
      If you want to remove a participant, go to the participants page, click on the participant's
      card and delete it in the settings.
    </>,
  ),
  draw_select_ward: _('Выбрать подопечного', 'Select Giftee'),
  draw_reset_settings: _('Сбросить настройки', 'Reset Settings'),
  draw_problem_one_user: _(
    <>
      <b>Внимание! </b> <br />У одого из участников нет подопечного и Тайного Санты, так как он был
      добавлен после проведения жеребьевки.
    </>,
    <>
      <b>Attention! </b> <br />
      One of the participants does not have a recipient and a Secret Santa, as they were added after
      the draw was conducted.
    </>,
  ),
  draw_problem_several_users: _(
    <>
      <b>Внимание! </b>
      <br /> У нескольких участников нет подопечного и Тайного Санты, так как они были добавлены
      после проведения жеребьевки.
    </>,
    <>
      <b>Attention! </b>
      <br /> Several participants do not have a recipient and a Secret Santa, as they were added
      after the draw was conducted.
    </>,
  ),
  draw_problem_solve_title: _('Что можно сделать?', 'What can be done?'),
  draw_problem_reset_title: _('Сбросить жеребьевку', 'Reset the draw'),
  draw_problem_reset_hint: _(
    'Вы можете сбросить жеребьевку и провести её заново между всеми участниками. В этом случае участникам попадутся другие Тайные Санты и подопечные.',
    'You can reset the draw and conduct it again among all participants. In this case, participants will get different Secret Santas and recipients.',
  ),
  draw_problem_invite_title: _('Пригласить еще участников', 'Invite more participants'),
  draw_problem_invite_hint: _(
    'Вы можете пригласить еще несколько участников и провести дополнительную жеребьевку только между ними.' +
      ' После того, как вы пригласите участников, вернитесь на эту страницу и сможете провести дополнительную жеребьевку.',
    'You can invite several more participants and conduct an additional draw just among them. After inviting participants, return to this page to conduct the additional draw.',
  ),
  draw_problem_additional_title: _('Дополнительная жеребьевка', 'Additional draw'),
  draw_problem_additional_hint: _(
    'Проведите дополнительную жеребьевку только между добавленными участниками.',
    'Conduct an additional draw only among the added participants.',
  ),
  draw_problem_delete_title: _('Удалить карточку', 'Delete card'),
  draw_problem_delete_hint: _(
    'Вы можете удалить карточку участника, чтобы он не участвовал в жеребьевке. Нет карточки - нет проблем!',
    'You can delete a participant’s card so they do not participate in the draw. No card - no problem!',
  ),
  draw_problem_manual_title: _('Выбрать подопечного', 'Select a recipient'),
  draw_problem_manual_hint: _(
    <>
      Вы можете выбрать подопечного для участника вручную.
      <br />
      <br />
      Выбрав подопечного для участника, вы измените подопечного для его Тайного Cанты. Если вы сами
      принимаете участие в жеребьевке, <b>советуем выбрать своего подопечного</b>, тогда вы будете
      дарить подарок новому участнику, а у остальных ничего не изменится.
    </>,
    <>
      You can manually select a recipient for a participant.
      <br />
      <br />
      By choosing a recipient for a participant, you will change the recipient for their Secret
      Santa. If you are participating in the draw yourself,{' '}
      <b>it is advised to choose your own recipient</b>, then you will give a gift to the new
      participant, and nothing will change for the others.
    </>,
  ),
  draw_wait_for_filling: _(
    'Ожидается подтверждение участия',
    'Awaiting participation confirmation',
  ),
};
