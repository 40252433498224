import * as React from 'react';
import { useState } from 'react';
import { dispatchActionAppUpdateState, loadBoxInfo } from '@src/state';
import { CreateCardStepName } from '@src/pages/box/card/CreateCardStepName';
import { CreateCardStepPicture } from '@src/pages/box/card/CreateCardStepPicture';
import { CreateCardStepPostAddress } from '@src/pages/box/card/CreateCardStepPostAddress';
import { CreateCardStepWish } from '@src/pages/box/card/CreateCardStepWish';
import { FullCardInfoOutput } from '@lub0v/santa-utils';
import { santaClient } from '@src/state/client';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { calculateCreateCardTotalSteps } from '@src/pages/box/card/CreateCardTypes';
import { CreateCardStepLogin } from '@src/pages/box/card/CreateCardStepLogin';

export const CreateCardForm = (props: BoxPageProps) => {
  const [error, setError] = useState(undefined);

  const setStep = (step: number) => {
    dispatchActionAppUpdateState({
      box: { createCard: { step } },
    });
  };
  const totalSteps = calculateCreateCardTotalSteps(props.box.info);
  const goNext = async () => {
    if (props.box.createCard.step === totalSteps) {
      const input = { ...props.box.createCard.input };
      if (props.session) {
        input.isVerified = true;
        input.email = props.session.email;
      }
      if (!input.key) {
        input.key = props.box.info.box.key;
      }
      if (!input.join) {
        input.join = props.box.join;
      }
      const res: FullCardInfoOutput = await santaClient.saveCard(input);
      if (res.ok) {
        dispatchActionAppUpdateState({
          box: { card: res.data, createCard: { step: -1 } },
        });
        await loadBoxInfo(props.box.info.box.key, props.box.join);
      } else {
        setError(res.error.message);
      }
    } else {
      setStep(props.box.createCard.step + 1);
    }
  };
  let FormStep;
  switch (props.box.createCard.step) {
    case 0:
      FormStep = CreateCardStepLogin;
      break;
    case 1:
      FormStep = CreateCardStepName;
      break;
    case 2:
      FormStep = CreateCardStepPicture;
      break;
    case 3:
      FormStep = props.box.info.box.usePost ? CreateCardStepPostAddress : CreateCardStepWish;
      break;
    case 4:
      FormStep = CreateCardStepWish;
      break;
    default:
      break;
  }
  return (
    <FormStep
      {...props}
      error={error}
      goBack={() => {
        setError(undefined);
        if (props.box.createCard.step === 1 && props.session) {
          setStep(-1);
        } else {
          setStep(props.box.createCard.step - 1);
        }
      }}
      goNext={goNext}
    />
  );
};
