import { LOGIN_TRANSLATIONS } from '@src/translations/login.translations';
import { ERROR_TRANSLATIONS } from '@src/translations/error.translations';
import { VALIDATIONS_TRANSLATIONS } from '@src/translations/validations.translations';
import { COMMON_TRANSLATIONS } from '@src/translations/common.translations';
import { HOME_TRANSLATIONS } from '@src/translations/home.translations';
import { RANDOMIZER_TRANSLATIONS } from '@src/translations/randomizer.translations';
import { ACCOUNT_TRANSLATIONS } from '@src/translations/account.translations';
import { FEEDBACK_TRANSLATIONS } from '@src/translations/feedback.translations';
import { QUESTIONS_TRANSLATIONS } from '@src/translations/questions.translations';
import { NEW_BOX_TRANSLATIONS } from '@src/translations/new_box.translations';
import { ACCOUNT_BOXES_TRANSLATIONS } from '@src/translations/account_boxes.translations';
import { BOX_TRANSLATIONS } from '@src/translations/box.translations';
import { CARD_TRANSLATIONS } from '@src/translations/card.translations';
import { WARD_TRANSLATIONS } from '@src/translations/ward.translations';
import { BOX_DRAW_TRANSLATIONS } from '@src/translations/draw.translations';
import { NOTICES_TRANSLATIONS } from '@src/translations/notices.translations';
import { META_TRANSLATIONS } from '@src/translations/meta.translations';
import { MODALS_TRANSLATIONS } from '@src/translations/modals.translations';
import { GIFTS_TRANSLATIONS } from '@src/translations/gifts.translations';
import { RESEND_TRANSLATIONS } from '@src/translations/resend.translations';
import { FOOTER_TRANSLATIONS } from '@src/translations/footer.translations';
import { DONATE_TRANSLATIONS } from '@src/translations/donate.translations';

export const TRANSLATIONS = {
  ...DONATE_TRANSLATIONS,
  ...FOOTER_TRANSLATIONS,
  ...LOGIN_TRANSLATIONS,
  ...ERROR_TRANSLATIONS,
  ...VALIDATIONS_TRANSLATIONS,
  ...COMMON_TRANSLATIONS,
  ...HOME_TRANSLATIONS,
  ...RANDOMIZER_TRANSLATIONS,
  ...ACCOUNT_TRANSLATIONS,
  ...FEEDBACK_TRANSLATIONS,
  ...QUESTIONS_TRANSLATIONS,
  ...NEW_BOX_TRANSLATIONS,
  ...ACCOUNT_BOXES_TRANSLATIONS,
  ...BOX_TRANSLATIONS,
  ...BOX_DRAW_TRANSLATIONS,
  ...CARD_TRANSLATIONS,
  ...WARD_TRANSLATIONS,
  ...NOTICES_TRANSLATIONS,
  ...META_TRANSLATIONS,
  ...MODALS_TRANSLATIONS,
  ...GIFTS_TRANSLATIONS,
  ...RESEND_TRANSLATIONS,
};
