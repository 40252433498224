import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  HeaderSecondaryMenu,
  OrganizerMenu,
  OrganizerMenuItem,
  ToggleMenu,
  ToggleMenuItem,
} from '@lub0v/ui-components';

type Props = BoxPageProps & { visible?: boolean; onClose: () => void };

export const OrgMenu = (props: Props) => {
  const boxInfo = props.box.info;
  const { pathname } = props.history.location;
  const pushTo = (page: string) => () => props.history.push(`/box/${boxInfo.box.key}/${page}`);

  return (
    <OrganizerMenu
      visible={props.visible}
      onClose={props.onClose}
      bottomText="box_org_menu_hint"
      translator={props.translator}
    >
      {boxInfo.box.isArchived ? null : (
        <OrganizerMenuItem
          translator={props.translator}
          icon={'add-user'}
          active={pathname.endsWith('/invite')}
          onClick={pushTo('invite')}
        >
          box_org_menu_add_users
        </OrganizerMenuItem>
      )}

      {boxInfo.box.isArchived ? null : (
        <OrganizerMenuItem
          translator={props.translator}
          icon={'group-menu'}
          active={pathname.endsWith('/draw')}
          info={boxInfo.isActiveDraw ? 'box_org_menu_draw_active' : ''}
          onClick={pushTo('draw')}
        >
          box_org_menu_draw
        </OrganizerMenuItem>
      )}

      <OrganizerMenuItem
        translator={props.translator}
        icon={'question'}
        onClick={pushTo('santas')}
        active={pathname.endsWith('/santas')}
      >
        box_org_menu_table
      </OrganizerMenuItem>

      <OrganizerMenuItem
        translator={props.translator}
        icon={'settings'}
        active={pathname.endsWith('/settings')}
        onClick={pushTo('settings')}
      >
        box_org_menu_settings
      </OrganizerMenuItem>

      <OrganizerMenuItem
        translator={props.translator}
        icon={'trash'}
        active={pathname.endsWith('/delete')}
        onClick={pushTo('delete')}
      >
        box_org_menu_delete
      </OrganizerMenuItem>
    </OrganizerMenu>
  );
};
