import * as React from 'react';

import { _, linkSmall } from '@src/translations/utils.translations';
import { TextRegular } from '@src/components';

export const MODALS_TRANSLATIONS = {
  modal_reset_draw_title: _('Сброс жеребьевки', 'Reset the Draw'),
  modal_reset_draw_message: _(
    'Вы точно хотите сбросить текущую жеребьевку? У всех участников пропадут их подопечные.',
    'Are you sure you want to reset the current draw? All participants will lose their giftees.',
  ),
  modal_reset_draw_button_yes: _('Да, сбросить', 'Yes, reset'),
  modal_cancel: _('Отменить', 'Cancel'),
  modal_draw_title: _('Проведение жеребьевки', 'Conducting the Draw'),
  modal_draw_message: _(
    <>
      Вы точно готовы провести жеребьевку? Все ли участники на месте? После проведения жеребьевки
      всем игрокам на почту придет уведомление и они смогут узнать своих подопечных.
    </>,
    <>
      Are you sure you are ready to conduct the draw? Are all participants present? After the draw
      is conducted, all players will receive a notification via email and they will be able to find
      out their giftees.
    </>,
  ),
  modal_draw_message_slow: _(
    <>
      Вы точно готовы провести жеребьевку? Все ли участники на месте? После проведения жеребьевки
      всем игрокам на почту придет уведомление и они смогут узнать своих подопечных.
      <br />
      <br />
      <b>ВНИМАНИЕ:</b> при очень большом количестве участников, жеребьевка может занять до 30
      секунд, не закрывайте окно.
    </>,
    <>
      Are you sure you are ready to conduct the draw? Are all participants present? After the draw
      is conducted, all players will receive a notification via email and they will be able to find
      out their giftees.
      <br />
      <br />
      <b>ATTENTION:</b> with a very large number of participants, the draw may take up to 30
      seconds, do not close the window.
    </>,
  ),
  modal_draw_button_yes: _('Да, провести жеребьевку', 'Yes, conduct the draw'),

  modal_confirm_gift_received_title: _(
    'Подтвердите получение подарка',
    'Confirm receipt of the gift',
  ),
  modal_confirm_gift_received_message: _(
    <>
      Нажимая на кнопку, вы утверждаете, что получили подарок от Тайного Санты — это действие нельза
      отменить!
      <br />
      <br />
      Не забудьте отблагодарить вашего Санту за подарок — сделать это можно через сообщение в чате.
    </>,
    <>
      By clicking the button, you confirm that you have received the gift from your Secret Santa —
      this action cannot be undone!
      <br />
      <br />
      Don't forget to thank your Santa for the gift — you can do this through a message in the chat.
    </>,
  ),
  modal_confirm_gift_received_button_yes: _(
    'Да, я получил подарок',
    'Yes, I have received the gift',
  ),

  modal_confirm_gift_sent_title: _(
    'Подтвердите отправление подарка',
    'Confirm the dispatch of the gift',
  ),
  modal_confirm_gift_sent_message: _(
    <>
      Нажимая на кнопку отправки, вы подтверждаете, что отправили подарок своему подопечному — это
      действие нельзя отменить!
      <br />
      <br />
      Не забудьте сообщить подопечному, как получить подарок — сделать это можно через сообщение в
      чате.
    </>,
    <>
      By clicking the send button, you confirm that you have sent the gift to your giftee — this
      action cannot be undone!
      <br />
      <br />
      Don't forget to inform your giftee about how to receive the gift — you can do this through a
      message in the chat.
    </>,
  ),
  modal_confirm_gift_sent_button_yes: _('Да, я отправил подарок', 'Yes, I have sent the gift'),

  modal_confirm_gift_sent_input_track: _('Трек номер', 'Tracking number'),
  modal_confirm_gift_sent_input_message: _(
    'Ваше сообщение подопечному',
    'Your message to your giftee',
  ),

  modal_confirm_read_faq_title: _(
    'Вы прочитали "Частые Вопросы"?',
    "Have you read the 'Frequently Asked Questions'?",
  ),
  modal_confirm_read_faq_message: _(
    <>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      Нажимая на кнопку ниже, вы подтверждаете, что прочитали все "Частые вопросы" и не нашли ответ
      на свой вопрос.
      <br />
      <br />
      Если ваш вопрос связан с конкретной коробкой, то для начала обратитесь к организатору.
      Связаться с организатором можно из коробки, нажав на ссылку "связаться с организатором" под
      названием коробки.
    </>,
    <>
      By clicking the button below, you confirm that you have read all the "Frequently Asked
      Questions" and did not find an answer to your question.
      <br />
      <br />
      If your question is related to a specific box, you should first contact the organizer. You can
      contact the organizer from within the box by clicking on the 'contact the organizer' link
      below the name of the box.
    </>,
  ),

  modal_confirm_read_faq_button_send: _('Все равно написать', 'Write anyway'),
  modal_confirm_read_faq_button_no: _('Пойду почитаю', 'I will read now'),

  modal_large_file_title: _('Файл слишком большой', 'File too large'),
  modal_large_file_message: _('Файл не должен превышать 250KB.', 'File should not exceed 250KB.'),
  modal_large_file_message_250kb: _(
    'Файл не должен превышать 250KB.',
    'File should not exceed 250KB.',
  ),
  modal_large_file_button_submit: _('Понятно', 'Understood'),
  modal_large_file_button_cancel: _('Понятно', 'Understood'),

  modal_logged_out_title: _('Войдите на сайт', 'Log In to the Website'),
  modal_logged_out_message: _(
    'Вы долго не заходили на сайт и в целях вашей безопасности ваша сессия была завершена. Пожалуйста войдите на сайт.',
    'You have been inactive for a long time, and for your security, your session has ended. Please log in to the website.',
  ),
  modal_logged_out_button: _('Войти на сайт', 'Log In to the Website'),

  modal_server_busy_title: _('Сайт перегружен', 'Website Overloaded'),
  modal_server_busy_message: _(
    'Похоже, что очень много людей сейчас хотят играть в Тайного Санту и сайт не справляется с нагрузкой. Мы обязательно все исправим, пожалуйста, попробуйте обратиться позже.',
    'It seems that the website is currently unable to handle the load. Please try again later.',
  ),
  modal_server_busy_button: _('Хорошо', 'Okay'),

  modal_too_many_requests_title: _('Слишком много запросов', 'Too Many Requests'),

  modal_too_many_requests_message: _(
    <>
      Похоже, что вы делаете слишком много действий. Пожалуйста, попробуйте позже. Если ошибка
      повторяется снова и снова, напишите на <b>postman@santa-secret.ru</b> с описанием проблемы и
      мы постараемся разобраться как можно скорее.
    </>,
    <>
      It seems that you are making too many actions. Please try again later. If the error repeats
      over and over, write to <b>postman@santa-secret.ru</b> with a description of the problem, and
      we will try to resolve it as soon as possible.
    </>,
  ),
  modal_too_many_requests_button: _('Хорошо', 'Okay'),
  modal_ask_org_title: _('Связаться с организатором', 'Contact the Organizer'),
  modal_ask_org_textarea: _('Сообщение организатору', 'Message to the Organizer'),
  modal_ask_org_submit: _('Отправить сообщение', 'Send Message'),
  modal_ask_org_cancel: _('Спрошу потом', 'Ask Later'),
  modal_ask_org_hint: _(
    'Напишите свое сообщение и мы отправим его вашему организатору на email адрес',
    'Write your message and we will send it to your organizer to email',
  ),

  modal_wait_for_filling_title: _(
    'Ожидается подтверждение участия',
    'Awaiting participation confirmation',
  ),
  modal_wait_for_filling_message: _(
    <>
      <TextRegular style={{ textAlign: 'left' }}>
        <b>1. Если участник был приглашен по ссылке: </b>
        <br />
        Участник создал себе карточку по ссылке-приглашения, но еще не перешел по ссылке из письма
        регистрации, чтобы подтвердить свой email. <br />
        <b>Что нужно сделать:</b> участнику нужно перейти по ссылке из письма о регистрации и тем
        самым подтвердить свой email.
        <br />
        <br />
        <b>2. Если участник был приглашен по email:</b>
        <br />
        Участник еще не перешел по ссылке из письма и/или не заполнил карточку.
        <br />
        <b>Что нужно сделать:</b> участнику нужно перейти по ссылке из письма-приглашения и
        заполнить карточку <br />
      </TextRegular>
    </>,
    <TextRegular style={{ textAlign: 'left' }}>
      <b>1. If the participant was invited via the link: </b>
      <br />
      The participant created their card using the invitation link, but has not yet clicked on the
      link in the registration email to confirm their email. <br />
      <b>What needs to be done:</b> The participant needs to click on the link in the registration
      email and thereby confirm their email.
      <br />
      <br />
      <b>2. If the participant was invited via email:</b>
      <br />
      The participant has not yet clicked on the link in the email and/or has not filled out their
      card.
      <br />
      <b>What needs to be done:</b> The participant needs to click on the link in the invitation
      email and fill out the card <br />
    </TextRegular>,
  ),
  modal_wait_for_filling_button: _('Понятно', 'Understood'),
};
