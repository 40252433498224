import { _ } from '@src/translations/utils.translations';

export const GIFTS_TRANSLATIONS = {
  gift_ideas_title: _('Идеи подарков от наших друзей', 'Gift Ideas from Our Friends'),
  gift_our_ideas_title: _(
    'Идеи подарков от нас и наших друзей',
    'Gift Ideas from Us and Our Friends',
  ),
  gift_go_to_site: _('Перейти на сайт', 'Go to Website'),
  gift_more: _('Показать еще варианты', 'Show More Options'),
};
