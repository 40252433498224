import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import { CardContainer, ColumnStart, BoxPic, PictureNotice } from '@src/components';
import { dispatchActionAppUpdateState } from '@src/state';

export function NoticeChatWithSanta(props: BoxPageProps) {
  return (
    <ColumnStart style={{ height: '100%' }}>
      <CardContainer>
        <PictureNotice
          translator={props.translator}
          title="notice_its_chat_with_santa_title"
          hint={<>notice_its_chat_with_santa_hint</>}
          button={'notice_open_chat_button'}
          onClick={() => {
            dispatchActionAppUpdateState({ box: { cardChatOpened: true } });
          }}
        >
          <BoxPic type="santa" />
        </PictureNotice>
      </CardContainer>
    </ColumnStart>
  );
}
