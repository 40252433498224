function insertAfter(referenceNode, newNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export function sanofiMount() {
  const imageWidth = 2886;
  const imageHeight = 348;
  const ratio = imageHeight / imageWidth;
  const width = window.innerWidth > 2886 ? 2886 : window.innerWidth;
  const height = width * ratio;

  const layoutMain = document.getElementsByClassName('layout-1__main')[0];
  // @ts-ignore
  layoutMain.style.background = 'transparent';
  // @ts-ignore
  layoutMain.style.position = 'relative';

  const mainWrapper = document.getElementsByClassName('layout-1__main-wrapper')[0];
  // @ts-ignore
  mainWrapper.style.backgroundImage = '#F5F7FD'; // "url('https://i.ibb.co/Xs5y8yW/image.png')";
  // @ts-ignore
  mainWrapper.style.backgroundSize = 'cover';
  // @ts-ignore
  mainWrapper.style['grid-template-areas'] = `"b"\n"f"\n"p"`;
  // @ts-ignore
  mainWrapper.style['grid-template-rows'] = 'auto 1fr auto';

  const wrappers = document.getElementsByClassName('layout-1__fade')[0];
  const div = document.createElement('div');
  div.id = 'sanofi';
  div.style.height = `${height}px`;
  div.style.width = '100%';
  div.style.textAlign = 'center';
  insertAfter(wrappers, div);

  div.innerHTML = `<div style="width: 100%; max-width: 2886px; margin: auto"><img style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; margin: auto; width: 100%; max-width: 2886px" src="https://i.ibb.co/y6xKJyV/image.png"/></div>`;

  setTimeout(() => {
    const pWrapper = document.getElementsByClassName('participants-wrapper')[0];
    // @ts-ignore
    pWrapper.style.minHeight = '10rem';
  }, 50);

  setTimeout(() => {
    const pWrapper = document.getElementsByClassName('participants-wrapper')[0];
    // @ts-ignore
    pWrapper.style.minHeight = '10rem';
  }, 150);

  setTimeout(() => {
    const pWrapper = document.getElementsByClassName('participants-wrapper')[0];
    // @ts-ignore
    pWrapper.style.minHeight = '10rem';
  }, 350);

  setTimeout(() => {
    const pWrapper = document.getElementsByClassName('participants-wrapper')[0];
    // @ts-ignore
    pWrapper.style.minHeight = '10rem';
  }, 500);

  let leftWidth = 600;
  let rightWidth = 500;
  if (window.innerWidth >= 1100) {
    leftWidth = 600;
    rightWidth = 500;
  } else if (window.innerWidth >= 700) {
    leftWidth = 400;
    rightWidth = 300;
  } else if (window.innerWidth >= 500) {
    leftWidth = 300;
    rightWidth = 200;
  } else if (window.innerWidth >= 100) {
    leftWidth = 250;
    rightWidth = 180;
  }

  const left = document.createElement('div');
  const leftRatio = 239 / 837;
  left.id = 'sanofi-left';
  left.style.position = 'absolute';
  left.style.left = '0';
  left.style.bottom = '0';
  left.style.width = `${leftWidth}px`;
  left.style.height = `${leftWidth * leftRatio}px`;
  left.style.backgroundImage = "url('https://i.ibb.co/LNCSNDV/image.png')";
  left.style.backgroundSize = 'cover';
  left.style.zIndex = '4';
  left.style.pointerEvents = 'none';

  layoutMain.appendChild(left);

  const right = document.createElement('div');
  const rightRatio = 305 / 738;
  right.id = 'sanofi-right';
  right.style.position = 'absolute';
  right.style.right = '0';
  right.style.bottom = '0';
  right.style.width = `${rightWidth}px`;
  right.style.height = `${rightWidth * rightRatio}px`;
  right.style.backgroundImage = "url('https://i.ibb.co/K5BVdhm/image.png')";
  right.style.backgroundSize = 'cover';
  right.style.zIndex = '4';
  right.style.pointerEvents = 'none';

  layoutMain.appendChild(right);
}

export function sanofiUnmount() {
  const div = document.getElementById('sanofi');
  div.parentNode.removeChild(div);

  const left = document.getElementById('sanofi-left');
  left.parentNode.removeChild(left);
  const right = document.getElementById('sanofi-right');
  right.parentNode.removeChild(right);

  const element = document.getElementsByClassName('layout-1__main')[0];
  // @ts-ignore
  element.style.marginTop = '0';
  // @ts-ignore
  element.style.background = '#fdfdff';
  const mainWrapper = document.getElementsByClassName('layout-1__main-wrapper')[0];
  // @ts-ignore
  mainWrapper.style.backgroundImage = 'none';
  // @ts-ignore
  mainWrapper.style.backgroundSize = 'contain';
  // @ts-ignore
  mainWrapper.style['grid-template-areas'] = `"f"\n"p"`;
  // @ts-ignore
  mainWrapper.style['grid-template-rows'] = '1fr auto';
}
