import { BoxPageProps } from '@src/pages/box/BoxPage';
import { t, TRANSLATIONS } from '@src/translations';
import {
  colors,
  ColumnCenter,
  MyCard,
  MyCardAction,
  PictureNotice,
  RowCenter,
  TextWish,
} from '@src/components';
import * as React from 'react';
import { useState } from 'react';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState, dispatchActionModal, loadBoxCards } from '@src/state';
import { MessagesInfoOutput } from '@lub0v/santa-utils';
import { ChatWithSanta } from '@src/pages/box/card/ChatWithSanta';

export function MyCardComponent(props: BoxPageProps) {
  const [showAddress, setShowAddress] = useState(false);
  const [confirmGiftReceived, setConfirmGiftReceived] = useState(false);
  const [chatOpened, setChatOpened] = useState(false);

  const goToEdit = () => {
    props.history.push(`/box/${props.box.info.box.key}/card/edit`);
  };

  const { key } = props.box.info.box;

  const setMessages = (r: MessagesInfoOutput) => {
    if (r.ok) {
      dispatchActionAppUpdateState({ box: { santaMessages: r.data } });
    }
  };

  const setGiftReceived = async (confirm: boolean) => {
    setConfirmGiftReceived(confirm);

    dispatchActionModal({
      opened: confirm,
      title: 'modal_confirm_gift_received_title',
      message: 'modal_confirm_gift_received_message',
      buttonYes: 'modal_confirm_gift_received_button_yes',
      buttonNo: 'modal_cancel',
      loading: false,
      onCancel: () => {
        setGiftReceived(false);
      },
      onSubmit: async () => {
        const r = await santaClient.writeToSanta({
          key,
          message: t(TRANSLATIONS.card_chat_gift_received_message),
          isGiftReceived: true,
          isAutomatic: true,
        });
        if (r.ok) {
          setMessages(r);
          await loadBoxCards(props.box.info.box.key);
          setGiftReceived(false);
        }
      },
    });
  };

  const { isActiveDraw } = props.box.info;
  const isGiftReceived = props.box.santaMessages
    ? !!props.box.santaMessages.find((m) => m.active && m.isGiftReceived && m.isYou)
    : false;
  const { useWish, usePost } = props.box.info.box;
  const { card } = props.box;
  const hasAddress = card.postalAddress && card.postalCode && card.postalRecipient;
  const hasPhone = card.phone;

  const cardFilled = hasAddress || !usePost || hasPhone;

  const giftAction = isGiftReceived ? (
    <MyCardAction icon={'gift'} disabled translator={props.translator}>
      card_you_got_the_gift
    </MyCardAction>
  ) : (
    <MyCardAction icon={'gift'} onClick={() => setGiftReceived(true)} translator={props.translator}>
      card_i_got_the_gift
    </MyCardAction>
  );
  const openChatAction = (
    <MyCardAction
      icon={'message'}
      onClick={() => {
        setChatOpened(true);
      }}
      translator={props.translator}
    >
      card_open_chat_with_santa
    </MyCardAction>
  );

  if (chatOpened) {
    return (
      <ChatWithSanta
        {...props}
        onClose={() => {
          setChatOpened(false);
        }}
      />
    );
  }

  if (!cardFilled) {
    return (
      <MyCard
        style={{ margin: 'auto' }}
        translator={props.translator}
        title="card_my_card_title"
        picture={card.picture}
        avatar={card.avatar}
        name={card.username}
        onEdit={goToEdit}
      >
        <PictureNotice
          title="card_my_card_not_filled"
          hint=""
          button="card_my_card_fill"
          onClick={goToEdit}
        />
      </MyCard>
    );
  }

  if (showAddress) {
    const phoneNumber = hasPhone ? (
      <>
        <RowCenter style={{ marginBottom: '1rem' }}>
          <TextWish grey>Тел.:&nbsp;</TextWish>
          <TextWish>{card.phone}</TextWish>
        </RowCenter>
      </>
    ) : null;
    const postalAddress = usePost ? (
      hasAddress ? (
        <>
          <ColumnCenter style={{ marginBottom: '0.5rem' }}>
            <TextWish style={{ marginBottom: '0.5rem' }}>
              {card.postalAddress}, {card.postalCode}
            </TextWish>
            <TextWish>
              <span style={{ color: colors.text.secondary }}>Получатель:&nbsp;</span>
              {card.postalRecipient}
            </TextWish>
          </ColumnCenter>
        </>
      ) : (
        <>
          <ColumnCenter style={{ marginBottom: '0.5rem' }}>
            <TextWish>
              Вы ещё не добавили почтовый адрес{' '}
              <TextWish orange underline onClick={goToEdit}>
                добавить адрес
              </TextWish>
            </TextWish>
          </ColumnCenter>
        </>
      )
    ) : null;

    let wish;
    if (hasPhone && hasAddress) {
      wish = (
        <>
          {postalAddress}
          {phoneNumber}
        </>
      );
    } else if (hasPhone) {
      wish = phoneNumber;
    } else if (hasAddress) {
      wish = postalAddress;
    } else {
      wish = t(TRANSLATIONS.card_no_contact_yet);
    }
    return (
      <MyCard
        style={{ margin: 'auto' }}
        translator={props.translator}
        title="card_my_card_title"
        useBg={true}
        picture={card.picture}
        avatar={card.avatar}
        name={card.username}
        onEdit={goToEdit}
        wish={wish}
        buttons={
          <>
            <MyCardAction icon={'star'} onClick={() => setShowAddress(false)}>
              {t(TRANSLATIONS.card_back_to_wish)}
            </MyCardAction>
            {isActiveDraw ? giftAction : null}
            {props.css.mobile ? openChatAction : null}
          </>
        }
      />
    );
  }

  return (
    <MyCard
      style={{ margin: 'auto' }}
      translator={props.translator}
      title="card_my_card_title"
      useBg={true}
      picture={card.picture}
      avatar={card.avatar}
      name={card.username}
      onEdit={goToEdit}
      wishHint={card.wish && useWish ? t(TRANSLATIONS.card_my_card_wish_title) : null}
      wish={
        useWish
          ? card.wish || t(TRANSLATIONS.card_my_card_no_wish_yet)
          : t(TRANSLATIONS.ward_no_wish_allowed)
      }
      buttons={
        <>
          <MyCardAction icon={'envelope'} onClick={() => setShowAddress(true)}>
            {t(TRANSLATIONS.card_show_contacts)}
          </MyCardAction>
          {isActiveDraw ? giftAction : null}
          {props.css.mobile ? openChatAction : null}
        </>
      }
    />
  );
}
