import * as React from 'react';
import { dispatchActionAppUpdateState } from '@src/state';
import { FormCard, Hint, Input, Tip } from '@src/components';
import { validateField } from '@src/utils/validators';
import { CreateCardProps, createCardStepTitle } from '@src/pages/box/card/CreateCardTypes';
import { useEffect } from 'react';
import { validators } from '@lub0v/ui-components';

export const CreateCardStepName = (props: CreateCardProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const phoneValidation = props.box.info.box.isPhoneRequired ? [validators.required()] : [];
  return (
    <FormCard
      title="create_card_step_name_title"
      translator={props.translator}
      step={createCardStepTitle(props)}
      onSubmit={props.goNext}
      onBack={props.goBack}
      error={props.error}
    >
      {props.box.info.isAdmin ? null : (
        <Tip blueberry icon="info">
          create_card_agreement
        </Tip>
      )}
      <Input
        label="create_card_username"
        value={props.box.createCard.input.username}
        validators={[validateField(props.box.createCard.input, 'username')]}
        noBottomText
        onChange={(username) => {
          dispatchActionAppUpdateState({ box: { createCard: { input: { username } } } });
        }}
      />
      <Hint style={{ marginBottom: '2rem' }}>create_card_username_hint</Hint>
      <Input
        label="create_card_phone"
        value={props.box.createCard.input.phone}
        validators={[validateField(props.box.createCard.input, 'phone'), ...phoneValidation]}
        noBottomText
        onChange={(phone) => {
          dispatchActionAppUpdateState({ box: { createCard: { input: { phone } } } });
        }}
        pattern={new RegExp('^[\\- 0-9,+()]+$')}
        max={32}
      />
      <Hint style={{ marginBottom: '2rem' }}>
        {props.box.info.box.isPhoneRequired
          ? 'create_card_phone_required_hint'
          : 'create_card_phone_hint'}{' '}
      </Hint>
    </FormCard>
  );
};
