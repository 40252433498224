import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ToggleMenu, ToggleMenuItem } from '@lub0v/ui-components';
import { t } from '@src/translations';

type Props = BoxPageProps & { button?: any };

export const ToggleMenuItems = (props: Props) => {
  const { location } = props.history;
  const isCardActive =
    location.pathname.endsWith('/card') || location.pathname.endsWith('/card/edit');
  const isWardActive = location.pathname.endsWith('/ward');
  // eslint-disable-next-line no-nested-ternary
  const activeIndex = isCardActive ? 1 : isWardActive ? 2 : 0;
  return (
    <ToggleMenu
      button={props.button}
      active={activeIndex}
      disabled={!props.box.info}
      style={props.style}
    >
      <ToggleMenuItem
        onClick={() => {
          if (props.box.info) {
            props.history.push(`/box/${props.box.info.box.key}`);
          }
        }}
        translator={t}
      >
        box_toggle_menu_participants
      </ToggleMenuItem>
      <ToggleMenuItem
        onClick={() => {
          if (props.box.info) {
            props.history.push(`/box/${props.box.info.box.key}/card`);
          }
        }}
        translator={t}
      >
        box_toggle_menu_card
      </ToggleMenuItem>
      <ToggleMenuItem
        onClick={() => {
          if (props.box.info) {
            props.history.push(`/box/${props.box.info.box.key}/ward`);
          }
        }}
        translator={t}
      >
        box_toggle_menu_ward
      </ToggleMenuItem>
    </ToggleMenu>
  );
};
