import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { santaClient } from '@src/state/client';
import { RegisterInput, SantaApiResult } from '@lub0v/santa-utils';
import {
  ColumnCenter,
  FormAuth,
  PictureNotice,
  SantaLink,
  TextSecondaryMedium,
  SuperHero,
  TextSecondaryRegular,
} from '@src/components';
import { RegisterComponent } from '@src/pages/auth/RegisterComponent';
import { AdLook } from '@src/components/AdLook';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface RegisterPageProps extends BasePageProps {
  register: RegisterInput;
}

function RegisterPageComponent(props: RegisterPageProps) {
  const [registered, setRegistered] = useState(false);
  const [error, setError] = useState(undefined);
  /* ******* methods ******* */

  const doRegister = async () => {
    const res: SantaApiResult = await santaClient.register(props.register);
    if (res.ok) {
      setRegistered(true);
      dispatchActionAppUpdateState({
        register: { email: null, username: null, redirect: null },
      });
    } else {
      setError(res.error.message);
    }
  };

  /* *********************** */
  return registered ? (
    <ColumnCenter style={{ marginTop: '5rem' }}>
      <PictureNotice
        translator={props.translator}
        title="register_email_sent"
        hint="register_email_sent_hint"
        button="log_in_to_site"
        onClick={() => {
          props.history.push('/login');
        }}
      >
        <SuperHero />
      </PictureNotice>
    </ColumnCenter>
  ) : (
    <FormAuth
      style={{ marginBottom: '5rem' }}
      title="registration"
      button="register"
      translator={props.translator}
      subTitle={
        <>
          already_registered_q{' '}
          <SantaLink to="/login">
            <TextSecondaryMedium orange underline>
              log_in_to_site
            </TextSecondaryMedium>
          </SantaLink>
        </>
      }
      error={error}
      onSubmit={doRegister}
      footer={
        <ColumnCenter>
          <TextSecondaryRegular grey>register_policy_agreement_hint</TextSecondaryRegular>
          <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
        </ColumnCenter>
      }
    >
      <RegisterComponent {...props} />
    </FormAuth>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    register: state.register,
  };
}

export const RegisterPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(RegisterPageComponent),
);
