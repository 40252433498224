import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { SantaAppState } from '@src/state';
import { validateField } from '@src/utils/validators';
import { santaClient } from '@src/state/client';
import { RecoverInput, SantaApiResult } from '@lub0v/santa-utils';
import {
  ColumnCenter,
  FormAuth,
  Hint,
  Input,
  PictureNotice,
  SuperHero,
  TextSecondaryRegular,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { updateStoreEmail } from '@src/pages/auth/auth';
import { AdLook } from '@src/components/AdLook';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface RecoverPageProps extends BasePageProps {
  recover: RecoverInput;
}

function RecoverPageComponent(props: RecoverPageProps) {
  const [recovered, setRecovered] = useState(false);
  const [error, setError] = useState(undefined);
  /* ******* methods ******* */

  const doRecover = async () => {
    const res: SantaApiResult = await santaClient.recover(props.recover);
    if (res.ok) {
      setRecovered(true);
    } else {
      setError(res.error.message);
    }
  };

  /* *********************** */
  return recovered ? (
    <ColumnCenter style={{ marginTop: '5rem' }}>
      <PictureNotice
        translator={props.translator}
        title="recover_email_sent"
        hint={t(TRANSLATIONS.recover_email_sent_hint, { email: props.recover.email })}
      >
        <SuperHero />
      </PictureNotice>
    </ColumnCenter>
  ) : (
    <FormAuth
      title="recover_password"
      button="recover_password_button"
      translator={props.translator}
      error={error}
      onSubmit={doRecover}
      footer={
        <ColumnCenter>
          <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
        </ColumnCenter>
      }
    >
      <Input
        label="your_email"
        noBottomText
        value={props.recover.email}
        validators={[validateField(props.recover, 'email')]}
        onChange={(email) => {
          setError(undefined);
          updateStoreEmail(email);
        }}
      />
      <Hint center>we_will_send_recover_link</Hint>
    </FormAuth>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    recover: state.recover,
  };
}

export const RecoverPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(RecoverPageComponent),
);
