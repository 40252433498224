import * as React from 'react';
import { useEffect, useState } from 'react';
import { dispatchActionAppUpdateState, loadBoxInfo, loadWard } from '@src/state';
import { CreateOtherCardInput, FullCardInfoOutput } from '@lub0v/santa-utils';
import { santaClient } from '@src/state/client';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  Animal,
  ANIMAL_TYPES,
  FormPage,
  FormPageGroup,
  GridColumn,
  GridContainer,
  Hint,
  Input,
  PicturePicker,
  TextArea,
  TextSecondaryMedium,
} from '@src/components';
import { validateField } from '@src/utils/validators';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { matchPath } from 'react-router';
import { sendAdminCardUpdatesToSanta, setEditOtherCard } from '@src/pages/box/card/cardHelpers';
import { EditOtherCardLoading } from '@src/pages/box/loading/EditOtherCardLoading';
import { DeleteSection } from '@src/helpers/DeleteSection';
import { EditOtherCardWishHint } from '@src/pages/box/card/parts/EditOtherCardWishHint';
import { EditOtherCardAddressHint } from '@src/pages/box/card/parts/EditOtherCardAddressHint';
import { CardPicturePicker } from '@src/components/CardPicturePicker';

export const EditOtherCardForm = (props: BoxPageProps) => {
  const [error, setError] = useState(undefined);
  const [deleteError, setDeleteError] = useState(undefined);
  const [oldCard, setOldCard] = useState(undefined);
  const [showWish, setShowWish] = useState(false);

  useEffect(() => {
    const matchCardEditPath = matchPath(props.history.location.pathname, {
      path: `/box/:key([0-9a-zA-Z\\_\\-&]+)/card/:id/edit`,
    });
    const { params } = matchCardEditPath;
    const { id } = params as any;
    const { key } = params as any;
    santaClient.getOtherCard({ key, id }).then((cardRes: FullCardInfoOutput) => {
      setEditOtherCard(id, key, cardRes.data);
      setOldCard(cardRes.data);
    });
  }, []);

  const { box } = props.box.info;
  const input = props.box.editOtherCard;
  if (!input) {
    return <EditOtherCardLoading {...props} />;
  }
  const saveOtherCard = async () => {
    const edit = { ...props.box.editOtherCard };
    if (!edit.postalCode) {
      edit.postalCode = undefined;
    }
    if (!edit.postalRecipient) {
      edit.postalRecipient = undefined;
    }
    if (!edit.postalAddress) {
      edit.postalAddress = undefined;
    }
    if (!edit.phone) {
      edit.phone = undefined;
    }
    const res: FullCardInfoOutput = await santaClient.saveOtherCard(edit);
    if (res.ok) {
      await sendAdminCardUpdatesToSanta(oldCard, res.data, props.box.info.box.key);
      dispatchActionAppUpdateState({ box: { otherCard: res.data, saved: 'edit_card_saved' } });
      await loadBoxInfo(props.box.info.box.key, props.box.join);
      await loadWard(props.box.info.box.key);
    } else {
      setError(res.error.message);
    }
  };
  let pictures = [...ANIMAL_TYPES];
  const ind = pictures.indexOf(props.box.otherCard.picture);
  pictures.splice(ind, 1);
  pictures = [props.box.otherCard.picture, ...pictures];

  const updateState = (card: Partial<CreateOtherCardInput>) => {
    dispatchActionAppUpdateState({ box: { editOtherCard: card } });
  };

  const deleteCard = async () => {
    const res = await santaClient.deleteOtherCard({ key: props.box.info.box.key, id: input.id });
    if (res.ok) {
      dispatchActionAppUpdateState({
        box: { otherCard: null, editOtherCard: null },
      });
      props.history.push(`/box/${props.box.info.box.key}`);
      await loadBoxInfo(props.box.info.box.key, props.box.join);
    } else {
      setDeleteError(res.error.message);
    }
  };

  const deleteCardGroup = (
    <FormPageGroup title="edit_other_card_delete_title" translator={props.translator}>
      <DeleteSection
        translator={props.translator}
        button="edit_other_card_delete_button"
        confirmDeleteWord="edit_other_card_delete_confirm_word"
        onClick={deleteCard}
        error={deleteError}
      >
        <Hint>
          {props.box.info.isActiveDraw
            ? 'edit_other_card_delete_after_draw_hint'
            : 'edit_other_card_delete_hint'}
        </Hint>
      </DeleteSection>
    </FormPageGroup>
  );

  return (
    <FormPage
      title="edit_other_card_title"
      onSubmit={box.isArchived ? null : saveOtherCard}
      buttonSubmit={box.isArchived ? null : 'edit_other_card_save'}
      buttonBack="edit_other_card_back"
      translator={props.translator}
      onBack={() => props.history.push(getBoxPath(''))}
      error={error}
      secondary={box.isArchived ? null : deleteCardGroup}
    >
      <FormPageGroup title="edit_other_card_email">
        <Input
          disabled={box.isArchived}
          label="edit_other_card_email"
          value={input.email}
          validators={[validateField(input, 'email')]}
          noBottomText
          onChange={(email) => updateState({ email })}
        />
        {props.box.otherCard.isVerified && !box.isArchived ? (
          <Hint>edit_other_card_can_edit_email_if_verified</Hint>
        ) : (
          <Hint>edit_other_card_can_edit_email_if_not_verified</Hint>
        )}
      </FormPageGroup>
      <FormPageGroup title="edit_other_card_username_title">
        <Input
          disabled={box.isArchived}
          label="edit_other_card_username"
          value={input.username}
          validators={[validateField(input, 'username')]}
          onChange={(username) => updateState({ username })}
        />
        <Input
          label="create_card_phone"
          value={input.phone}
          validators={[validateField(input, 'phone')]}
          noBottomText
          onChange={(phone) => updateState({ phone })}
          pattern={new RegExp('^[\\- 0-9,+()]+$')}
          max={32}
        />
      </FormPageGroup>
      <FormPageGroup title="edit_other_card_picture" overflowScroll>
        <CardPicturePicker
          disabled={box.isArchived}
          selectedPicture={input.picture}
          image={input.avatar}
          onPictureChange={(picture) => updateState({ picture })}
          onCustomUploaded={(avatar) => {
            updateState({ avatar });
          }}
        />
      </FormPageGroup>
      {props.box.info.box.useWish ? (
        <FormPageGroup title="edit_other_card_wish">
          {showWish ? (
            <TextArea
              disabled={box.isArchived}
              label="create_card_step_wish"
              value={input.wish}
              onChange={(wish) => updateState({ wish })}
              validators={[validateField(input, 'wish')]}
            />
          ) : (
            <Hint>
              <TextSecondaryMedium orange underline onClick={() => setShowWish(true)}>
                edit_other_card_show_wish
              </TextSecondaryMedium>
            </Hint>
          )}
          <EditOtherCardWishHint {...props} />
        </FormPageGroup>
      ) : null}

      {props.box.info.box.usePost ? (
        <FormPageGroup title="edit_other_card_postal_address">
          <Input
            disabled={box.isArchived}
            label="create_card_post_address"
            value={input.postalAddress}
            validators={[validateField(input, 'postalAddress')]}
            onChange={(postalAddress) => updateState({ postalAddress })}
          />
          <GridContainer spacing={2}>
            <GridColumn xs={8}>
              <Input
                disabled={box.isArchived}
                label="create_card_post_recipient"
                value={input.postalRecipient}
                validators={[validateField(input, 'postalRecipient')]}
                onChange={(postalRecipient) => updateState({ postalRecipient })}
              />
            </GridColumn>
            <GridColumn xs={4}>
              <Input
                disabled={box.isArchived}
                label="create_card_post_code"
                value={input.postalCode}
                validators={[validateField(input, 'postalCode')]}
                onChange={(postalCode) => updateState({ postalCode })}
                max={10}
                number
              />
            </GridColumn>
          </GridContainer>
          <EditOtherCardAddressHint {...props} />
        </FormPageGroup>
      ) : null}
    </FormPage>
  );
};
