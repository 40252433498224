import * as React from 'react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { GridColumn, GridContainer, UserCardMini } from '@src/components';
import { CardInfo } from '@lub0v/santa-utils';
import { BoxPageProps } from '@src/pages/box/BoxPage';

export function DrawParticipantsListSimple(props: BoxPageProps) {
  const boxCards: CardInfo[] = props.box.cards;

  const getUserCard = (card: CardInfo) => (
    <React.Fragment key={card.id}>
      <GridColumn xs={12}>
        <UserCardMini
          style={{ maxWidth: props.css.mobile ? 'auto' : '28rem' }}
          picture={card.picture}
          avatar={card.avatar}
          name={card.username}
          verified={card.isVerified}
          colors={null}
        />
      </GridColumn>
    </React.Fragment>
  );

  const cardHeight = 105;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemCount={boxCards.length}
          itemSize={cardHeight}
          width={width}
        >
          {({ index, style }: { index: number; style: any }) => {
            if (boxCards.length > index) {
              const card = boxCards[index];
              return (
                <GridContainer spacing={2} style={style}>
                  <GridColumn key={`card-${card.id}`} item xs={12}>
                    {getUserCard(card)}
                  </GridColumn>
                </GridContainer>
              );
            }
            return null;
          }}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}
