import * as React from 'react';
import { useEffect, useState } from 'react';
import createActivityDetector from 'activity-detector';
import { loadNotificationsAuto } from '@src/state';

export function MoveDetector() {
  const [isActive, _setIsActive] = useState(true);
  const isActiveRef = React.useRef(isActive);

  const setIsActive = (val: boolean) => {
    isActiveRef.current = val;
    _setIsActive(val);
  };
  const FREQUENCY = 20;

  const tryLoadNotifications = async () => {
    if (isActiveRef.current) {
      await loadNotificationsAuto();
    }
    setTimeout(tryLoadNotifications, FREQUENCY * 1000);
  };

  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 30000,
      ignoredEventsWhenIdle: [],
    });
    activityDetector.on('idle', () => {
      setIsActive(false);
    });
    activityDetector.on('active', () => {
      setIsActive(true);
    });
    tryLoadNotifications();
    return () => activityDetector.stop();
  }, []);
  return <></>;
}
