import * as React from 'react';
import { dispatchActionAppUpdateState } from '@src/state';
import { Animal, ANIMAL_TYPES, FormCard, PicturePicker } from '@src/components';
import { CreateCardProps, createCardStepTitle } from '@src/pages/box/card/CreateCardTypes';
import { useEffect, useState } from 'react';
import { CardPicturePicker } from '@src/components/CardPicturePicker';

export const CreateCardStepPicture = (props: CreateCardProps) => {
  const [animals, setAnimalTypes] = useState(ANIMAL_TYPES);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.box.createCard.input.picture) {
      const { picture } = props.box.createCard.input;
      const anm = [...animals];
      anm.splice(anm.indexOf(picture), 1);
      setAnimalTypes([picture, ...anm]);
    } else {
      dispatchActionAppUpdateState({ box: { createCard: { input: { picture: animals[0] } } } });
    }
  }, []);
  return (
    <FormCard
      title="create_card_step_picture_title"
      translator={props.translator}
      step={createCardStepTitle(props)}
      onSubmit={props.goNext}
      onBack={props.goBack}
      error={props.error}
    >
      <CardPicturePicker
        disabled={false}
        selectedPicture={props.box.createCard.input.picture}
        image={props.box.createCard.input.avatar}
        onPictureChange={(picture) =>
          dispatchActionAppUpdateState({ box: { createCard: { input: { picture } } } })
        }
        onCustomUploaded={(avatar) => {
          dispatchActionAppUpdateState({ box: { createCard: { input: { avatar } } } });
        }}
      />
    </FormCard>
  );
};
