import { BoxPageProps } from '@src/pages/box/BoxPage';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Chat, ChatMessage, ColumnStart, Icon } from '@src/components';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState } from '@src/state';
import { MessageInfo, MessagesInfoOutput } from '@lub0v/santa-utils';
import * as moment from 'moment';
import { NoticeFutureChatWithSanta } from '@src/notices/NoticeFutureChatWithSanta';
import { NoticeChatWithSanta } from '@src/notices/NoticeChatWithSanta';
import { NoticeEmptyChat } from '@src/notices/NoticeEmptyChat';
import { FileUpload } from '@src/components/FileUpload';
import { isDotCom } from '@src/host';

export function ChatWithSanta(props: BoxPageProps & { onClose: () => void }) {
  const [message, setMessage] = useState('');
  const { key } = props.box.info.box;
  const fileUpload = useRef(null);

  const setMessages = (r: MessagesInfoOutput) => {
    if (r.ok) {
      dispatchActionAppUpdateState({ box: { santaMessages: r.data } });
    }
  };

  useEffect(() => {
    santaClient.getSantaMessages({ key }).then(setMessages);
  }, []);

  if (!props.box.ward) {
    return <NoticeFutureChatWithSanta {...props} />;
  }
  if (!props.box.cardChatOpened && !(props.box.santaMessages && props.box.santaMessages.length)) {
    return <NoticeChatWithSanta {...props} />;
  }

  const deleteMessage = async (m: MessageInfo) => {
    await santaClient.updateCardMessage({ id: m.id, message: '', key }).then(setMessages);
  };

  const sendMessage = async () => {
    const r = await santaClient.writeToSanta({ key, message, isGiftReceived: false });
    if (r.ok) {
      setMessages(r);
      setMessage('');
    }
  };

  const messages = props.box.santaMessages;

  const onFileStateChanged = async (state, file) => {
    if (state === 'uploaded') {
      const r = await santaClient.writeToSanta({
        key,
        message: file.filename,
        attachment: file,
        isGiftReceived: false,
      });
      if (r.ok) {
        setMessages(r);
        setMessage('');
      }
    }
  };

  return (
    <ColumnStart
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        height: '100%',
      }}
    >
      <Chat
        onClose={props.onClose}
        disabled={props.box.info.box.isArchived}
        translator={props.translator}
        title="card_chat_title"
        locale={isDotCom() ? 'en' : 'ru'}
        formatDate={(ts) => moment(ts).format('DD MMMM YYYY')}
        formatTime={(ts) => moment(ts).format('hh:mm:ss')}
        value={message}
        onChange={setMessage}
        onSend={sendMessage}
        placeholder={'card_chat_placeholder'}
        empty={<NoticeEmptyChat {...props} />}
        icon={
          <Icon
            type={'attach'}
            style={{ width: '2rem' }}
            onClick={() => {
              fileUpload.current.open();
            }}
          />
        }
      >
        {messages
          ? messages.map((m: MessageInfo) => {
              const name = m.isAutomatic ? 'Автоматическое сообщение' : m.isYou ? 'Вы' : 'Санта';
              return (
                <ChatMessage
                  key={m.timestamp}
                  time={m.timestamp}
                  isYou={m.isYou}
                  isAutomatic={m.isAutomatic}
                  attachment={m.attachment}
                  name={name}
                  deleted={m.active ? '' : 'Сообщение было удалено...'}
                  icon={
                    m.isGiftReceived ? (
                      <Icon type={'gift-received'} />
                    ) : m.isGiftSent ? (
                      <Icon type={'gift-sent'} />
                    ) : null
                  }
                  onDelete={() => deleteMessage(m)}
                >
                  {m.message}
                </ChatMessage>
              );
            })
          : null}
      </Chat>
      <FileUpload ref={fileUpload} onChange={onFileStateChanged} />
    </ColumnStart>
  );
}
