import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import { CreateOtherCardInput } from '@lub0v/santa-utils';
import {
  ButtonService,
  Hint,
  RowEnd,
  TextArea,
  TextSecondaryMedium,
  validators,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { useState } from 'react';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState } from '@src/state';

export function EditOtherCardAddressHint(props: BoxPageProps) {
  const [pokeMessage, setPokeMessage] = useState('');
  const input: CreateOtherCardInput = props.box.editOtherCard;
  const { box } = props.box.info;

  if (!input) {
    return null;
  }
  if (!input.isVerified) {
    return (
      <Hint translator={props.translator}>edit_other_card_address_not_exists_not_verified</Hint>
    );
  }
  if (box.isArchived) {
    return null;
  }
  const noPostAddress = !input.postalRecipient && !input.postalCode && !input.postalAddress;

  const sendPoke = async () => {
    const res = await santaClient.aminPoke({ key: box.key, id: input.id, message: pokeMessage });
    if (res.ok) {
      setPokeMessage('');
      dispatchActionAppUpdateState({ box: { saved: 'admin_poke_sent' } });
    }
  };

  const poke = pokeMessage ? (
    <>
      <TextArea
        translator={props.translator}
        label="edit_other_card_poke_title"
        value={pokeMessage}
        onChange={setPokeMessage}
        validators={[validators.required()]}
      />
      <RowEnd translator={props.translator}>
        <ButtonService style={{ marginRight: '0' }} onClick={sendPoke}>
          edit_other_card_poke_button
        </ButtonService>
      </RowEnd>
    </>
  ) : null;

  if (noPostAddress) {
    return (
      <>
        <Hint translator={props.translator}>
          edit_other_card_address_not_exists_hint
          <TextSecondaryMedium
            translator={props.translator}
            orange
            underline
            onClick={() => {
              setPokeMessage(pokeMessage ? '' : t(TRANSLATIONS.edit_other_card_ask_to_add_address));
            }}
          >
            edit_other_card_address_ask_to_add
          </TextSecondaryMedium>
        </Hint>
        {poke}
      </>
    );
  }

  return (
    <>
      <Hint translator={props.translator}>
        edit_other_card_address_exists_hint
        <TextSecondaryMedium
          translator={props.translator}
          orange
          underline
          onClick={() => {
            setPokeMessage(
              pokeMessage ? '' : t(TRANSLATIONS.edit_other_card_ask_to_update_address),
            );
          }}
        >
          edit_other_card_address_ask_to_update
        </TextSecondaryMedium>
      </Hint>
      {poke}
    </>
  );
}
