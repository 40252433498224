import * as React from 'react';
import { ChangeEvent, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { santaClient } from '@src/state/client';
import axios from 'axios';
import { dispatchActionModal } from '@src/state';
import { _ } from '@src/translations/utils.translations';

export interface FileUploadProps {
  onChange: (state, file) => void;
  maxSize?: number;
  fileLargeMsg?: string;
  accept?: string;
}

// eslint-disable-next-line react/display-name
export const FileUpload = forwardRef((props: FileUploadProps, ref) => {
  const inputFile = useRef(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [file, setFile] = useState(null);
  const send = useRef(null);

  const showTooLargeModal = async (opened: boolean) => {
    setModalOpened(opened);

    dispatchActionModal({
      opened,
      title: 'modal_large_file_title',
      message: props.fileLargeMsg || 'modal_large_file_message',
      buttonYes: 'modal_large_file_button_submit',
      loading: false,
      onSubmit: async () => {
        send.current = true;
        showTooLargeModal(false);
      },
    });
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const MAX_FILE_SIZE = props.maxSize || 250 * 1024;
      const loadedFile = e.target.files[0];

      if (loadedFile.size >= MAX_FILE_SIZE) {
        showTooLargeModal(true);
        return;
      }

      setFile(loadedFile);

      const url = (await santaClient.getUploadURL({})).data;

      await axios.put(url, loadedFile, {
        headers: {
          'content-type': loadedFile.type,
        },
      });

      const fileUrl = url.split('?')[0];
      const key = fileUrl.split('amazonaws.com/')[1];

      props.onChange('uploaded', {
        filename: loadedFile.name,
        url: fileUrl,
        key,
        size: loadedFile.size,
      });
    }
  };
  useImperativeHandle(ref, () => ({
    open: () => {
      inputFile.current.click();
    },
  }));

  return (
    <input
      type="file"
      id="upload-file"
      ref={inputFile}
      accept={props.accept}
      onChange={handleFileChange}
      style={{ display: 'none' }}
    />
  );
});
