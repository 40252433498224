import * as React from 'react';
import { _ } from '@src/translations/utils.translations';
import { TextMedium } from '@src/components';

export const WARD_TRANSLATIONS = {
  ward_not_a_participant_title: _('Вы не зашли на сайт', 'You have not logged in'),
  ward_not_a_participant_hint: _(
    'У вас нет подопечного. Жеребьевка прошла без вас так как вы не создали себе карточку участника либо она была удалена.',
    'You do not have a giftee. The draw occurred without you because you did not create a participant card or it was deleted.',
  ),
  ward_not_a_participant_hint_not_registered: _(
    'Вы не зашли на сайт под своим аккаунтом, поэтому вы не видите подопечного. Залогиньтесь и попробуйте снова.',
    'You are not logged in with your account, therefore you do not see a giftee. Log in and try again.',
  ),
  ward_no_ward_title: _('У вас пока нет подопечного', 'You do not have a giftee yet'),
  ward_no_ward_hint: _(
    'Он появится сразу после того, как организатор проведет жеребьевку.',
    'They will appear right after the organizer conducts the draw.',
  ),
  ward_no_ward_admin_title: _('У вас пока нет подопечного', 'You do not have a giftee yet'),
  ward_no_ward_admin_hint: _(
    'Он появится сразу после того, как вы проведете жеребьевку',
    'They will appear right after you conduct the draw',
  ),
  back_to_box: _('Вернуться в коробку', 'Return to the box'),
  ward_card_title: _('Мой подопечный', 'My Giftee'),
  ward_ask_to_add_contact: _(
    'Ваш подопечный пока что не оставил контактных данных.',
    'Your giftee has not yet left contact details.',
  ),
  ward_card_not_filled_title: _(
    'Ваш подопечный пока что не заполнил карточку',
    'Your giftee has not yet filled out the card',
  ),
  ward_card_not_filled_hint: _(
    'Вы можете подождать пока он заполнит её или поторопить его в чате',
    'You can wait until they fill it out or hurry them in the chat',
  ),
  ward_no_wish_yet_title: _(
    'Ваш подопечный пока что не оставил пожеланий. Вы можете подождать пока он напишет их или поторопить его в чате.',
    'Your giftee has not yet left any wishes. You can wait until they write them or hurry them in the chat.',
  ),
  ward_no_wish_yet_hint: _(
    'Вы можете подождать пока он напишет их или поторопить его в чате',
    'You can wait until they write them or hurry them in the chat',
  ),
  ward_no_wish_allowed: _(
    'Организатор запретил оставлять пожелания по подарку :(',
    'The organizer has forbidden leaving gift wishes :(',
  ),
  ward_wish_title: _('Пожелания', 'Wishes'),
  ward_ask_to_add_wish: _('Попросить добавить пожелания.', 'Ask to add wishes.'),
  ward_get_ward_title: _(
    'Жеребьевка проведена и у тебя появился подопечный',
    'The draw is conducted and you now have a giftee',
  ),
  ward_get_ward_hint: _(
    'Скорее нажимай на кнопку ниже, чтобы узнать кто это',
    'Quickly press the button below to find out who it is',
  ),
  ward_get_ward_button: _('Узнать подопечного', 'Find out the giftee'),
  ward_chat_title: _('Чат с Подопечным', 'Chat with the Giftee'),
  ward_chat_placeholder_gift: _('Сообщите об отправке подарка', 'Notify about sending the gift'),
  ward_chat_placeholder: _('Введите сообщение для подопечного', 'Enter a message for the giftee'),
  ward_chat_gift_sent_message: _('Подарок отправлен!', 'Gift sent!'),
  ward_ask_to_add_address: _(
    <>
      Ваш подопечный пока что не заполнил свой почтовый адрес.
      <br />
      Вы можете попросить его добавить адрес в чате.
    </>,
    <>
      Your giftee has not yet filled in their postal address.
      <br />
      You can ask them to add the address in the chat.
    </>,
  ),
  postal_address: _(
    (address, code, recipient) => (
      <>
        Почтовый адрес: {address}, {code} <br />
        Получатель: {recipient}
      </>
    ),
    (address, code, recipient) => (
      <>
        Postal Address: {address}, {code} <br />
        Recipient: {recipient}
      </>
    ),
  ),
  postal_address_no_break: _(
    (address, code, recipient) => (
      <>
        <TextMedium>
          {address}, {code}
        </TextMedium>
        <TextMedium> Получатель: {recipient}</TextMedium>
      </>
    ),
    (address, code, recipient) => (
      <>
        <TextMedium>
          {address}, {code}
        </TextMedium>
        <TextMedium> Recipient: {recipient}</TextMedium>
      </>
    ),
  ),
  phone_number: _(
    (number) => (
      <>
        Номер телефона: {number}
        <br />
      </>
    ),
    (number) => (
      <>
        Phone number: {number}
        <br />
      </>
    ),
  ),
  go_to_ward_card: _('Перейти к карточке', 'Go to the giftee card'),
};
