import { t, TRANSLATIONS } from '@src/translations';

const DEFAULT_TITLE = TRANSLATIONS.meta['/'];

function setDescriptionContent(content) {
  const el: any = document.head.querySelector('meta[name=description]');
  el.content = content;
}

function setTitle(title) {
  document.title = title;
}

function setCanonical(path) {
  const SITE_URL = window.location.host;
  console.log('SITE URL: ', SITE_URL);
  const el: any = document.head.querySelector('link[rel=canonical]');
  el.href = `${SITE_URL}${path}`;
}

export default function updateMetaTags(pathname) {
  const meta = TRANSLATIONS.meta[pathname];
  if (!meta) {
    console.warn(`NO TRANSLATION FOR meta[${pathname}]`);
  }
  const titleKey = meta ? meta.title : DEFAULT_TITLE.title;
  const descriptionKey = meta ? meta.description : '';
  const title = t(titleKey);
  const description = t(descriptionKey);

  setTitle(titleKey !== title ? title : t(DEFAULT_TITLE));
  const enabledForBots = descriptionKey !== description;
  setDescriptionContent(enabledForBots ? description : '');
  setCanonical(pathname);
  if (window) {
    // @ts-ignore
    window.prerenderReady = true;
  }
}
