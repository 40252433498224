import * as React from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { BOX_PATH } from '@src/pages/box/boxHelpers';
import { BoxParticipantsLoadingComponent } from '@src/pages/box/loading/BoxParticipantsLoading';
import { EditCardLoading } from '@src/pages/box/loading/EditCardLoading';
import { EditOtherCardLoading } from '@src/pages/box/loading/EditOtherCardLoading';
import { BoxCardLoadingComponent } from '@src/pages/box/loading/BoxCardLoading';
import { BoxSettingsLoading } from '@src/pages/box/loading/BoxSettingsLoading';
import { BoxInviteLoading } from '@src/pages/box/loading/BoxInviteLoading';
import { Switch } from 'react-router';
import { ColumnStart } from '@src/components';
import { Route } from 'react-router-dom';

export const BoxPageLoadingRoutes = (props: BoxPageProps) => (
  <ColumnStart style={{ marginTop: '2rem', marginBottom: '4rem' }}>
    <Switch key={props.history.location.pathname}>
      <Route exact path={BOX_PATH}>
        <BoxParticipantsLoadingComponent {...props} />
      </Route>
      <Route path={`${BOX_PATH}/participants`}>
        <BoxParticipantsLoadingComponent {...props} />
      </Route>
      <Route exact path={`${BOX_PATH}/card/:id/edit`}>
        <EditOtherCardLoading {...props} />
      </Route>
      <Route path={`${BOX_PATH}/settings`}>
        <BoxSettingsLoading {...props} />
      </Route>
      <Route path={`${BOX_PATH}/invite`}>
        <BoxInviteLoading {...props} />
      </Route>
      <Route exact path={`${BOX_PATH}/card/edit`}>
        <EditCardLoading {...props} />
      </Route>
      <Route exact path={`${BOX_PATH}/card`}>
        <BoxCardLoadingComponent {...props} />
      </Route>
      <Route path={`${BOX_PATH}/ward`}>
        <BoxCardLoadingComponent {...props} />
      </Route>
    </Switch>
  </ColumnStart>
);
