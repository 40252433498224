import * as React from 'react';
import { SantaAppState } from '@src/state';
import { connect } from 'react-redux';
import { Lang } from '@src/types/lang';

type Props = {
  mobile: boolean;
  showAd: boolean;
};

function AdLookComponent({ mobile, showAd }: Props) {
  if (!showAd) {
    return null;
  }
  const style: { left?: string; right?: string } = {};
  if (mobile) {
    style.left = '50%';
    // @ts-ignore
    style.maxWidth = 300;
    // @ts-ignore
    style.maxHeight = 225;
    // @ts-ignore
    style.marginTop = -122;
    // @ts-ignore
    style.marginLeft = -150;
  }

  return (
    <div
      className="adlook-container-rlf"
      style={{
        maxWidth: 400,
        maxHeight: 300,
        position: 'fixed',
        marginTop: -150,
        marginLeft: -200,
        bottom: 0,
        right: 0,
        ...style,
      }}
    >
      <div
        className="tgx-rlf"
        data-rlf-id="5986"
        data-rlf-auto="1"
        data-rlf-flt="1"
        data-rlf-dock="0"
        data-rlf-align="rb"
        data-rlf-min-time="30"
        data-rlf-fw="400"
      />
    </div>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    mobile: state.css.mobile,
  };
}

export const AdLookRlf = connect(mapStateToProps)(AdLookComponent);
