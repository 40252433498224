import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const FEEDBACK_TRANSLATIONS = {
  feedback_title: _('Обратная связь', 'Feedback and Questions'),
  feedback_tip: _(
    <>
      Если у вас есть отзыв, предложение или вопрос, ответ на который вы не смогли найти на сайте,
      отправьте свое сообщение, и мы постараемся связаться с вами в ближайшее время. <br /> <br />{' '}
      Время для оперативных ответов с <b>19:00 до 09:00 Мск</b>.
    </>,
    <>
      If you have feedback, suggestions, or a question answer for which you couldn't find on the
      website ― send your message, and we will try to get in touch with you as soon as possible.{' '}
      <br /> <br /> The time for prompt responses is from <b>7am to 10pm PST</b>.
    </>,
  ),
  feedback_message_title: _('Сообщение', 'Message'),
  feedback_send: _('Отправить сообщение', 'Send Message'),
  feedback_sent_title: _('Сообщение отправлено', 'Message Sent'),
  feedback_sent_hint: _(
    'Спасибо за ваше сообщение, мы постараемся ответить как можно скорее.',
    'Thank you for your message, we will try to respond as soon as possible.',
  ),
  feedback_please_read_faq_title: _(
    'Не торопитесь писать сообщение',
    "Don't Rush to Send a Message",
  ),
  feedback_please_read_faq_hint: _(
    'Пожалуйста, прежде чем отправлять сообщение с вопросом, поищите ответ на него на странице “Частые Вопросы”',
    'Please, before sending a message with a question, look for an answer on the “Frequently Asked Questions” page',
  ),
  feedback_go_to_faq: _('Перейти к частым вопросам', 'Go to Frequently Asked Questions'),
  feedback_still_send: _('Не нашел ответ на вопрос', "Didn't Find an Answer to the Question"),
};
