// eslint-disable-next-line @typescript-eslint/no-unused-vars,max-classes-per-file
import * as React from 'react';
import { Lang } from '@src/types/lang';
import { TextSecondaryMedium, SantaLink, Translator, TextMedium } from '@src/components';
import { TRANSLATIONS } from '@src/translations/translations';
import { SantaAppState } from '@src/state/santaAppState';

export class Translation {
  translations: any = {};

  constructor(ru: any, en: any) {
    this.translations = {
      [Lang.RU]: ru,
      [Lang.EN]: en,
    };
  }
}

export function _(ru: any, en?: any) {
  return new Translation(ru, en);
}

export function linkBig(path, text, onClick = () => {}) {
  return (
    <SantaLink to={{ pathname: path }}>
      <TextMedium orange underline onClick={onClick}>
        {text}
      </TextMedium>
    </SantaLink>
  );
}

export function link(path, text, onClick = () => {}) {
  return (
    <SantaLink to={{ pathname: path }}>
      <TextSecondaryMedium orange underline onClick={onClick}>
        {text}
      </TextSecondaryMedium>
    </SantaLink>
  );
}

export function linkSeparateTab(path, text, onClick = () => {}) {
  return (
    <SantaLink to={{ pathname: path }} separateTab>
      <TextSecondaryMedium orange underline onClick={onClick}>
        {text}
      </TextSecondaryMedium>
    </SantaLink>
  );
}

export function linkSmall(path, text, onClick = () => {}) {
  return (
    <SantaLink to={{ pathname: path }}>
      <TextSecondaryMedium orange underline onClick={onClick}>
        {text}
      </TextSecondaryMedium>
    </SantaLink>
  );
}

const DOES_NOT_HAVE_TRANSLATIONS = {};

function translate(v: any, params?: any) {
  if (typeof v === 'object' && Array.isArray(v)) {
    return v.map((val) => translate(val));
  }
  // eslint-disable-next-line global-require
  const lang = (require('../state/store').store.getState() as SantaAppState).language;
  if (v && v.translations) {
    return translateWithLang(lang, v, params);
  }
  if (TRANSLATIONS[v]) {
    return translateWithLang(lang, TRANSLATIONS[v], params);
  }
  if (TRANSLATIONS.errors[v]) {
    return translateWithLang(lang, TRANSLATIONS.errors[v], params);
  }
  if (TRANSLATIONS.validations[v]) {
    return translateWithLang(lang, TRANSLATIONS.validations[v], params);
  }
  DOES_NOT_HAVE_TRANSLATIONS[v] = true;
  if (typeof v === 'string') {
    // return `t_${v}`;
  }
  return v;
}

export const TRANSLATOR: Translator = translate;

function translateWithLang(lang: string, translation: Translation | any, params?: any): any {
  if (typeof translation === 'function') {
    return processFunction(translation, params);
  }
  if (!translation || !translation.translations) {
    // means that we passed already translated object
    return translation;
  }
  const obj = translation.translations[lang] ?? translation.translations.ru;
  if (typeof obj === 'function') {
    const val = processFunction(obj, params);
    if (params && typeof val === 'string') {
      return processParams(val, params, lang);
    }
    return val;
  }
  if (params && typeof obj === 'string') {
    return processParams(obj, params, lang);
  }
  return obj;
}

function processParams(obj: any, params: any, lang: string) {
  let result = obj;
  if (!obj) {
    return null;
  }
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line @typescript-eslint/no-loop-func
    Object.keys(params).forEach((key) => {
      const matchPlural = result.match(new RegExp(`{${key}([^}]+)}`));
      const matchPluralNo = result.match(new RegExp(`{x_${key}([^}]+)}`));
      if (matchPlural || matchPluralNo) {
        let res = '';
        const param = params[key];
        const arr = matchPlural ? matchPlural[1].split('|') : matchPluralNo[1].split('|');
        if (typeof param === 'number') {
          res = plural(lang, arr[0], param, arr.splice(1));
        }
        if (matchPlural) {
          result = result.replace(matchPlural[0], param + res);
        } else {
          result = result.replace(matchPluralNo[0], res);
        }
      } else {
        result = result.replace(`{${key}}`, params[key]);
      }
    });
  }
  return result;
}

function processFunction(obj: any, params: any) {
  if (typeof obj === 'function') {
    // todo sort by keys
    if (!params) {
      return obj();
    }
    return obj(...Object.values(params));
  }
  return undefined;
}

function ends(num: number, end: number) {
  return `${num}`.endsWith(`${end}`); // num - (end % 10) === 0;
}

/*
0 - ends in 1, excluding 11:
1 - ends in 2-4, excluding 12-14:
2 - everything else:
*/
function getRuEndingIndex(num: number) {
  if (num >= 11 && num <= 14) {
    return 2;
  }
  if (ends(num, 1)) {
    return 0;
  }
  if (ends(num, 2) || ends(num, 3) || ends(num, 4)) {
    return 1;
  }
  return 2;
}

function getEnEndingIndex(num: number) {
  return num === 1 ? 0 : 1;
}

function plural(lang: string, word: string, num: number, endings: string[]) {
  const ending = lang === 'ru' ? endings[getRuEndingIndex(num)] : endings[getEnEndingIndex(num)];
  return word + ending;
}
