import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DotJoined, TextSecondaryRegular, UserCard } from '@src/components';

function ExampleComponent(props: BasePageProps) {
  const getUserCard = () => (
    <UserCard
      picture={'lion'}
      name={'Randmo'}
      verified
      giftActive
      fixed
      style={{ margin: 'auto', zIndex: 2 }}
    >
      <DotJoined>
        <TextSecondaryRegular grey>Это вы</TextSecondaryRegular>
      </DotJoined>
    </UserCard>
  );

  const Row = ({ index, style }: { index: number; style: any }) => {
    console.log(style);
    return (
      <UserCard picture={'lion'} name={'Randmo'} verified giftActive fixed style={style}>
        <DotJoined>
          <TextSecondaryRegular grey>Это вы</TextSecondaryRegular>
        </DotJoined>
      </UserCard>
    );
    // return <div style={style}>{getUserCard()}</div>;
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          className="List"
          height={height}
          itemCount={1000}
          itemSize={200}
          width={width}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}

export const ExamplePage = connect(mapStateToPropsBasePage)(
  withBasePage<BasePageProps>(ExampleComponent),
);
