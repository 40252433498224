import * as React from 'react';
import { _ } from '@src/translations/utils.translations';

export const ACCOUNT_BOXES_TRANSLATIONS = {
  you_admin_and_participant: _(
    'Вы организатор и участник',
    'You are the organizer and a participant',
  ),
  you_admin: _('Вы организатор', 'You are the organizer'),
  you_participant: _('Вы участник', 'You are a participant'),
  draw_completed: _('Жеребьевка проведена', 'Draw conducted'),
  participants_number: _('{count участник||а|ов}', '{count participant||s}'),
  no_boxes: _('Пока что коробок нет', 'No boxes yet'),
  no_boxes_hint: _('Создайте свою первую коробку', 'Create your first box'),
  no_archived_boxes: _('Ни одной коробки в архиве', 'No boxes in the archive'),
  no_archived_boxes_hint: _(
    'Если вы организатор коробки, то можете заархивировать коробку через настройки. Коробки из архива можно просматривать, но не менять.',
    'If you are the organizer of a box, you can archive it through the settings. Archived boxes can be viewed but not modified.',
  ),
  account_my_boxes: _('Мои Коробки', 'My Boxes'),
  account_archive: _('Архив', 'Archive'),
};
