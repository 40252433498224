import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { FormCard, BoxPic, PicturePicker, BOX_PICTURES } from '@src/components';
import { NewBoxProps } from '@src/pages/new_box/NewBoxProps';
import { t, TRANSLATIONS } from '@src/translations';
import { santaClient } from '@src/state/client';
import { FileUpload } from '@src/components/FileUpload';
import { AdLookIBV } from '@src/components/AdLookIBV';

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    boxCreate: state.boxCreate,
  };
}

export const NewBoxStepPicture = connect(mapStateToProps)((props: NewBoxProps) => {
  const [error, setError] = useState(undefined);
  const [loaderId, setLoaderId] = useState(undefined);
  const fileUpload = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = async () => {
    const res = await santaClient.createBox(props.boxCreate.input);
    if (res.ok) {
      props.goNext();
    } else {
      setError(res.error.message);
    }
  };

  const onFileStateChanged = async (state, file) => {
    if (state === 'uploaded') {
      dispatchActionAppUpdateState({
        boxCreate: { input: { logo: file.url, picture: 'custom_image' } },
      });
      setTimeout(() => {
        setLoaderId(null);
      }, 500);
    }
  };

  return (
    <FormCard
      title="new_box_step_picture_title"
      translator={props.translator}
      step={t(TRANSLATIONS.new_box_step_title, { step: props.boxCreate.step })}
      onSubmit={onSubmit}
      onBack={props.goBack}
      error={error}
    >
      <PicturePicker
        selected={props.boxCreate.input.picture}
        onChange={async (picture) => {
          const pic = typeof picture !== 'string' ? 'custom_image' : picture;
          dispatchActionAppUpdateState({ boxCreate: { input: { picture: pic } } });
          if (pic === 'custom_image') {
            setTimeout(() => {
              setLoaderId('custom_image');
            }, 500);
            fileUpload.current.open();
          }
        }}
        loader={loaderId}
      >
        {BOX_PICTURES.map((type) => {
          const logo = props.boxCreate.input.logo;
          if (type == 'custom_image' && logo) {
            return (
              <img key={type} alt="custom_image" style={{ borderRadius: '100%' }} src={logo} />
            );
          }
          return <BoxPic key={type} type={type} />;
        })}
      </PicturePicker>
      <FileUpload
        ref={fileUpload}
        onChange={onFileStateChanged}
        maxSize={1024 * 250}
        fileLargeMsg="modal_large_file_message_250kb"
        accept="image/*"
      />
      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </FormCard>
  );
});
