import * as React from 'react';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, dispatchActionResetNewBox, SantaAppState } from '@src/state';
import { NewBoxProps } from '@src/pages/new_box/NewBoxProps';
import { t, TRANSLATIONS } from '@src/translations';
import { santaClient } from '@src/state/client';
import { PandaWithGift, PictureNotice, ColumnCenter, FormCard, SwitchPanel } from '@src/components';
import { AdLookIBV } from '@src/components/AdLookIBV';

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    boxCreate: state.boxCreate,
  };
}

export const NewBoxStepInfo = connect(mapStateToProps)((props: NewBoxProps) => {
  const [error, setError] = useState(undefined);
  const [created, setCreated] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = async () => {
    const res = await santaClient.createBox({ ...props.boxCreate.input, isCreated: true });
    if (res.ok) {
      if (props.session) {
        dispatchActionResetNewBox(() => {
          props.history.push(`/box/${props.boxCreate.input.key}`);
        });
      } else {
        setCreated(true);
      }
    } else {
      setError(res.error.message);
    }
  };

  if (created) {
    return (
      <FormCard
        title="new_box_register_email_sent"
        translator={props.translator}
        step=""
        btnLeft={<div />}
        btnRightTitle="back_to_home"
        onSubmit={() => {
          dispatchActionResetNewBox();
          props.history.push('/');
        }}
        error={error}
      >
        <ColumnCenter style={{ marginTop: '2rem' }}>
          <PictureNotice
            translator={props.translator}
            title=""
            hint="new_box_register_email_sent_hint"
          >
            <PandaWithGift />
          </PictureNotice>
        </ColumnCenter>
      </FormCard>
    );
  }

  return (
    <FormCard
      title="new_box_step_info_title"
      translator={props.translator}
      step={t(TRANSLATIONS.new_box_step_title, { step: props.boxCreate.step })}
      onSubmit={onSubmit}
      onBack={props.goBack}
      error={error}
    >
      <SwitchPanel
        title="new_box_step_info_use_wish"
        hint="new_box_step_info_use_wish_hint"
        value={props.boxCreate.input.useWish}
        onChange={(useWish) => {
          dispatchActionAppUpdateState({ boxCreate: { input: { useWish } } });
        }}
      />
      <SwitchPanel
        title="new_box_step_info_use_post"
        hint="new_box_step_info_use_post_hint"
        value={props.boxCreate.input.usePost}
        onChange={(usePost) => {
          dispatchActionAppUpdateState({ boxCreate: { input: { usePost } } });
        }}
      />

      <SwitchPanel
        title="new_box_step_info_phone_required"
        hint="new_box_step_info_phone_required_hint"
        value={props.boxCreate.input.isPhoneRequired}
        onChange={(isPhoneRequired) => {
          dispatchActionAppUpdateState({ boxCreate: { input: { isPhoneRequired } } });
        }}
      />
      <SwitchPanel
        title="new_box_step_info_use_names"
        hint="new_box_step_info_use_names_hint"
        value={props.boxCreate.input.useNames}
        onChange={(useNames) => {
          dispatchActionAppUpdateState({ boxCreate: { input: { useNames } } });
        }}
      />
      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </FormCard>
  );
});
