import * as React from 'react';
import { ButtonMain, DonatePanel, SantaLink, SimpleLink, TextMedium } from '@src/components';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { Box, dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { connect } from 'react-redux';
import { santaClient } from '@src/state/client';
import { useEffect } from 'react';
import { isDotCom } from '@src/host';

export function mapStateToPropsBoxBasePage(state: SantaAppState) {
  return {
    box: state.box,
    showDonatePanel: state.showDonatePanel,
    sentMetrikaShowDonatePanel: state.sentMetrikaShowDonatePanel,
  };
}

export const SantaDonatePanel = connect(mapStateToPropsBoxBasePage)(
  ({
    box,
    showDonatePanel,
    sentMetrikaShowDonatePanel,
  }: {
    box: Box;
    showDonatePanel: boolean;
    sentMetrikaShowDonatePanel: boolean;
  }) => {
    if (isDotCom()) {
      return null;
    }
    if (!showDonatePanel || !box || !box.cards || box.cards.length < 6 || !box.info.isActiveDraw) {
      return null;
    }
    if (box.info.box.key === 'tortomaster2023') {
      return null;
    }
    useEffect(() => {
      if (!sentMetrikaShowDonatePanel) {
        reachGoalYaMetrika('donate_panel_showed');
        dispatchActionAppUpdateState({
          sentMetrikaShowDonatePanel: true,
        });
      }
    }, []);
    const closePanel = async () => {
      dispatchActionAppUpdateState({
        showDonatePanel: false,
      });
      await santaClient.setDonateAsked();
    };
    return (
      <DonatePanel
        style={{ marginBottom: '2rem' }}
        title={'Привет, поможешь?'}
        onClose={async () => {
          reachGoalYaMetrika('donate_panel_closed');
          await closePanel();
        }}
        message={
          <>
            Меня зовут{' '}
            <TextMedium orange>
              <SimpleLink
                href="https://vk.com/lubka"
                target="_blank"
                onClick={() => reachGoalYaMetrika('vk_profile_click')}
              >
                Люба
              </SimpleLink>
            </TextMedium>
            , и я очень рада, что мой сайт помогает вам организовывать новогоднее развлечение!{' '}
            <br />
            Этот сервис абсолютно бесплатный для всех и держится лишь на моем энтузиазме и любви к
            людям. Чтобы он исправно работал, я плачу за сервера, отправку email сообщений, CDN,
            работу дизайнера, a также, трачу много свободного времени на разработку и поддержку
            пользователей. С каждым годом популярность сайта растет, как и растут расходы. Здесь
            есть назойливая реклама, но пока что она не приносит достаточного дохода для полноценной
            поддержки сайта. Мне было бы очень приятно, если бы вы помогли мне развивать этот проект
            дальше, и, возможно после этого, вы больше не увидите назойливую рекламу ;) Спасибо! ❤️
          </>
        }
      >
        <SantaLink
          to={'/donate'}
          onClick={async () => {
            reachGoalYaMetrika('donate_panel_clicked');
            await closePanel();
          }}
        >
          <ButtonMain>Помочь проекту</ButtonMain>
        </SantaLink>
      </DonatePanel>
    );
  },
);
