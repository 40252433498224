import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

const DESCRIPTION_DEFAULT = _(
  'Бесплатный сайт для организации онлайн жеребьевки на Тайного Санту между друзьями. Три шага: 1 - создай коробку, 2 - пригласи друзей, 3 - проведи жеребьевку',
  'Best Free Secret Santa Generator Online: Organize your Secret Santa event with friends in just three easy steps. Step 1: Create your Secret Santa box. Step 2: Invite your friends via a user-friendly platform. Step 3: Experience the thrill of the Secret Santa draw. Perfect for holiday gift exchanges and making festive memories!',
);
const BOX_PATH = '/box/:key([0-9a-zA-Z\\_\\-&]+)';

export const META_TRANSLATIONS = {
  meta: {
    ['/']: {
      title: _(
        'Тайный Санта | бесплатный онлайн организатор жеребьевки',
        'Secret Santa | Free Online Draw Organizer',
      ),
      description: DESCRIPTION_DEFAULT,
    },
    ['/policy']: {
      title: _('Тайный Санта | политика обработки данных', 'Secret Santa | Data Processing Policy'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/login']: {
      title: _('Тайный Санта | вход', 'Secret Santa | Login'),
      description: _('Войти на сайт Тайного Санты', 'Login to the Secret Santa website'),
    },
    ['/register']: {
      title: _('Тайный Санта | регистрация', 'Secret Santa | Registration'),
      description: _(
        'Зарегистрироваться на сайте Таного Санты',
        'Register on the Secret Santa site',
      ),
    },
    ['/recover']: {
      title: _('Тайный Санта | восстановить пароль', 'Secret Santa | Recover Password'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/reset_password']: {
      title: _('Тайный Санта | изменить пароль', 'Secret Santa | Change Password'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/randomizer']: {
      title: _('Тайный Санта | быстрая жеребьевка', 'Secret Santa | Quick Draw'),
      description: _(
        'Провести случайную онлайн жеребьевку на Тайного Санту между участниками бесплатно и без регистрации на сайте.',
        'Conduct a random online draw for Secret Santa among participants for free and without registration on the site.',
      ),
    },
    ['/faq']: {
      title: _('Тайный Санта | частые вопросы', 'Secret Santa | FAQ'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/feedback']: {
      title: _('Тайный Санта | обратная связь', 'Secret Santa | Feedback'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/donate']: {
      title: _('Тайный Санта | поддержать сайт', 'Secret Santa | Support the Site'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/account']: {
      title: _('Тайный Санта | настройки профиля', 'Secret Santa | Profile Settings'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/box/new']: {
      title: _('Тайный Санта | создать коробку', 'Secret Santa | Create a Box'),
      description: DESCRIPTION_DEFAULT,
    },
    ['/account/boxes']: {
      title: _('Тайный Санта | мои коробки', 'Secret Santa | My Boxes'),
      description: DESCRIPTION_DEFAULT,
    },
    [`${BOX_PATH}`]: {
      title: _('Тайный Санта | участники', 'Secret Santa | Participants'),
      description: '',
    },
    [`${BOX_PATH}/participants`]: {
      title: _('Тайный Санта | участники', 'Secret Santa | Participants'),
      description: '',
    },
    [`${BOX_PATH}/invite`]: {
      title: _('Тайный Санта | пригласить участников', 'Secret Santa | Invite Participants'),
      description: '',
    },
    [`${BOX_PATH}/settings`]: {
      title: _('Тайный Санта | настройки коробки', 'Secret Santa | Box Settings'),
      description: '',
    },
    [`${BOX_PATH}/card`]: {
      title: _('Тайный Санта | моя карточка', 'Secret Santa | My Card'),
      description: '',
    },
    [`${BOX_PATH}/card/edit`]: {
      title: _('Тайный Санта | редактировать карточку', 'Secret Santa | Edit Card'),
      description: '',
    },
    [`${BOX_PATH}/card/:id/edit`]: {
      title: _(
        'Тайный Санта | редактировать карточку участника',
        'Secret Santa | Edit Participant Card',
      ),
      description: '',
    },
    [`${BOX_PATH}/draw`]: {
      title: _('Тайный Санта | провести жеребьевку', 'Secret Santa | Conduct the Draw'),
      description: '',
    },
    [`${BOX_PATH}/santas`]: {
      title: _('Тайный Санта | кто чей Санта?', 'Secret Santa | Who is Whose Santa?'),
      description: '',
    },
    [`${BOX_PATH}/ward`]: {
      title: _('Тайный Санта | мой подопечный', 'Secret Santa | My Ward'),
      description: '',
    },
  },
};
