import * as React from 'react';
import { useEffect, useState } from 'react';
import { dispatchActionAppUpdateState, loadBoxInfo, loadCard, loadWard } from '@src/state';
import { CreateMyCardInput, FullCardInfoOutput } from '@lub0v/santa-utils';
import { santaClient } from '@src/state/client';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ANIMAL_TYPES,
  FormPage,
  FormPageGroup,
  GridColumn,
  GridContainer,
  Hint,
  Input,
  TextArea,
  Tip,
  validators,
} from '@src/components';
import { validateField } from '@src/utils/validators';
import { t, TRANSLATIONS } from '@src/translations';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { EditCardLoading } from '@src/pages/box/loading/EditCardLoading';
import { Redirect } from 'react-router';
import { DeleteSection } from '@src/helpers/DeleteSection';
import { resetEditCard, sendCardUpdatesToSanta } from '@src/pages/box/card/cardHelpers';
import { CardPicturePicker } from '@src/components/CardPicturePicker';

export const EditCardForm = (props: BoxPageProps) => {
  const [error, setError] = useState(undefined);
  const [deleteError, setDeleteError] = useState(undefined);
  useEffect(() => {
    loadCard(props.box.info.box.key).then(() => {
      resetEditCard();
    });
  }, []);

  const saveCard = async () => {
    const res: FullCardInfoOutput = await santaClient.saveCard(props.box.editCard);
    if (res.ok) {
      await sendCardUpdatesToSanta(props.box.card, res.data, props.box.info.box.key);
      dispatchActionAppUpdateState({ box: { card: res.data, saved: 'edit_card_saved' } });
      await loadBoxInfo(props.box.info.box.key, props.box.join);
    } else {
      setError(res.error.message);
    }
  };
  const { box } = props.box.info;

  const input = props.box.editCard;
  if (!props.box.card) {
    return <Redirect to={`/box/${props.box.info.box.key}/card`} />;
  }
  if (!input) {
    return <EditCardLoading {...props} />;
  }
  const updateState = (card: Partial<CreateMyCardInput>) => {
    dispatchActionAppUpdateState({ box: { editCard: card } });
  };

  const deleteCard = async () => {
    const res = await santaClient.deleteCard({ key: props.box.info.box.key });
    if (res.ok) {
      dispatchActionAppUpdateState({
        box: { card: null, editCard: null, createCard: { step: -1 } },
      });
      props.history.push(`/box/${props.box.info.box.key}`);
      await loadBoxInfo(props.box.info.box.key, props.box.join);
    } else {
      setDeleteError(res.error.message);
    }
  };

  const deleteCardGroup =
    props.box.info.isAdmin || !props.box.info.isActiveDraw ? (
      <FormPageGroup title="edit_my_card_delete_title" translator={props.translator}>
        <DeleteSection
          translator={props.translator}
          button="edit_my_card_delete_button"
          confirmDeleteWord="edit_my_card_delete_confirm_word"
          onClick={deleteCard}
          error={deleteError}
        >
          <Hint>edit_my_card_delete_hint</Hint>
        </DeleteSection>
      </FormPageGroup>
    ) : (
      <FormPageGroup title="edit_my_card_delete_title" translator={props.translator}>
        <Hint>edit_my_card_delete_after_draw_hint</Hint>
      </FormPageGroup>
    );

  const phoneValidation = props.box.info.box.isPhoneRequired ? [validators.required()] : [];

  return (
    <FormPage
      title="edit_my_card_title"
      onSubmit={saveCard}
      buttonSubmit={box.isArchived ? null : 'edit_my_card_save'}
      buttonBack="edit_my_card_back"
      translator={props.translator}
      onBack={() => props.history.push(getBoxPath('/card'))}
      error={error}
      secondary={box.isArchived ? null : deleteCardGroup}
    >
      <FormPageGroup title="edit_my_card_username_title">
        <Input
          disabled={box.isArchived}
          label="create_card_username"
          value={input.username}
          validators={[validateField(input, 'username')]}
          onChange={(username) => updateState({ username })}
        />
        <Input
          label="create_card_phone"
          value={input.phone}
          validators={[validateField(input, 'phone'), ...phoneValidation]}
          noBottomText
          onChange={(phone) => updateState({ phone })}
          pattern={new RegExp('^[\\- 0-9,+()]+$')}
          max={32}
        />
      </FormPageGroup>
      <FormPageGroup title="edit_my_card_picture_title" overflowScroll>
        <CardPicturePicker
          disabled={box.isArchived}
          selectedPicture={input.picture}
          image={input.avatar}
          onPictureChange={(picture) => updateState({ picture })}
          onCustomUploaded={(avatar) => {
            updateState({ avatar });
          }}
        />
      </FormPageGroup>
      {props.box.info.box.useWish ? (
        <FormPageGroup title="create_card_step_wish">
          <Tip icon="info">
            create_card_step_wish_hint
            {props.box.info.box.useCashLimit ? (
              <>
                <br />
                <br />
                {t(TRANSLATIONS.create_card_step_wish_hint_cash_limit)}
                <b>
                  {t(TRANSLATIONS.currency[props.box.info.box.cashLimitCurrency].num, {
                    count: props.box.info.box.cashLimit,
                  })}
                  .
                </b>
                {t(TRANSLATIONS.create_card_step_wish_hint_cash_limit_2)}
              </>
            ) : null}
          </Tip>
          <TextArea
            disabled={box.isArchived}
            label="create_card_step_wish"
            value={input.wish}
            onChange={(wish) => updateState({ wish })}
            validators={[validateField(input, 'wish')]}
          />
        </FormPageGroup>
      ) : null}

      {props.box.info.box.usePost ? (
        <FormPageGroup title="create_card_step_post_address_title">
          <Input
            disabled={box.isArchived}
            label="create_card_post_address"
            value={input.postalAddress}
            validators={[validateField(input, 'postalAddress'), validators.required()]}
            onChange={(postalAddress) => updateState({ postalAddress })}
          />
          <GridContainer spacing={2}>
            <GridColumn xs={8}>
              <Input
                disabled={box.isArchived}
                label="create_card_post_recipient"
                value={input.postalRecipient}
                validators={[validateField(input, 'postalRecipient'), validators.required()]}
                onChange={(postalRecipient) => updateState({ postalRecipient })}
              />
            </GridColumn>
            <GridColumn xs={4}>
              <Input
                disabled={box.isArchived}
                label="create_card_post_code"
                value={input.postalCode}
                validators={[validateField(input, 'postalCode'), validators.required()]}
                onChange={(postalCode) => updateState({ postalCode })}
                max={10}
                number
              />
            </GridColumn>
          </GridContainer>
        </FormPageGroup>
      ) : null}
    </FormPage>
  );
};
