import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonMain,
  CatAngry,
  CatPlayful,
  ColumnCenter,
  PictureNotice,
  TextMedium,
} from '@src/components';
import * as React from 'react';
import { t, TRANSLATIONS } from '@src/translations';

export function BoxUnauthorized(props: BoxPageProps) {
  return (
    <ColumnCenter
      style={{ maxWidth: '40rem', marginLeft: 'auto', marginRight: 'auto', marginBottom: '2rem' }}
    >
      <CatAngry style={{ maxWidth: '10rem' }} />
      <TextMedium translator={props.translator} style={{ marginTop: '1rem' }}>
        {t(TRANSLATIONS.box_not_authorized_hint, {
          isLoggedIn: !!props.session,
        })}
      </TextMedium>
    </ColumnCenter>
  );
}
