import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import { CatPlayful, PictureNotice } from '@src/components';

export function NoticeEmptyChat(props: BoxPageProps) {
  return (
    <PictureNotice translator={props.translator} title="" hint="notice_empty_chat_hint">
      {/* <CatPlayful /> */}
    </PictureNotice>
  );
}
