import * as React from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import { GridColumn, GridContainer } from '@lub0v/ui-components';
import { LoadingCardSuperBig } from '@src/loading/LoadingComponents';

export const BoxCardLoadingComponent = (props: BoxPageProps) => (
  <GridContainer spacing={5}>
    <GridColumn xs={12} md={6}>
      <LoadingCardSuperBig />
    </GridColumn>
    <GridColumn xs={12} md={6}>
      <LoadingCardSuperBig />
    </GridColumn>
  </GridContainer>
);
