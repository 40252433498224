import { RedirectOutput, TelegramLoginLinkInput } from '@lub0v/santa-utils';
import { santaClient } from '@src/state/client';
import { actionAppUpdateState, store } from '@src/state';

export function telegramLogin(redirect, onError: () => void) {
  // @ts-ignore
  window.Telegram.Login.auth(
    // @ts-ignore
    { bot_id: window.TelegramBotId, request_access: true },
    async (data) => {
      if (!data) {
        onError();
        return;
      }
      await socialLogin('tg', { redirect, ...data });
    },
  );
}

export async function socialLogin(
  type: string,
  input: { redirect?: string; [key: string]: any },
  login = true,
) {
  const params = {
    login,
    ...input,
    redirect: input.redirect || '/',
  };
  let res: RedirectOutput;
  switch (type) {
    case 'fb':
      res = await santaClient.loginFacebook(params);
      break;
    case 'google':
      res = await santaClient.loginGoogle(params);
      break;
    case 'vk':
      res = await santaClient.loginVkontakte(params);
      break;
    case 'tg':
      res = await santaClient.loginTelegram(params as TelegramLoginLinkInput);
      break;
    default:
      break;
  }
  console.log('RES LOGIN');
  console.log(res);
  // @ts-ignore
  window.location = res.data.redirect;
}

export function updateStoreEmail(email: string) {
  store.dispatch(
    actionAppUpdateState({
      recover: { email },
      register: { email },
      login: { email },
      boxCreate: { input: { email } },
    }),
  );
}
