import * as React from 'react';
import VK, { Like } from 'react-vk';

export const VkLike = () => (
  <VK apiId={4639542}>
    {/* <Like */}
    {/*  elementId={'vk_like'} */}
    {/*  options={{ */}
    {/*    type: 'mini', */}
    {/*    height: 24, */}
    {/*    pageUrl: 'http://santa-secret.ru/', */}
    {/*  }} */}
    {/* /> */}
  </VK>
);
