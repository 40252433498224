import * as React from 'react';
import {
  BoxHeaderInfo,
  ColumnStart,
  DotJoined,
  ErrorBoundary,
  Header,
  HeaderSecondary,
  Layout1,
  TextMedium,
} from '@lub0v/ui-components';
import { t } from '@src/translations';
import { HeaderComponent } from '@src/pages/base/HeaderComponent';
import { FooterComponent } from '@src/pages/base/FooterComponent';
import { LoadingBarSmall, LoadingPicture } from '@src/loading/LoadingComponents';
import { BoxPageProps, mapStateToPropsBoxBasePage } from '@src/pages/box/BoxPage';
import { connect } from 'react-redux';
import { BoxPageLoadingRoutes } from '@src/pages/box/loading/BoxPageLoadingRoutes';
import { ToggleMenuItemsLoading } from '@src/pages/box/loading/ToggleMenuItemsLoading';

function BoxPageLoadingComponent(props: BoxPageProps) {
  const RightItem = props.css.mobile ? null : <ToggleMenuItemsLoading {...props} />;
  const LeftItem = (
    <BoxHeaderInfo title={'...'} pic={<LoadingPicture />}>
      <DotJoined>
        <TextMedium grey>Участников: ...</TextMedium>
      </DotJoined>
    </BoxHeaderInfo>
  );
  return (
    <Layout1
      translator={t}
      headerFixed
      header={
        <Header shadow={props.headerShadow} link="/">
          {props.loading ? <LoadingBarSmall /> : <HeaderComponent {...props} />}
        </Header>
      }
      footer={<FooterComponent />}
      headerSecondary={<HeaderSecondary shadow leftItem={LeftItem} rightItem={RightItem} />}
    >
      {props.css.mobile ? (
        <ToggleMenuItemsLoading style={{ marginTop: '1.5rem' }} {...props} />
      ) : null}
      <ErrorBoundary>
        <ColumnStart style={{ marginTop: '2rem', marginBottom: '4rem' }}>
          <BoxPageLoadingRoutes {...props} translator={t} />
        </ColumnStart>
      </ErrorBoundary>
    </Layout1>
  );
}

export const BoxPageLoading = BoxPageLoadingComponent;
