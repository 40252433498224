import * as React from 'react';
import { BaseComponentProps, Divider, ColumnStart, InputTable } from '@lub0v/ui-components';
import { RandomizerInput } from '@lub0v/santa-utils';
import { useEffect } from 'react';
import { t, TRANSLATIONS } from '@src/translations';

interface RandomizerConfirmUsersTableProps extends BaseComponentProps {
  randomizerInput: RandomizerInput;
  orgParticipate: boolean;
}

export function RandomizerConfirmUsersTable(props: RandomizerConfirmUsersTableProps & any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ColumnStart>
      <Divider left translator={t}>
        randomizer_organizer
      </Divider>
      <InputTable
        style={{ marginTop: '1rem' }}
        readonly
        addNumber={true}
        value={[
          {
            username: props.randomizerInput.organizerUsername,
            email: props.randomizerInput.organizerEmail,
          },
        ]}
        translator={props.translator}
        columns={{
          username: {
            title: 'org_name',
          },
          email: {
            title: 'org_email',
          },
        }}
      />
      <Divider left translator={t}>
        randomizer_participants
      </Divider>
      <InputTable
        style={{ marginTop: '1rem' }}
        readonly
        addNumber={true}
        value={props.randomizerInput.users}
        translator={props.translator}
        columns={{
          username: {
            title: 'user_name',
          },
          email: {
            title: 'user_email',
          },
        }}
      />
    </ColumnStart>
  );
}
