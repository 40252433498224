import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const HOME_TRANSLATIONS = {
  secret_santa: _('Тайный Санта ', 'Secret Santa '),
  create_box: _('Создать коробку', 'Create a box'),
  quick_draw: _('Быстрая жеребьевка', 'Quick draw'),
  have_question_q: _(
    'Есть вопрос, отзыв или предложение?',
    'Do you have a question, feedback, or suggestion?',
  ),
  home_questions_title: _('Вопросы и ответы про сервис', 'Questions and answers about site'),
  home_have_more_questions: _('Есть еще вопросы?', 'Have more questions?'),
  home_have_more_questions_hint: _(
    'Поищите ответ на странице «Частые вопросы»',
    "Look for the answer on the 'Frequently Asked Questions' page",
  ),
  home_have_more_questions_button: _('Частые вопросы', 'Frequently Asked Questions'),
  write_to_me: _(
    'Напишите мне, и я постараюсь ответить как можно скорее.',
    'Write to me, and I will try to respond as soon as possible.',
  ),
  write_to_me_btn: _('Написать', 'Write'),
  home_main_text: _(
    'Организуй тайный обмен подарками между друзьями или коллегами',
    'Organize a secret gift exchange among friends or colleagues',
  ),
  home_steps_title: _('Как это работает?', 'How does it work?'),
  home_step_one_instruction: _(
    'Создайте коробку для жеребьевки, указав её название, ограничение на стоимость подарков и другие опции по желанию. Пригласите своих друзей, отправив им ссылку на коробку или через ввод их email адресов вручную.',
    'Create a draw box by specifying its name, a limit on the cost of gifts, and other options as desired. Invite your friends by sending them a link to the box or by manually entering their email addresses.',
  ),
  home_step_two_instruction: _(
    'Участники могут добавить в карточку свои пожелания по подарку и почтовый адрес. Следите за пополнением карточек участников, и, как только все игроки зарегистрировались, проведите тайную жеребьевку.',
    "Participants can add their gift preferences and mailing address to their card. Keep track of the participants' card updates, and once all players have registered, conduct the secret draw.",
  ),
  home_step_three_instruction: _(
    'Сразу после проведения жеребьевки всем участникам на почту придёт уведомление, и они узнают, кому нужно подготовить подарок.',
    'Immediately after the draw, all participants will receive a notification via email, and they will find out whom they need to prepare a gift for.',
  ),
  home_step_one_card_title: _('Стоимость подарков', 'Cost of gifts'),
  home_step_one_card_toggle: _('Ограничить стоимость подарков', 'Limit the cost of gifts'),
  home_step_one_input_title: _('Сумма', 'Amount'),
  home_step_one_select_title: _('Валюта', 'Currency'),
  home_step_one_select_value: _('Рубль', 'Ruble'),
  home_step_one_card_step: _('Шаг 2 из 4', 'Step 2 of 4'),
  home_step_two_address: _(
    <>
      Почтовый адрес: Москва, ул. 1905 года, д. 13, кв. 56, 143254 <br />
      Получатель: Кевин Паркер
    </>,
    <>
      Postal address: Moscow, 1905 year Street, Bldg. 13, Apt. 56, 143254 <br />
      Recipient: Kevin Parker
    </>,
  ),
  home_step_two_wish: _(
    <>
      Привет, дорогой Санта! В этом году я вел себя очень хорошо, и поэтому надеюсь, что ты сделаешь
      мне хороший сюрприз. Говорят, книга лучший подарок..
    </>,
    <>
      Hello, my dear Santa! This year I have been very good, so I hope you will make me a nice
      surprise. They say a book is the best gift..
    </>,
  ),
  home_step_three_message: _(
    <>
      Hello, Evgeny!
      <br />
      <br />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      The draw in the "Secret Santa" box has been conducted, and you have been assigned a giftee!
      Hurry to the website to find out who it is!
    </>,
  ),
  home_step_three_get_ward: _('Узнать подопечного', 'Find out your giftee'),
  home_counter: _('Сант в этом году', 'Santas this year'),
};
