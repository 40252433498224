import { BoxPageProps } from '@src/pages/box/BoxPage';
import { t, TRANSLATIONS } from '@src/translations';
import { BoxInfo } from '@lub0v/santa-utils';

export interface CreateCardProps extends BoxPageProps {
  goNext: () => void;
  goBack: () => void;
  error?: string;
}

export function createCardStepTitle(props: CreateCardProps) {
  return t(TRANSLATIONS.create_card_step_title, {
    step: props.box.createCard.step,
    total: calculateCreateCardTotalSteps(props.box.info),
  });
}

export function calculateCreateCardTotalSteps(boxInfo: BoxInfo) {
  let steps = 2; // name/phone + picture;
  if (boxInfo.box.usePost) {
    steps += 1;
  }
  if (boxInfo.box.useWish) {
    steps += 1;
  }
  return steps;
}
