import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ToggleMenu, ToggleMenuItem } from '@lub0v/ui-components';

type Props = BoxPageProps & { button?: any };

export const ToggleMenuItemsLoading = (props: Props) => {
  const { location } = props.history;
  const isCardActive =
    location.pathname.endsWith('/card') || location.pathname.endsWith('/card/edit');
  const isWardActive = location.pathname.endsWith('/ward');
  // eslint-disable-next-line no-nested-ternary
  const activeIndex = isCardActive ? 1 : isWardActive ? 2 : 0;
  return (
    <ToggleMenu button={props.button} active={activeIndex} disabled style={props.style}>
      <ToggleMenuItem>Участники</ToggleMenuItem>
      <ToggleMenuItem>Моя карточка</ToggleMenuItem>
      <ToggleMenuItem>Подопечный</ToggleMenuItem>
    </ToggleMenu>
  );
};
