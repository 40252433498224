import * as React from 'react';

import { connect } from 'react-redux';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { SantaAppState } from '@src/state';

import { UserAccountInfo } from '@lub0v/santa-utils';
import { socialLogin } from '@src/pages/auth/auth';
import { AccountInfo } from '@lub0v/santa-utils/dist/src/types/account';
import {
  Icon,
  BaseComponentProps,
  ButtonSocial,
  RowStart,
  withBase,
  ColumnStart,
} from '@src/components';
import { isDotCom } from '@src/host';

function getSocialBtn(
  type: string,
  accounts: AccountInfo[],
  onClick: () => void,
  onUnlinkClick?: () => void,
) {
  const account = accounts.find((a) => a.accountType === type);
  if (account) {
    return (
      <RowStart>
        <ButtonSocial type={type} disabled fixed>
          {account.username} - аккаунт привязан
        </ButtonSocial>
        <Icon
          type="close-colored"
          onClick={onUnlinkClick ?? onClick}
          style={{ marginLeft: '0.3rem' }}
        />
      </RowStart>
    );
  }
  return (
    <RowStart>
      <ButtonSocial type={type} onClick={onClick} fixed>
        {`link_with_${type}`}
      </ButtonSocial>
      <div style={{ marginLeft: '0.3rem', width: '1.79rem', height: '1.79rem' }} />
    </RowStart>
  );
}

function SocialLinkComponent(props: { account: UserAccountInfo } & BaseComponentProps) {
  const { account } = props;
  if (!account.accounts) {
    return <></>;
  }
  return (
    <ColumnStart translator={props.translator}>
      {getSocialBtn('google', account.accounts, () => {
        socialLogin('google', { redirect: '/account' }, false);
      })}
      {getSocialBtn('vk', account.accounts, () => {
        socialLogin('vk', { redirect: '/account' }, false);
      })}
      {isDotCom()
        ? getSocialBtn('fb', account.accounts, () => {
            socialLogin('fb', { redirect: '/account' }, false);
          })
        : null}
      {getSocialBtn(
        'tg',
        account.accounts,
        () => {
          // @ts-ignore
          window.Telegram.Login.auth(
            // @ts-ignore
            { bot_id: window.TelegramBotId, request_access: true },
            async (data) => {
              if (data) {
                await socialLogin('tg', { redirect: '/account', ...data }, false);
              }
            },
          );
        },
        () => {
          socialLogin('tg', { redirect: '/account' }, false);
        },
      )}
    </ColumnStart>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    account: state.account.account,
  };
}

export const SocialLink = withBase(connect(mapStateToProps)(SocialLinkComponent));
