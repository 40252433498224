import * as React from 'react';
import { dispatchActionAppUpdateState } from '@src/state';
import { FormCard, GridColumn, GridContainer, Input, validators } from '@lub0v/ui-components';
import { validateField } from '@src/utils/validators';
import { CreateCardProps, createCardStepTitle } from '@src/pages/box/card/CreateCardTypes';
import { useEffect } from 'react';

export const CreateCardStepPostAddress = (props: CreateCardProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FormCard
      title="create_card_step_post_address_title"
      translator={props.translator}
      step={createCardStepTitle(props)}
      onSubmit={props.goNext}
      onBack={props.goBack}
      error={props.error}
    >
      <Input
        label="create_card_post_address"
        value={props.box.createCard.input.postalAddress}
        validators={[
          validateField(props.box.createCard.input, 'postalAddress'),
          validators.required(),
        ]}
        onChange={(postalAddress) => {
          dispatchActionAppUpdateState({ box: { createCard: { input: { postalAddress } } } });
        }}
      />
      <GridContainer spacing={2}>
        <GridColumn xs={8}>
          <Input
            label="create_card_post_recipient"
            value={props.box.createCard.input.postalRecipient}
            validators={[
              validateField(props.box.createCard.input, 'postalRecipient'),
              validators.required(),
            ]}
            onChange={(postalRecipient) => {
              dispatchActionAppUpdateState({ box: { createCard: { input: { postalRecipient } } } });
            }}
          />
        </GridColumn>
        <GridColumn xs={4}>
          <Input
            label="create_card_post_code"
            value={props.box.createCard.input.postalCode}
            validators={[
              validateField(props.box.createCard.input, 'postalCode'),
              validators.required(),
            ]}
            onChange={(postalCode) => {
              dispatchActionAppUpdateState({ box: { createCard: { input: { postalCode } } } });
            }}
            max={10}
          />
        </GridColumn>
      </GridContainer>
    </FormCard>
  );
};
