import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormCard, TextSecondaryRegular, Tip, validators } from '@src/components';
import { CreateCardProps } from '@src/pages/box/card/CreateCardTypes';
import { dispatchActionAppUpdateState } from '@src/state';
import { setCookie } from '@src/utils/cookie';
import { ButtonSocial, ButtonSocialGroup, Divider, Hint, Input } from '@lub0v/ui-components';
import { socialLogin, telegramLogin, updateStoreEmail } from '@src/pages/auth/auth';
import { validateField } from '@src/utils/validators';
import { isDotCom } from '@src/host';

export const CreateCardStepLogin = (props: CreateCardProps) => {
  const [redirect, setRedirect] = useState(undefined);
  useEffect(() => {
    window.scrollTo(0, 0);
    const loc = props.history.location;
    setRedirect(`${loc.pathname + loc.search}`);
    dispatchActionAppUpdateState({
      register: { email: null, username: null, redirect: `${loc.pathname + loc.search}` },
    });
  }, []);

  const onBtnClick = () => {
    setCookie('cardStep', '1');
  };
  return (
    <FormCard
      title="new_box_step0_title"
      translator={props.translator}
      step={''}
      onSubmit={props.goNext}
      onBack={props.goBack}
      error={props.error}
    >
      <Tip blueberry icon="info">
        create_card_step_login_title
        <br />
        <br />
        <TextSecondaryRegular blueberry>register_policy_agreement_hint</TextSecondaryRegular>
      </Tip>
      <ButtonSocialGroup
        style={{ gridTemplateColumns: isDotCom() ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr' }}
      >
        <ButtonSocial
          type="google"
          onClick={() => {
            onBtnClick();
            socialLogin('google', { redirect });
          }}
        >
          register_with_google
        </ButtonSocial>
        <ButtonSocial
          type="vk"
          onClick={() => {
            onBtnClick();
            socialLogin('vk', { redirect });
          }}
        >
          register_with_vk
        </ButtonSocial>
        {isDotCom() ? (
          <ButtonSocial
            type="fb"
            onClick={() => {
              onBtnClick();
              socialLogin('fb', { redirect });
            }}
          >
            register_with_fb
          </ButtonSocial>
        ) : null}
        <ButtonSocial
          type="tg"
          onClick={() => {
            telegramLogin(redirect, () => {});
          }}
        >
          login_with_tg
        </ButtonSocial>
      </ButtonSocialGroup>
      <Divider>or</Divider>
      <Input
        label="your_name"
        value={props.box.createCard.input.username}
        validators={[validateField(props.box.createCard.input, 'username'), validators.required()]}
        onChange={(username) => {
          dispatchActionAppUpdateState({ box: { createCard: { input: { username } } } });
        }}
      />
      <Input
        label="your_email"
        noBottomText
        value={props.box.createCard.input.email}
        validators={[validateField(props.box.createCard.input, 'email'), validators.required()]}
        onChange={(email) => {
          updateStoreEmail(email);
          dispatchActionAppUpdateState({ box: { createCard: { input: { email } } } });
        }}
      />
      <Hint center>we_will_send_register_link</Hint>
    </FormCard>
  );
};
