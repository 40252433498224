import * as React from 'react';
import { useEffect } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, dispatchActionResetNewBox, SantaAppState } from '@src/state';
import { NewBoxStep0 } from '@src/pages/new_box/NewBoxStep0';
import { NewBoxStepName } from '@src/pages/new_box/NewBoxStepName';
import { NewBoxStepCost } from '@src/pages/new_box/NewBoxStepCost';
import { NewBoxProps } from '@src/pages/new_box/NewBoxProps';
import { NewBoxStepLoading } from '@src/pages/new_box/NewBoxStepLoading';
import { NewBoxStepInfo } from '@src/pages/new_box/NewBoxStepInfo';
import { ColumnCenter, PictureNotice, PandaWithGift } from '@src/components';
import { Redirect } from 'react-router';
import { NewBoxStepPicture } from '@src/pages/new_box/NewBoxStepPicture';
import { santaClient } from '@src/state/client';
import { BoxKeyOutput } from '@lub0v/santa-utils';

function getStepComponent(step: number) {
  switch (step) {
    case 0:
      return NewBoxStep0;
    case 1:
      return NewBoxStepName;
    case 2:
      return NewBoxStepPicture;
    case 3:
      return NewBoxStepCost;
    case 4:
      return NewBoxStepInfo;
    default:
      return null;
  }
}

function NewBoxPageComponent(props: NewBoxProps & { loading: boolean }) {
  const setStep = (step: number) => {
    dispatchActionAppUpdateState({ boxCreate: { step } });
  };
  useEffect(() => {
    dispatchActionResetNewBox(() => {
      if (props.session) {
        dispatchActionAppUpdateState({
          boxCreate: { step: 1 },
        });
      } else {
        dispatchActionAppUpdateState({
          register: { redirect: '/box/new' },
          boxCreate: { step: 0 },
        });
      }
      if (!props.boxCreate.input.key) {
        santaClient.generateBoxKey().then((res: BoxKeyOutput) => {
          if (res.ok) {
            dispatchActionAppUpdateState({ boxCreate: { input: { key: res.data } } });
          }
        });
      }
    });
  }, []);
  if (props.loading) {
    return <NewBoxStepLoading />;
  }
  const StepComponent = getStepComponent(props.boxCreate.step);
  if (StepComponent) {
    return (
      <StepComponent
        {...props}
        goBack={() => {
          const { step } = props.boxCreate;
          if (step === 0 || (step === 1 && props.session)) {
            dispatchActionResetNewBox();
            dispatchActionAppUpdateState({
              boxCreate: { step: 0 },
            });
            props.history.goBack();
          } else {
            setStep(step - 1);
          }
        }}
        goNext={() => {
          if (props.boxCreate.step <= 4) {
            setStep(props.boxCreate.step + 1);
          }
        }}
      />
    );
  }
  if (props.session) {
    return <Redirect to={`/box/${props.boxCreate.input.key}`} />;
  }
  return (
    <ColumnCenter style={{ marginTop: '5rem' }}>
      <PictureNotice
        translator={props.translator}
        title="new_box_register_email_sent"
        hint="new_box_register_email_sent_hint"
      >
        <PandaWithGift />
      </PictureNotice>
    </ColumnCenter>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    session: state.session,
    randomizer: state.randomizer,
    boxCreate: state.boxCreate,
    loading: state.loading,
    register: state.register,
  };
}

export const NewBoxPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(NewBoxPageComponent, { noFooter: true, wide: true }),
);
