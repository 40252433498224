import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const VALIDATIONS_TRANSLATIONS = {
  ['validations.form_errors']: _('В форме допущены ошибки', 'Errors in the form'),
  ['validations.required']: _('Обязательное поле', 'Required field'),
  ['validations.invalid']: _('Некорректное поле', 'Invalid field'),
  ['validations.minCharLength']: _(
    (field) => {
      if (field === 'username') {
        return `Имя должно быть более {min символ|a|ов|ов}`;
      }
      return `Значение должно быть более {min символ|a|ов|ов}`;
    },
    (field) => {
      if (field === 'username') {
        return `The username must be more than {min character||s|s}`;
      }
      return `The value must be more than {min character||s|s}`;
    },
  ),
  ['validations.maxCharLength']: _(
    (field) => {
      if (field === 'username') {
        return `Имя должно быть не более {max символ|a|ов|ов}`;
      }
      return `Значение должно быть не более {max символ|a|ов|ов}`;
    },
    (field) => {
      if (field === 'username') {
        return `The username must be no more than {max character||s|s}`;
      }
      return `The value must be no more than {max character||s|s}`;
    },
  ),
  ['validations.invalidEmail']: _('Некорректный email', 'Invalid email'),
  ['validations.passwordsDiffer']: _('Пароли не совпадают', 'Passwords do not match'),
  ['validations.not_unique_name']: _('Не уникальное имя', 'Name is not unique'),
  ['validations.not_unique_email']: _('Не уникальный email', 'Email is not unique'),
  ['validations.username_invalid']: _('Некорректное имя', 'Invalid name'),
  ['validations.min_1_required']: _(
    'Необходим хотя бы один участник',
    'At least one participant is required',
  ),
  ['validations.min_3_required']: _('Слишком мало участников', 'Too few participants'),
  ['validations.max_5_allowed']: _(
    'Слишком много участников. Максимальное допустимое количество участников - 5',
    'Too many participants. The maximum allowable number of participants is 5',
  ),
  ['validations.max_50_allowed']: _(
    'Слишком много участников. Максимальное допустимое количество участников - 50',
    'Too many participants. The maximum allowable number of participants is 50',
  ),
  ['validations.max_100_allowed']: _(
    'Слишком много участников. Максимальное допустимое количество участников - 100',
    'Too many participants. The maximum allowable number of participants is 100',
  ),
  validations: {
    'validations.required_field': _('Обязательное поле', 'Required field'),
    'validations.password_length': _('Слишком короткий пароль', 'Password too short'),
    'validations.password_length_big': _('Слишком длинный пароль', 'Password too long'),
    'validations.invalid_email': _('Некорректный email', 'Invalid email'),
    'validations.username_too_short': _('Слишком короткое имя', 'Username too short'),
    'validations.username_too_long': _('Слишком длинное имя', 'Username too long'),
    'validations.min_length': _('Слишком короткое значение', 'Value too short'),
    'validations.max_length': _('Слишком длинное значение', 'Value too long'),
    'validations.account_confirm_delete_incorrect': _('Неверно', 'Incorrect'),
    'validations.confirm_incorrect': _('Неверно', 'Incorrect'),
    'validations.min_1_required': _(
      'Необходим хотя бы один участник',
      'At least one participant required',
    ),
  },
};
