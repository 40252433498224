import * as React from 'react';

import { connect } from 'react-redux';
import { BasePageProps, withBasePage } from '@src/pages/withBasePage';
import { mapStateToProps } from '@src/pages/DonatePage';
import { ColumnCenter, PandaWithGift, PictureNotice } from '@src/components';
import { getBoxPath } from '@src/pages/box/boxHelpers';

function DonateSuccessPageComponent(props: BasePageProps) {
  return (
    <ColumnCenter style={{ marginTop: '5rem' }} translator={props.translator}>
      <PictureNotice
        translator={props.translator}
        title="notice_donate_success_title"
        hint="notice_donate_success_hint"
        button="back_to_home"
        onClick={() => {
          props.history.push(getBoxPath('/'));
        }}
      >
        <PandaWithGift />
      </PictureNotice>
    </ColumnCenter>
  );
}

export const DonateSuccessPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(DonateSuccessPageComponent),
);
