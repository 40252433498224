import * as React from 'react';

import { SessionObject } from '@lub0v/santa-utils';
import { BaseComponentProps, ErrorForm, Header, Layout1, TextMedium } from '@src/components';
import { AccountNotifications, CssProps, SantaAppState } from '@src/state';
import { t, TRANSLATIONS } from '@src/translations';
import { HeaderComponent } from '@src/pages/base/HeaderComponent';
import { FooterComponent } from '@src/pages/base/FooterComponent';
import { LoadingBarSmall } from '@src/loading/LoadingComponents';
import { UserNotifications } from '@src/pages/UserNotifications';
import { useEffect } from 'react';
import { AccessUnauthorized } from '@src/pages/AccessUnauthorized';
import { ModalProps } from '@lub0v/ui-components';

export interface BasePageProps extends BaseComponentProps {
  translator?: any;
  session: SessionObject;
  notifications: AccountNotifications;
  loading: boolean;
  css: CssProps;
  urlParams: any;
  headerShadow?: boolean;
  history?: any;
  counter?: number;
  modalProps?: ModalProps;
  lang: string;
}

type Properties = {
  noFooter?: boolean;
  wide?: boolean;
  headerSecondary?: any;
  headerShadow?: boolean;
  headerHome?: boolean;
  headerFixed?: boolean;
  counter?: boolean;
  authenticatedOnly?: boolean;
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function withBasePage<T extends BasePageProps>(
  Component: React.ComponentType<T>,
  options: Properties = {
    noFooter: false,
    wide: false,
    headerShadow: true,
    headerHome: false,
    headerFixed: false,
    counter: false,
    authenticatedOnly: false,
  },
) {
  return function BasePageComponent(props: BasePageProps & T) {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const counter =
      props.counter > 0 ? (
        <TextMedium style={{ cursor: 'default' }}>
          {numberWithCommas(props.counter)} {t(TRANSLATIONS.home_counter)}
        </TextMedium>
      ) : null;
    if (props.urlParams.error) {
      return (
        <>
          <Layout1
            translator={t}
            wide={options.wide}
            header={
              <Header
                left={options.counter && counter ? counter : null}
                shadow={options.headerShadow}
                link="/"
                lang={props.lang}
              >
                {props.loading ? <LoadingBarSmall /> : <HeaderComponent {...props} />}
              </Header>
            }
            headerFixed={options.headerFixed}
            footer={<FooterComponent />}
            notifications={
              <UserNotifications
                {...props.notifications}
                translator={props.translator}
                history={props.history}
              />
            }
          >
            <ErrorForm errors={[props.urlParams.error]} translator={props.translator} />
          </Layout1>
        </>
      );
    }
    let hSecondary = options.headerSecondary;
    if (hSecondary) {
      hSecondary = React.cloneElement(hSecondary, { key: props.lang, lang: props.lang });
    }
    return (
      <>
        <Layout1
          translator={t}
          wide={options.wide}
          header={
            <Header
              left={options.counter && counter ? counter : null}
              shadow={options.headerShadow}
              link="/"
              lang={props.lang}
            >
              {props.loading ? <LoadingBarSmall /> : <HeaderComponent {...props} />}
            </Header>
          }
          headerFixed={options.headerFixed}
          headerHome={options.headerHome}
          footer={options.noFooter ? null : <FooterComponent />}
          headerSecondary={hSecondary}
          modalProps={props.modalProps}
          notifications={
            <UserNotifications
              {...props.notifications}
              translator={props.translator}
              history={props.history}
            />
          }
          key={props.lang}
        >
          {options.authenticatedOnly && !props.session ? (
            <AccessUnauthorized {...props} />
          ) : (
            <Component {...props} />
          )}
        </Layout1>
        {/*<div*/}
        {/*  className="left-ads"*/}
        {/*  style={{*/}
        {/*    background: '#f1f1f1',*/}
        {/*    position: 'absolute',*/}
        {/*    left: '5rem',*/}
        {/*    width: '300px',*/}
        {/*    height: '800px',*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<div*/}
        {/*  className="right-ads"*/}
        {/*  style={{*/}
        {/*    background: '#f1f1f1',*/}
        {/*    position: 'absolute',*/}
        {/*    right: '5rem',*/}
        {/*    width: '300px',*/}
        {/*    height: '800px',*/}
        {/*  }}*/}
        {/*/>*/}
      </>
    );
  };
}

export function mapStateToPropsBasePage(state: SantaAppState) {
  return {
    session: state.session,
    notifications: state.notifications,
    loading: state.loading,
    css: state.css,
    urlParams: state.urlParams,
    counter: state.counter,
    modalProps: state.modal,
    lang: state.language,
  };
}
