import { SessionObject } from '@lub0v/santa-utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { santaClient } from '@src/state/client';
import { CssProps, SantaAppState } from '@src/state';

import { ButtonService, GoodsCarousel, ProductPanel, SimpleLink } from '@src/components';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { t, TRANSLATIONS } from '@src/translations';
import { connect } from 'react-redux';
import { shuffleArray } from '@lub0v/utils/dist/src/random';

type GoodsCarouselProps = {
  session: SessionObject;
  css: CssProps;
  isTortomaster: boolean;
  lang: string;
};

function GoodsCarouselInternal(props: GoodsCarouselProps) {
  const [goods, setGoods] = useState([]);
  const [nextClicks, setNextClicks] = useState(0);
  useEffect(() => {
    santaClient.getGoods().then((res) => {
      const items = [];
      for (const item of res.data) {
        if (props.isTortomaster) {
          if (item.link.includes('tortomaster')) {
            items.push(item);
          }
        } else {
          for (let i = 0; i < item.weight; i += 1) {
            items.push(item);
          }
        }
      }
      setGoods(Array.from(new Set(shuffleArray(items))));
    });
  }, []);

  if (!goods.length) {
    return null;
  }
  const products = goods.map((g) => {
    const price = `${g.price}${g.currency}`;
    const onLike = props.session
      ? async () => {
          const res = await santaClient.likeGoods({ id: `${g.id}` });
          const gg = res.data;
          const newGoods = [];
          for (let i = 0; i < goods.length; i += 1) {
            const gods = gg.find((gds) => gds.id === goods[i].id);
            if (gods) {
              newGoods.push(gods);
            }
          }
          setGoods(newGoods);
        }
      : undefined;
    return (
      <ProductPanel
        key={g.id}
        image={g.image}
        imagePosition={g.imagePosition}
        desc={g.description}
        price={price}
        onLike={onLike}
        liked={g.liked}
        lang={props.lang}
        adInfo={g.advertiser_info}
      >
        <SimpleLink
          href={g.link}
          target="_blank"
          onClick={() => reachGoalYaMetrika('goods_clicked')}
        >
          <ButtonService>Купить</ButtonService>
        </SimpleLink>
      </ProductPanel>
    );
  });
  return (
    <GoodsCarousel
      key={products.length}
      minPadding={props.css.mobile ? 4 : 0}
      title={t(TRANSLATIONS.gift_our_ideas_title)}
      onNextClick={() => {
        setNextClicks(nextClicks + 1);
        reachGoalYaMetrika(`goods_next_click_${nextClicks + 1}`);
      }}
    >
      {products}
    </GoodsCarousel>
  );
}

function mapStateToProps(state: SantaAppState) {
  return {
    lang: state.language,
    session: state.session,
    css: state.css,
    isTortomaster:
      state.box &&
      state.box.info &&
      state.box.info.box &&
      state.box.info.box.key === 'tortomaster2023',
  };
}

export const SantaGoodsCarousel = connect(mapStateToProps)(GoodsCarouselInternal);
