import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ColumnCenter, PenguinWithRaisedHands, PictureNotice, TextMedium } from '@src/components';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState, loadBoxInfo } from '@src/state';
import * as React from 'react';
import { useState } from 'react';

export function DrawActiveNotice(props: BoxPageProps) {
  if (!props.box.info.isActiveDraw) {
    return null;
  }
  const [isResetting, setIsResetting] = useState(false);
  const { box } = props.box.info;

  const setConfirmResetDraw = (confirmResetDraw: boolean) => {
    dispatchActionAppUpdateState({
      modal: {
        opened: confirmResetDraw,
        title: 'modal_reset_draw_title',
        message: 'modal_reset_draw_message',
        buttonYes: 'modal_reset_draw_button_yes',
        buttonNo: 'modal_cancel',
        loading: false,
        onCancel: () => {
          setConfirmResetDraw(false);
        },
        onSubmit: async () => {
          if (!isResetting) {
            setIsResetting(true);
            setTimeout(async () => {
              dispatchActionAppUpdateState({ modal: { loading: true } });
              const r = await santaClient.resetDraw({ key: props.box.info.box.key });
              if (r.ok) {
                setConfirmResetDraw(false);
                await loadBoxInfo(props.box.info.box.key);
                setIsResetting(false);
              }
            }, 20);
          }
        },
      },
    });
  };

  return (
    <ColumnCenter style={{ marginTop: '3rem' }}>
      <PictureNotice
        translator={props.translator}
        title="draw_done_title"
        hint={
          box.isArchived ? (
            'draw_done_hint_archived'
          ) : (
            <>
              draw_done_hint{' '}
              <TextMedium orange underline onClick={() => setConfirmResetDraw(true)}>
                draw_done_reset
              </TextMedium>
              .
            </>
          )
        }
        button="draw_done_button"
        loading={isResetting}
        onClick={() => {
          props.history.push(getBoxPath('/'));
        }}
      >
        <PenguinWithRaisedHands />
      </PictureNotice>
    </ColumnCenter>
  );
}
