import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonMain,
  CatAngry,
  ColumnCenter,
  PictureNotice,
  ButtonSecondary,
} from '@src/components';
import * as React from 'react';
import { BasePageProps } from '@src/pages/withBasePage';

export function AccessUnauthorized(props: BasePageProps) {
  return (
    <ColumnCenter style={{ marginTop: '5rem' }}>
      <PictureNotice
        translator={props.translator}
        title="notice_not_authorized_title"
        hint="notice_not_authorized_hint"
        btn={
          <ButtonSecondary translator={props.translator} onClick={() => props.history.push('/')}>
            back_to_home
          </ButtonSecondary>
        }
        btn2={
          <ButtonMain translator={props.translator} onClick={() => props.history.push('/login')}>
            log_in_to_site
          </ButtonMain>
        }
        onClick={() => props.history.push('/login')}
        onClick2={() => props.history.push('/')}
      >
        <CatAngry />
      </PictureNotice>
    </ColumnCenter>
  );
}
