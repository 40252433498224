import { CreateMyCardInput, CreateOtherCardInput, FullCardInfo } from '@lub0v/santa-utils';
import { dispatchActionAppUpdateState, SantaAppState, store } from '@src/state';
import * as levenshtein from 'js-levenshtein';
import { t, TRANSLATIONS } from '@src/translations';
import { santaClient } from '@src/state/client';

export function resetEditCard() {
  const { box } = store.getState() as SantaAppState;
  const { card } = box;
  if (!card || !box.info) {
    return;
  }
  const editCard: CreateMyCardInput = new CreateMyCardInput();
  editCard.key = box.info.box.key;
  editCard.username = card.username;
  editCard.wish = card.wish;
  editCard.postalAddress = card.postalAddress;
  editCard.postalCode = card.postalCode;
  editCard.postalRecipient = card.postalRecipient;
  editCard.picture = card.picture;
  editCard.phone = card.phone;
  dispatchActionAppUpdateState({ box: { editCard } });
}

export function createCardInputFromCard() {
  const { box } = store.getState() as SantaAppState;
  const { card } = box;
  if (!card || !box.info) {
    return;
  }

  const input = new CreateMyCardInput();
  input.key = box.info.box.key;
  input.username = card.username;
  input.picture = card.picture;
  input.wish = card.wish;
  input.postalCode = card.postalCode;
  input.postalRecipient = card.postalRecipient;
  input.postalAddress = card.postalAddress;
  input.phone = card.phone;
  dispatchActionAppUpdateState({ box: { createCard: { input } } });
}

export function setEditOtherCard(id: number, key: string, card: FullCardInfo) {
  const editOtherCard: CreateOtherCardInput = new CreateOtherCardInput();
  editOtherCard.key = key;
  editOtherCard.id = id;
  editOtherCard.email = card.email;
  editOtherCard.username = card.username;
  editOtherCard.wish = card.wish;
  editOtherCard.postalAddress = card.postalAddress;
  editOtherCard.postalCode = card.postalCode;
  editOtherCard.postalRecipient = card.postalRecipient;
  editOtherCard.picture = card.picture;
  editOtherCard.isVerified = card.isVerified;
  editOtherCard.phone = card.phone;
  dispatchActionAppUpdateState({ box: { otherCard: card, editOtherCard } });
}

export function resetEditOtherCard() {
  dispatchActionAppUpdateState({ box: { otherCard: null, editOtherCard: null } });
}

export function isWishChanged(a: FullCardInfo, b: FullCardInfo) {
  if ((!a.wish && b.wish) || (!b.wish && b.wish)) {
    return true;
  }
  return levenshtein(a.wish, b.wish) > 0;
}

export function isAddressChanged(a: FullCardInfo, b: FullCardInfo) {
  return (
    a.postalCode !== b.postalCode ||
    a.postalRecipient !== b.postalRecipient ||
    a.postalAddress !== b.postalAddress
  );
}

export async function sendCardUpdatesToSanta(
  oldCard: FullCardInfo,
  newCard: FullCardInfo,
  key: string,
) {
  const wishChanged = isWishChanged(oldCard, newCard);
  const addressChanged = isAddressChanged(oldCard, newCard);
  if (!wishChanged && !addressChanged) {
    return;
  }
  let message = '';
  if (wishChanged && addressChanged) {
    message = t(TRANSLATIONS.card_info_updated);
  } else if (wishChanged) {
    message = t(TRANSLATIONS.card_info_wish_updated);
  } else {
    message = t(TRANSLATIONS.card_info_address_updated);
  }
  const r = await santaClient.writeToSanta({
    key,
    message,
    isAutomatic: true,
  });
  if (r.ok) {
    dispatchActionAppUpdateState({ box: { santaMessages: r.data } });
  }
}

export async function sendAdminCardUpdatesToSanta(
  oldCard: FullCardInfo,
  newCard: FullCardInfo,
  key: string,
) {
  const wishChanged = isWishChanged(oldCard, newCard);
  const addressChanged = isAddressChanged(oldCard, newCard);
  if (!wishChanged && !addressChanged) {
    return;
  }
  let message = '';
  if (wishChanged && addressChanged) {
    message = t(TRANSLATIONS.card_info_updated_admin);
  } else if (wishChanged) {
    message = t(TRANSLATIONS.card_info_wish_updated_admin);
  } else {
    message = t(TRANSLATIONS.card_info_address_updated_admin);
  }
  await santaClient.writeToSanta({
    key,
    cardId: newCard.id,
    message,
    isAutomatic: true,
  });
}
