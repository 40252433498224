import * as React from 'react';
import { useRef, useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { DonateInput } from '@lub0v/santa-utils';
import { AdLook } from '@src/components/AdLook';
import {
  ButtonCash,
  ButtonMain,
  ButtonNav,
  ColumnStart,
  HintError,
  Input,
  RabbitWithCup,
  RowEnd,
  RowSpaceBetween,
  RowStart,
  SimpleLink,
  TextArea,
  TextH3Medium,
  TextMedium,
} from '@src/components';
import { santaClient } from '@src/state/client';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { getFrontendHost } from '@src/host';
import { t } from '@src/translations';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface DonatePageProps extends BasePageProps {
  donate: DonateInput;
}

function DonatePageComponent(props: DonatePageProps) {
  const [otherAmount, setOtherAmount] = useState('');
  const [error, setError] = useState(undefined);
  const submitButtonRef = useRef(null);
  const sendDonate = async () => {
    if (props.donate.amount <= 0) {
      setError(t('donate_err'));
      return;
    }
    santaClient.donate(props.donate);
    reachGoalYaMetrika('donate_click');
    submitButtonRef.current.click();
  };
  const styleNoWrap = {
    whiteSpace: 'no-wrap',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    marginRight: '0.5rem',
  };
  const amounts = [50, 100, 250, 500, 1000];
  const methods = [
    {
      title: t('donate_method_pc'),
      value: 'PC',
    },
    {
      title: t('donate_method_ac'),
      value: 'AC',
    },
    {
      title: t('donate_method_mc'),
      value: 'MC',
    },
  ];
  return (
    <ColumnStart
      style={{ margin: '3rem auto 2rem auto', width: '100%', maxWidth: '50rem' }}
      alignCenter
    >
      <TextH3Medium style={{ textAlign: 'center' }}>
        {t('donate_title_site_improving_by')}{' '}
        <TextH3Medium orange>
          <SimpleLink
            href="https://vk.com/lubka"
            target="_blank"
            onClick={() => reachGoalYaMetrika('vk_profile_click')}
          >
            {t('donate_liubov')}
          </SimpleLink>
        </TextH3Medium>{' '}
        {t('donate_with_love')}
      </TextH3Medium>
      <RabbitWithCup style={{ width: '13rem', marginTop: '0.5rem' }} />
      <TextMedium
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
          textAlign: 'center',
          width: '100%',
          maxWidth: '40rem',
        }}
      >
        {t('donate_message')}
      </TextMedium>
      <ColumnStart style={{ width: '100%', maxWidth: '40rem', marginTop: '1rem' }}>
        <RowSpaceBetween style={{ flexWrap: 'wrap' }}>
          {methods.map((m) => (
            <ButtonNav
              key={m.value}
              active={props.donate.method === m.value}
              style={styleNoWrap}
              onClick={() => {
                dispatchActionAppUpdateState({ donate: { method: m.value } });
              }}
            >
              {m.title}
            </ButtonNav>
          ))}
        </RowSpaceBetween>
        <RowSpaceBetween style={{ marginTop: '0.5rem', flexWrap: 'wrap' }}>
          {amounts.map((am) => (
            <ButtonCash
              style={{ marginTop: '0.8rem' }}
              key={`${am}${t('donate_curr')}`}
              active={`${props.donate.amount}` === `${am}`}
              onClick={() => dispatchActionAppUpdateState({ donate: { amount: +am } })}
            >
              {`${am}${t('donate_curr')}`}
            </ButtonCash>
          ))}
          <RowStart style={{ width: '10rem', marginTop: '0.8rem' }}>
            <Input
              label={t('donate_other_amount')}
              value={otherAmount}
              number
              onChange={(am) => {
                setError(undefined);
                setOtherAmount(am);
                dispatchActionAppUpdateState({ donate: { amount: +am } });
              }}
            />
          </RowStart>
        </RowSpaceBetween>
        <TextArea
          translator={props.translator}
          label="Комментарий"
          value={props.donate.message}
          onChange={(message) => {
            dispatchActionAppUpdateState({ donate: { message } });
          }}
        />
        {error ? <HintError errors={[error]} /> : null}
        <RowEnd>
          <ButtonMain onClick={sendDonate}>Отправить</ButtonMain>
        </RowEnd>
      </ColumnStart>
      <AdLookIBV numAdsDesktop={2} numAdsMobile={1} />
      <>
        <form target="_blank" method="POST" action="https://yoomoney.ru/quickpay/confirm.xml">
          <input type="hidden" name="receiver" value="410013586911320" />
          <input type="hidden" name="quickpay-form" value="donate" />
          <input type="hidden" name="targets" value="В поддержку сайта «Тайный Санта»" />
          <input type="hidden" name="formcomment" value="В поддержку сайта «Тайный Санта»" />
          <input type="hidden" name="short-dest" value="В поддержку сайта «Тайный Санта»" />
          <input type="hidden" name="paymentType" value={props.donate.method} />
          <input type="hidden" name="comment" value={props.donate.message} />
          <input type="hidden" name="sum" value={props.donate.amount} />
          <input type="hidden" name="successURL" value={`${getFrontendHost()}/donate/thanks`} />
          <button type="submit" ref={submitButtonRef} style={{ display: 'none' }} />
        </form>
      </>
    </ColumnStart>
  );
}

// //action="https://money.yandex.ru/quickpay/confirm.xml">
export function mapStateToProps(state: SantaAppState) {
  return {
    donate: state.donate,
    ...mapStateToPropsBasePage(state),
  };
}

export const DonatePage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(DonatePageComponent),
);
