import * as React from 'react';
import { useEffect, useState } from 'react';

import { SessionObject } from '@lub0v/santa-utils';
import {
  BaseComponentProps,
  Header,
  HeaderSecondary,
  HeaderSecondaryMenu,
  Layout1,
  QuickNotification,
  ToggleMenuButton,
  ModalProps,
} from '@src/components';
import {
  AccountNotifications,
  Box,
  CssProps,
  dispatchActionAppUpdateState,
  loadBoxInfo,
  loadCard,
  loadWard,
  SantaAppState,
} from '@src/state';
import { t } from '@src/translations';
import { HeaderComponent } from '@src/pages/base/HeaderComponent';
import { FooterComponent } from '@src/pages/base/FooterComponent';
import { LoadingBarSmall } from '@src/loading/LoadingComponents';
import { connect } from 'react-redux';
import { BoxCardComponent } from '@src/pages/box/card/BoxCard';
import { ToggleMenuItems } from '@src/pages/box/parts/ToggleMenuItems';
import { OrgMenu } from '@src/pages/box/parts/OrgMenu';
import { BOX_PATH } from '@src/pages/box/boxHelpers';
import { BoxPageRoutes } from '@src/pages/box/BoxPageRoutes';
import { BoxHeaderLeft } from '@src/pages/box/parts/BoxHeaderLeft';
import { UserNotifications } from '@src/pages/UserNotifications';
import { BoxPageLoading } from '@src/pages/box/loading/BoxPageLoading';
import { BoxPageUnauthorizedComponent } from '@src/pages/box/BoxPageUnauthorized';
import { BoxPageNotFoundComponent } from '@src/pages/box/BoxPageNotFound';
import { Route } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';

export interface BoxPageProps extends BaseComponentProps {
  notifications: AccountNotifications;
  join: string;
  translator: any;
  session: SessionObject;
  headerShadow: boolean;
  history?: any;
  loading: boolean;
  showConfetti: boolean;
  css: CssProps;
  urlParams: any;
  box: Box;
  match: any;
  modal: ModalProps;
  lang: string;
}

const getUrlParams = (search: string) => {
  const query = new URLSearchParams(search);
  const urlParams: any = {};
  query.forEach((v, k) => {
    urlParams[k] = v;
  });
  return urlParams;
};

function BoxPageComponent(props: BoxPageProps) {
  const [menuOpened, setMenuOpened] = useState(false);
  useEffect(() => {
    const { match, urlParams } = props;
    const { join = urlParams && urlParams.join ? urlParams.join : props.join } = getUrlParams(
      props.history.location.search,
    );
    const { key } = match.params;
    // const join = urlParams && urlParams.join ? urlParams.join : props.join;
    dispatchActionAppUpdateState({ box: { join, createCard: { input: { join } } } });
    loadCard(key, join);
    loadWard(key);
    loadBoxInfo(key, join);
  }, []);

  if (props.loading) {
    return <BoxPageLoading {...props} />;
  }
  if (props.box.isUnauthorized) {
    return <BoxPageUnauthorizedComponent {...props} />;
  }
  if (props.box.isNotFound) {
    return <BoxPageNotFoundComponent {...props} />;
  }
  const boxInfo = props.box.info;
  if (!boxInfo) {
    return <BoxPageLoading {...props} />;
  }

  const SettingsButton = !boxInfo.isAdmin ? null : (
    <ToggleMenuButton
      type="settings"
      opened={menuOpened}
      onClick={() => setMenuOpened(!menuOpened)}
    />
  );

  const RightItem = props.css.mobile ? (
    SettingsButton
  ) : (
    <ToggleMenuItems button={SettingsButton} {...props} />
  );

  const MobileMenuItems = props.css.mobile ? (
    <ToggleMenuItems {...props} style={{ marginTop: '1.5rem' }} />
  ) : null;

  const closeMenu = () => setMenuOpened(false);

  const { pathname } = props.history.location;

  const isCardFormActive = pathname.endsWith('/card') && props.box.createCard.step > -1;
  const showHeaderSecondary = !isCardFormActive;
  return (
    <>
      <Layout1
        translator={t}
        showFade={menuOpened}
        onFadeClick={closeMenu}
        confetti={
          props.showConfetti ? (
            <ConfettiExplosion
              duration={6000}
              force={0.7}
              particleCount={250}
              particleSize={12}
              width={1800}
              colors={['#fff200', '#ff0000', '#3f44dd', '#4acae5', '#ee6be3']}
            />
          ) : null
        }
        headerFixed
        wide={isCardFormActive}
        header={
          <Header shadow={false} link="/">
            {props.loading ? <LoadingBarSmall /> : <HeaderComponent {...props} />}
          </Header>
        }
        footer={isCardFormActive ? null : <FooterComponent />}
        headerSecondary={
          showHeaderSecondary ? (
            <HeaderSecondary
              shadow={!menuOpened}
              leftItem={<BoxHeaderLeft {...props} />}
              rightItem={RightItem}
            />
          ) : null
        }
        headerSecondaryMenu={
          boxInfo ? (
            <HeaderSecondaryMenu>
              {boxInfo.isAdmin && !props.session.email ? (
                <QuickNotification
                  orange
                  opened
                  buttonText={'box_admin_add_email'}
                  onClick={() => {
                    props.history.push('/account');
                  }}
                >
                  box_admin_did_not_add_email
                </QuickNotification>
              ) : null}

              <QuickNotification
                translator={props.translator}
                autoHide={3000}
                green
                onClick={() => {
                  dispatchActionAppUpdateState({ box: { saved: '' } });
                }}
                opened={!!props.box.saved}
                style={{}}
              >
                {props.box.saved}
              </QuickNotification>
              {boxInfo.isAdmin ? (
                <OrgMenu visible={menuOpened} onClose={closeMenu} {...props} />
              ) : null}
            </HeaderSecondaryMenu>
          ) : null
        }
        notifications={
          <UserNotifications
            {...props.notifications}
            translator={props.translator}
            history={props.history}
          />
        }
        modalProps={props.modal}
      >
        {isCardFormActive ? (
          <Route path={`${BOX_PATH}/card`}>
            <BoxCardComponent {...props} />
          </Route>
        ) : (
          <div style={{ height: '100%' }}>
            <section style={{ height: '100%' }}>
              {MobileMenuItems}
              <BoxPageRoutes {...props} />
            </section>
          </div>
        )}
      </Layout1>
    </>
  );
}

export function mapStateToPropsBoxBasePage(state: SantaAppState) {
  return {
    showConfetti: state.showConfetti,
    session: state.session,
    notifications: state.notifications,
    loading: state.loading,
    css: state.css,
    urlParams: state.urlParams,
    box: state.box,
    key: state.box.info ? state.box.info.box.key : null,
    modal: state.modal,
    lang: state.language,
  };
}

export const BoxPage = connect(mapStateToPropsBoxBasePage)(BoxPageComponent);
