import * as React from 'react';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { RandomizerCardInfo, RandomizerGetCardsOutput } from '@lub0v/santa-utils';
import {
  ButtonMain,
  ColumnStart,
  ErrorForm,
  GridColumn,
  GridContainer,
  SantaLink,
  TextMedium,
  UserCardMini,
  UserCardMiniSimple,
  Heading,
  Tip,
} from '@src/components';
import { santaClient } from '@src/state/client';
import { LoadingBarBig } from '@src/loading/LoadingComponents';
import { AdLook } from '@src/components/AdLook';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface RandomizerResultPageProps extends BasePageProps {
  randomizerCards: RandomizerCardInfo[];
  computedMatch: any;
  match: any;
}

function RandomizerResultPageComponent(props: RandomizerResultPageProps) {
  const [openedCards, setOpenedCards] = useState([]);
  const [pictures, setPictures] = useState({});
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const { match } = props;
    const { uuid } = match.params;
    santaClient
      .getRandomizedCards({ uuid })
      .then((r: RandomizerGetCardsOutput) => {
        dispatchActionAppUpdateState({ randomizerCards: r.data });
        const pics = {};
        const cards = r.data;
        for (let i = 0; i < cards.length; i += 1) {
          pics[cards[i].email] = cards[i].picture;
        }
        setPictures(pics);
      })
      .catch(() => {
        setError('randomizer_wrong_uuid');
      });
  }, []);

  if (error) {
    return (
      <ErrorForm errors={[error]} translator={props.translator}>
        <SantaLink to="/">
          <ButtonMain>back_to_home</ButtonMain>
        </SantaLink>
      </ErrorForm>
    );
  }

  if (!props.randomizerCards.length) {
    const bars = Array(4).fill(0);
    return (
      <ColumnStart translator={props.translator} style={{ marginBottom: '3rem' }}>
        <Heading translator={props.translator}>Результаты быстрой жеребьевки</Heading>
        <GridContainer spacing={2}>
          <GridColumn xs={12} sm={12} md={12} lg={4}>
            <LoadingBarBig />
          </GridColumn>
          <GridColumn xs={12} sm={12} md={12} lg={8}>
            <GridContainer spacing={2}>
              {bars.map((b, i) => (
                <React.Fragment key={i}>
                  <GridColumn xs={12} sm={6} md={6}>
                    <LoadingBarBig />
                  </GridColumn>
                  <GridColumn xs={12} sm={6} md={6}>
                    <LoadingBarBig />
                  </GridColumn>
                </React.Fragment>
              ))}
            </GridContainer>
          </GridColumn>
        </GridContainer>
      </ColumnStart>
    );
  }

  return (
    <ColumnStart translator={props.translator} style={{ marginBottom: '3rem' }}>
      <Heading translator={props.translator}>Результаты быстрой жеребьевки</Heading>
      <GridContainer spacing={props.css.mobile ? 2 : 3}>
        <GridColumn xs={12} sm={12} md={12} lg={4}>
          <Tip icon="info">draw_santas_hint</Tip>
          <AdLookIBV numAdsDesktop={2} numAdsMobile={1} row={false} />
        </GridColumn>
        <GridColumn
          xs={12}
          sm={12}
          md={12}
          lg={8}
          style={{ marginTop: props.css.mobile ? 0 : '0.6rem' }}
        >
          <GridContainer spacing={props.css.mobile ? 2 : 3}>
            {props.randomizerCards.map((c: RandomizerCardInfo) => (
              <React.Fragment key={c.email}>
                <GridColumn xs={12} sm={6} md={6}>
                  <UserCardMini picture={c.picture} verified name={c.username} line />
                </GridColumn>
                <GridColumn xs={12} sm={6} md={6}>
                  {openedCards.includes(c.wardEmail) ? (
                    <UserCardMini picture={pictures[c.wardEmail]} verified name={c.wardUsername} />
                  ) : (
                    <UserCardMiniSimple>
                      <TextMedium
                        translator={props.translator}
                        grey
                        underline
                        onClick={() => {
                          setOpenedCards([...openedCards, c.wardEmail]);
                        }}
                      >
                        randomizer_open_ward
                      </TextMedium>
                    </UserCardMiniSimple>
                  )}
                </GridColumn>
              </React.Fragment>
            ))}
          </GridContainer>
        </GridColumn>
      </GridContainer>
    </ColumnStart>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    randomizerCards: state.randomizerCards,
    urlParams: state.urlParams,
  };
}

export const RandomizerResultPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(RandomizerResultPageComponent),
);
