import * as React from 'react';
import { useState } from 'react';
import {
  ButtonService,
  ColumnStart,
  SantaLink,
  SimpleLink,
  Table,
  Tbody,
  Td,
  TextMedium,
  Th,
  Thead,
  Tip,
  Tr,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { BoxInfo, CardInfo } from '@lub0v/santa-utils';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { reachGoalYaMetrika } from '@src/utils/metrika';

type ParticipantsProps = {
  translator: any;
  boxInfo: BoxInfo;
  cards: CardInfo[];
};

function safeVal(v: string) {
  if (v) {
    return `"${v.replace(/\n/g, '\\n').replace(/'/g, "''").replace(/"/g, '""')}"`;
  }
  return `""`;
}

function getColumns(boxInfo: BoxInfo) {
  const drawActive = true; // boxInfo.isActiveDraw;
  const columns = [
    t(TRANSLATIONS.box_table_header_number),
    t(TRANSLATIONS.box_table_header_name),
    ...(drawActive
      ? [
          t(TRANSLATIONS.box_table_know_ward),
          t(TRANSLATIONS.box_table_header_ward),
          t(TRANSLATIONS.box_table_header_gift_sent),
          t(TRANSLATIONS.box_table_header_santa),
          t(TRANSLATIONS.box_table_header_gift_received),
        ]
      : []),
    t(TRANSLATIONS.box_table_header_email),
    t(TRANSLATIONS.box_table_header_phone),
  ];
  if (boxInfo.box.usePost) {
    columns.push(t(TRANSLATIONS.box_table_header_postal_address));
  }
  if (boxInfo.box.useWish) {
    columns.push(t(TRANSLATIONS.box_table_header_wish));
  }
  return columns;
}

function createTableData(boxInfo: BoxInfo, cards: CardInfo[]) {
  const columns = getColumns(boxInfo);
  const drawActive = true; // boxInfo.isActiveDraw;
  let report = `${columns.join(',')}\r\n`;
  for (let i = 0; i < cards.length; i += 1) {
    const card = cards[i];
    const cols = [];
    const ward = cards.find((cc) => cc.id === card.wardId);
    const santa = cards.find((cc) => cc.wardId === card.id);
    const hasWard = !!ward;
    const hasSanta = !!santa;

    cols.push(
      i + 1,
      safeVal(card.username),
      ...(drawActive
        ? [
            card.isWardKnown ? t(TRANSLATIONS.yes) : t(TRANSLATIONS.no),
            hasWard ? safeVal(ward.username) : t(TRANSLATIONS.box_table_no_ward),
            hasWard
              ? ward.isGiftOnItsWay
                ? t(TRANSLATIONS.yes)
                : t(TRANSLATIONS.no)
              : t(TRANSLATIONS.box_table_no_ward),
            hasSanta ? safeVal(santa.username) : t(TRANSLATIONS.box_table_no_santa),
            card.isGiftReceived ? t(TRANSLATIONS.yes) : t(TRANSLATIONS.no),
          ]
        : []),
      card.email,
      card.phone,
    );

    if (boxInfo.box.usePost) {
      const hasAddress = card.postalAddress && card.postalCode && card.postalRecipient;
      if (hasAddress) {
        cols.push(
          safeVal(
            `${card.postalAddress}, ${card.postalCode}; ${t(TRANSLATIONS.recipient)}: ${
              card.postalRecipient
            }`,
          ),
        );
      } else {
        cols.push('');
      }
    }
    if (boxInfo.box.useWish) {
      cols.push(safeVal(card.wish));
    }

    report += `${cols.join(',')}\r\n`;
  }
  return report;
}

export const ParticipantsTableComponent = (props: ParticipantsProps) => {
  const [cardState, setCardState] = useState({});
  const columns = getColumns(props.boxInfo);
  if (!props.boxInfo.box.isArchived) {
    columns.push('');
  }

  const setCardSt = (card: CardInfo, state: any) => {
    const st = { ...cardState };
    if (!st[card.id]) {
      st[card.id] = state;
    } else {
      st[card.id] = { ...st[card.id], ...state };
    }
    setCardState(st);
  };

  const showWard = (card: CardInfo) => {
    setCardSt(card, { showWard: true });
  };

  const showSanta = (card: CardInfo) => {
    setCardSt(card, { showSanta: true });
  };

  const showWish = (card: CardInfo) => {
    setCardSt(card, { showWish: true });
  };

  const cards = props.cards.length > 150 ? [...props.cards].slice(0, 150) : props.cards;

  const report = createTableData(props.boxInfo, props.cards);
  const drawActive = true; // props.boxInfo.isActiveDraw;
  return (
    <ColumnStart>
      <Tip
        blueberry
        noIcon
        translator={props.translator}
        button={
          <SimpleLink
            onClick={() => {
              reachGoalYaMetrika('download_table');
            }}
            download={`${safeVal(props.boxInfo.box.name.replace(/\s/g, '_'))}.csv`}
            href={`data:text/csv;charset=utf-8,${encodeURIComponent(report)}`}
          >
            <ButtonService translator={props.translator}>box_table_download</ButtonService>
          </SimpleLink>
        }
      >
        {t(TRANSLATIONS.box_table_hint, {
          drawActive: props.boxInfo.isActiveDraw,
          num: props.cards.length,
        })}
      </Tip>
      <Table translator={props.translator}>
        <Thead>
          <Tr>
            {columns.map((c) => (
              <Th key={c}>{c}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {cards.map((card, index: number) => {
            const ward = props.cards.find((cc) => cc.id === card.wardId);
            const santa = props.cards.find((cc) => cc.wardId === card.id);
            const hasWard = !!ward;
            const hasSanta = !!santa;
            const hasAddress = card.postalAddress && card.postalCode && card.postalRecipient;
            const postalAddress = t(TRANSLATIONS.postal_address_no_break, {
              address: card.postalAddress,
              code: card.postalCode,
              recipient: card.postalRecipient,
            });
            return (
              <Tr key={card.id}>
                <Td>{index + 1}</Td>
                <Td maxWidth={'20rem'}>{card.username}</Td>
                {drawActive ? (
                  <>
                    <Td maxWidth={'20rem'}>
                      <TextMedium>{card.isWardKnown ? 'yes' : 'no'}</TextMedium>
                    </Td>
                    <Td maxWidth={'20rem'}>
                      {hasWard ? (
                        cardState[card.id] && cardState[card.id].showWard ? (
                          <TextMedium>{ward.username}</TextMedium>
                        ) : (
                          <TextMedium
                            orange
                            underline
                            onClick={() => {
                              showWard(card);
                            }}
                          >
                            box_table_show
                          </TextMedium>
                        )
                      ) : (
                        <TextMedium grey>box_table_no_ward</TextMedium>
                      )}
                    </Td>
                    <Td maxWidth={'20rem'}>
                      {hasWard ? (
                        <TextMedium>{ward.isGiftOnItsWay ? 'yes' : 'no'}</TextMedium>
                      ) : (
                        <TextMedium grey>box_table_no_ward</TextMedium>
                      )}
                    </Td>
                    <Td maxWidth={'20rem'}>
                      {hasSanta ? (
                        cardState[card.id] && cardState[card.id].showSanta ? (
                          <TextMedium>{santa.username}</TextMedium>
                        ) : (
                          <TextMedium
                            orange
                            underline
                            onClick={() => {
                              showSanta(card);
                            }}
                          >
                            box_table_show
                          </TextMedium>
                        )
                      ) : (
                        <TextMedium grey>box_table_no_santa</TextMedium>
                      )}
                    </Td>
                    <Td maxWidth={'20rem'}>
                      {hasSanta ? (
                        <TextMedium>{card.isGiftReceived ? 'yes' : 'no'}</TextMedium>
                      ) : (
                        <TextMedium grey>box_table_no_santa</TextMedium>
                      )}
                    </Td>
                  </>
                ) : null}

                <Td maxWidth={'20rem'}>
                  {card.email && card.email.length > 1 ? (
                    <TextMedium>{card.email}</TextMedium>
                  ) : (
                    <TextMedium grey>box_table_no_email</TextMedium>
                  )}
                </Td>
                <Td maxWidth={'20rem'}>
                  {card.phone && card.phone.length > 1 ? (
                    <TextMedium>{card.phone}</TextMedium>
                  ) : (
                    <TextMedium grey>box_table_no_phone</TextMedium>
                  )}
                </Td>
                {props.boxInfo.box.usePost ? (
                  <Td maxWidth={'20rem'}>
                    {hasAddress ? (
                      postalAddress
                    ) : (
                      <TextMedium grey>box_table_no_postal_address</TextMedium>
                    )}
                  </Td>
                ) : null}
                {props.boxInfo.box.useWish ? (
                  <Td maxWidth={'20rem'}>
                    {card.wish ? (
                      cardState[card.id] && cardState[card.id].showWish ? (
                        <TextMedium>{card.wish}</TextMedium>
                      ) : (
                        <TextMedium
                          orange
                          underline
                          onClick={() => {
                            showWish(card);
                          }}
                        >
                          box_table_show
                        </TextMedium>
                      )
                    ) : (
                      <TextMedium grey>box_table_no_wish</TextMedium>
                    )}
                  </Td>
                ) : null}
                {props.boxInfo.box.isArchived ? null : (
                  <Td maxWidth={'20rem'}>
                    <SantaLink
                      to={
                        card.isYou ? getBoxPath('/card/edit') : getBoxPath(`/card/${card.id}/edit`)
                      }
                    >
                      <TextMedium orange underline translator={props.translator}>
                        box_table_edit
                      </TextMedium>
                    </SantaLink>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </ColumnStart>
  );
};
