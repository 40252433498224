import * as React from 'react';

import { _, link, linkSeparateTab, linkSmall } from '@src/translations/utils.translations';

export const LOGIN_TRANSLATIONS = {
  language_ru: _('RU', 'RU'),
  language_en: _('EN', 'EN'),
  login_and_registration: _('Вход и регистрация', 'Register and login'),
  login_with_google: _('Войти с Google', 'Sign in with Google'),
  login_with_vk: _('Войти с Вконтакте', 'Sign in with Vkontakte'),
  login_with_fb: _('Войти с Facebook', 'Sign in with Facebook'),
  login_with_tg: _('Войти с Телеграм', 'Sign in with Telegram'),
  register_with_vk: _('Регистрация с Вконтакте', 'Register with Vkontakte'),
  register_with_google: _('Регистрация с Google', 'Register with Google'),
  register_with_fb: _('Регистрация с Facebook', 'Register with Facebook'),
  register_with_tg: _('Регистрация с Телеграм', 'Register with Telegram'),
  login: _('Войти', 'Sign in'),
  we_will_send_register_link: _(
    'Мы отправим ссылку для регистрации на указанный адрес',
    'We will send a registration link to the provided address',
  ),
  we_will_send_recover_link: _(
    'Мы отправим ссылку с временным паролем на вашу почту',
    'We will send a link with a temporary password to your email',
  ),
  log_in_to_site: _('Войти на сайт', 'Sign in'),
  site_login: _('Вход на сайт', 'Sign in'),
  already_registered_q: _('Уже зарегистрированы?', 'Already registered?'),
  no_account_q: _('Еще нет аккаунта?', 'Do not have account yet?'),
  registration: _('Регистрация', 'Registration'),
  register: _('Зарегистрироваться', 'Register'),
  register_email_sent: _('Письмо отправлено!', 'Email sent!'),
  register_email_sent_hint: _(
    'Проверьте свой почтовый ящик. Вероятно, оно уже там :)',
    "Check your mailbox. It's probably already there :)",
  ),
  forgot_password_q: _('Забыли пароль?', 'Forgot password?'),
  policy_agreement_hint: _(
    <>
      Входя на сайт, вы даете согласие на{' '}
      {linkSeparateTab('/policy', 'обработку персональных данных.')}
    </>,
    <>
      By accessing the site, you agree to{' '}
      {linkSeparateTab('/policy', 'processing of personal data.')}
    </>,
  ),
  register_policy_agreement_hint: _(
    <>
      Регистрируясь, вы даете согласие на{' '}
      {linkSeparateTab('/policy', 'обработку персональных данных.')}
    </>,
    <>
      By registering, you consent to the{' '}
      {linkSeparateTab('/policy', 'processing of personal data.')}
    </>,
  ),
  recover_password: _('Восстановление доступа', 'Recover password'),
  recover_password_button: _('Восстановить пароль', 'Recover password'),
  recover_email_sent: _('Письмо отправлено!', 'Email sent!'),
  recover_email_sent_hint: _(
    (email) => (
      <>
        Мы отправили письмо со ссылкой для смены пароля на адрес <b>{email}</b>. Перейдите по ссылке
        из письма, чтобы задать новый пароль. {link('/resend', ' Не пришло письмо?')}
      </>
    ),
    (email) => (
      <>
        We have sent an email with a link to change your password to <b>{email}</b>. Follow the link
        from the email to set a new password. {link('/resend', " Didn't receive the email?")}
      </>
    ),
  ),
  reset_password: _('Смена пароля', 'Create new password'),
  reset_password_button: _('Продолжить', 'Continue'),
  reset_password_done: _('Пароль изменен!', 'Password has been reset!'),
  reset_password_done_hint: _(
    'Пароль был успешно изменен. Войди на сайт с новым паролем.',
    'The password has been successfully changed. Log in to the site with the new password.',
  ),
  reset_password_try_again: _('Восстановить доступ', 'Recover access'),
  new_password: _('Новый пароль', 'New password'),
  confirm_password: _('Повторите пароль', 'Confirm password'),
};
