import * as React from 'react';
import { NotificationInfo } from '@lub0v/santa-utils';
import { TextMedium, NotificationItem, NotificationsPanel } from '@src/components';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState } from '@src/state';

export function UserNotifications(props: {
  translator: any;
  history: any;
  opened: boolean;
  notifications: NotificationInfo[];
}) {
  if (!props.opened) {
    return null;
  }
  const { notifications } = props;
  if (!notifications.length) {
    return (
      <NotificationsPanel
        title={
          <TextMedium grey translator={props.translator}>
            no_notifications
          </TextMedium>
        }
      />
    );
  }

  const markAsRead = async (id: number, close = false) => {
    const res = await santaClient.readAccountNotifications({ ids: [id] });
    if (res.ok) {
      dispatchActionAppUpdateState({
        notifications: { notifications: res.data, opened: close ? false : !!res.data.length },
      });
    }
  };

  const markAllAsRead = async () => {
    const res = await santaClient.readAccountNotifications({ ids: notifications.map((n) => n.id) });
    if (res.ok) {
      dispatchActionAppUpdateState({
        notifications: { notifications: res.data, opened: !!res.data.length },
      });
    }
  };

  const unreadNotifications = notifications.filter((n) => !n.is_viewed);
  const title = unreadNotifications.length ? (
    <TextMedium translator={props.translator} orange onClick={markAllAsRead}>
      mark_as_read
    </TextMedium>
  ) : (
    <TextMedium translator={props.translator} grey>
      all_notifications_read
    </TextMedium>
  );

  return (
    <NotificationsPanel title={title}>
      {notifications.map((n) => (
        <NotificationItem
          key={n.id}
          button={n.button}
          green={n.green}
          blue={n.blue}
          yellow={n.yellow}
          onClose={
            n.is_viewed
              ? null
              : () => {
                  markAsRead(n.id);
                }
          }
          onButtonClick={() => {
            markAsRead(n.id, true);
            props.history.push(n.url);
          }}
          style={{ opacity: n.is_viewed ? 0.35 : 1 }}
        >
          {n.message}
        </NotificationItem>
      ))}
    </NotificationsPanel>
  );
}
