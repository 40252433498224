import * as React from 'react';
import { dispatchActionAppUpdateState } from '@src/state';
import { FormCard, TextArea, Tip } from '@lub0v/ui-components';
import { validateField } from '@src/utils/validators';
import { CreateCardProps, createCardStepTitle } from '@src/pages/box/card/CreateCardTypes';
import { t, TRANSLATIONS } from '@src/translations';
import { useEffect } from 'react';

export const CreateCardStepWish = (props: CreateCardProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FormCard
      title="create_card_step_wish_title"
      translator={props.translator}
      step={createCardStepTitle(props)}
      onSubmit={props.goNext}
      onBack={props.goBack}
      error={props.error}
    >
      <Tip icon="info">
        create_card_step_wish_hint
        {props.box.info.box.useCashLimit ? (
          <>
            <br />
            <br />
            {t(TRANSLATIONS.create_card_step_wish_hint_cash_limit)}
            <b>
              {t(TRANSLATIONS.currency[props.box.info.box.cashLimitCurrency].num, {
                count: props.box.info.box.cashLimit,
                count1: props.box.info.box.cashLimit,
              })}
              .
            </b>
            {t(TRANSLATIONS.create_card_step_wish_hint_cash_limit_2)}
          </>
        ) : null}
      </Tip>
      <TextArea
        label="create_card_step_wish"
        value={props.box.createCard.input.wish}
        onChange={(wish) =>
          dispatchActionAppUpdateState({ box: { createCard: { input: { wish } } } })
        }
        validators={[validateField(props.box.createCard.input, 'wish')]}
      />
    </FormCard>
  );
};
