import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const FOOTER_TRANSLATIONS = {
  footer_quick_draw: _('Быстрая жеребьевка', 'Quick draw'),
  footer_faq: _('Частые вопросы', 'FAQ'),
  footer_gift_ideas: _('Идеи подарков', 'Gift ideas'),
  footer_support: _('Помочь проекту', 'Support the project'),
  footer_no_email: _('Не пришло письмо?', "Didn't receive the email?"),
  footer_feedback: _('Обратная связь', 'Feedback'),
  footer_policy: _('Политика конфиденциальности', 'Policy'),
};
