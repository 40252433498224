import * as React from 'react';
import { SantaAppState } from '@src/state';
import { Animal, ANIMAL_TYPES, PicturePicker } from '@src/components';
import { connect } from 'react-redux';
import { useRef, useState } from 'react';
import { FileUpload } from '@src/components/FileUpload';

type Props = {
  selectedPicture: string;
  disabled: boolean;
  onPictureChange: (pic) => void;
  onCustomUploaded: (url) => void;
  image?: string;
};

function CardPicturePickerComponent({
  selectedPicture,
  image,
  disabled,
  onPictureChange,
  onCustomUploaded,
}: Props) {
  const [loaderId, setLoaderId] = useState(undefined);
  const [pics, setPics] = useState(null);
  const fileUpload = useRef(null);
  const customType = 'custom_avatar';

  if (!pics) {
    let p = [...ANIMAL_TYPES];
    const ind = p.indexOf(selectedPicture);
    p.splice(ind, 1);
    p = [selectedPicture, ...p];
    setPics(p);
    return null;
  }

  const onFileStateChanged = async (state, file) => {
    if (state === 'uploaded') {
      onCustomUploaded(file.url);
      setTimeout(() => {
        setLoaderId(null);
      }, 500);
    }
  };

  return (
    <>
      <PicturePicker
        disabled={disabled}
        noWrap
        selected={selectedPicture}
        onChange={async (picture) => {
          const pic = typeof picture !== 'string' ? customType : picture;
          onPictureChange(pic);
          if (pic === customType) {
            setTimeout(() => {
              setLoaderId(customType);
            }, 500);
            fileUpload.current.open();
          }
        }}
        loader={loaderId}
      >
        {pics.map((type) => {
          if (type == customType && image) {
            return <img key={type} alt={customType} style={{ borderRadius: '100%' }} src={image} />;
          }
          return <Animal key={type} type={type} />;
        })}
      </PicturePicker>
      <FileUpload
        ref={fileUpload}
        onChange={onFileStateChanged}
        maxSize={1024 * 250}
        fileLargeMsg="modal_large_file_message_250kb"
        accept="image/*"
      />
    </>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {};
}

export const CardPicturePicker = connect(mapStateToProps)(CardPicturePickerComponent);
