import * as React from 'react';

import ContentLoader from 'react-content-loader';
import { CssProps, SantaAppState } from '@src/state';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { BaseComponentProps } from '@lub0v/ui-components';
import { connect } from 'react-redux';

interface Props extends BaseComponentProps {
  css: CssProps;
  width?: number;
}

function LoadingBarMediumInternal(props: Props) {
  const fs = props.css.fontSize;
  return (
    <ContentLoader
      viewBox={`0 0 100 ${fs * 4}`}
      width={props.width ?? '100%'}
      height={fs * 4}
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="40" />
    </ContentLoader>
  );
}

function LoadingBarBigInternal(props: Props) {
  const fs = props.css.fontSize;
  return (
    <ContentLoader
      viewBox={`0 0 100 ${fs * 4}`}
      width={props.width ?? '100%'}
      height={fs * 6}
      preserveAspectRatio="none"
      style={{ marginBottom: '1rem' }}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="60" />
    </ContentLoader>
  );
}

function LoadingCardBigInternal(props: Props) {
  const fs = props.css.fontSize;
  return (
    <ContentLoader
      viewBox={`0 0 100 ${fs * 4}`}
      width={props.width ?? '100%'}
      height={fs * 12}
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="120" />
    </ContentLoader>
  );
}

function LoadingCardSmallInternal(props: Props) {
  const fs = props.css.fontSize;
  return (
    <ContentLoader
      viewBox={`0 0 100 ${fs * 4}`}
      width={props.width ?? '100%'}
      height={fs * 6}
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="60" />
    </ContentLoader>
  );
}

function LoadingCardSuperBigInternal(props: Props) {
  const fs = props.css.fontSize;
  return (
    <ContentLoader
      viewBox={`0 0 100 ${fs * 4}`}
      width={props.width ?? '100%'}
      height={fs * 30}
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="400" />
    </ContentLoader>
  );
}

function LoadingBarSmallInternal(props: Props) {
  const fs = props.css.fontSize;
  return (
    <ContentLoader
      viewBox={`0 0 100 ${fs * 2}`}
      width={props.width ?? '100%'}
      height={fs * 2}
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100" height="20" />
    </ContentLoader>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    css: state.css,
  };
}

export const LoadingBarMedium = connect(mapStateToProps)(LoadingBarMediumInternal);
export const LoadingBarBig = connect(mapStateToProps)(LoadingBarBigInternal);
export const LoadingCardBig = connect(mapStateToProps)(LoadingCardBigInternal);
export const LoadingCardSmall = connect(mapStateToProps)(LoadingCardSmallInternal);
export const LoadingCardSuperBig = connect(mapStateToProps)(LoadingCardSuperBigInternal);
export const LoadingBarSmall = connect(mapStateToProps)(LoadingBarSmallInternal);

export const LoadingPicture = connect(mapStateToProps)((props: Props) => {
  const fs = props.css.fontSize;
  const w = props.css.mobile ? fs * 4 : fs * 5;
  return (
    <ContentLoader viewBox={`0 0 ${w} ${w}`} width={w} height={w} preserveAspectRatio="none">
      <circle cx={w / 2} cy={w / 2} r={w / 2} />
    </ContentLoader>
  );
});
