import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage } from '@src/pages/withBasePage';
import {
  actionReloadSession,
  dispatchActionAppUpdateState,
  SantaAppState,
  store,
  UserAccountInfoState,
} from '@src/state';
import {
  ButtonService,
  ColumnStart,
  HintError,
  Input,
  QuickNotification,
  RowStart,
  Tip,
  withBase,
} from '@src/components';
import { validateField } from '@src/utils/validators';
import { santaClient } from '@src/state/client';
import { t, TRANSLATIONS } from '@src/translations';

interface AccountPageProps extends BasePageProps {
  account: UserAccountInfoState;
}

function EmailFieldComponent(props: AccountPageProps) {
  const [error, setError] = useState(undefined);
  const [saved, setSaved] = useState(false);

  const saveEmail = async () => {
    const res = await santaClient.accountUpdateEmail(props.account.emailInput);
    if (res.ok) {
      const acc = res;
      dispatchActionAppUpdateState({
        account: {
          account: acc.data,
          usernameInput: { username: acc.data.username },
          emailInput: { email: acc.data.email },
        },
      });
      store.dispatch(actionReloadSession());
      setSaved(true);
    } else {
      setError(res.error.message);
    }
  };
  const unverifiedTip = props.account.account.verifyEmail ? (
    <Tip icon="info">
      {t(TRANSLATIONS.account_verify_email_link_sent, { email: props.account.account.verifyEmail })}
    </Tip>
  ) : null;
  return (
    <>
      <QuickNotification
        translator={props.translator}
        autoHide={2000}
        green
        onClick={() => {
          setSaved(false);
        }}
        opened={saved && !props.account.account.verifyEmail}
        style={{ top: props.css.mobile ? '-5.5rem' : '-3rem' }}
      >
        account_email_saved
      </QuickNotification>
      <ColumnStart translator={props.translator}>
        {unverifiedTip}
        <RowStart translator={props.translator}>
          <Input
            label="your_email"
            value={props.account.emailInput.email}
            validators={[validateField(props.account.emailInput, 'email')]}
            onChange={(email) => {
              dispatchActionAppUpdateState({ account: { emailInput: { email } } });
            }}
          />
          <ButtonService
            disabled={props.account.account.email === props.account.emailInput.email}
            onClick={saveEmail}
            style={{ marginLeft: '0.5rem' }}
          >
            save
          </ButtonService>
        </RowStart>
        {error ? <HintError center errors={[error]} /> : null}
      </ColumnStart>
    </>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    account: state.account,
  };
}

export const EmailField = withBase(connect(mapStateToProps)(EmailFieldComponent));
