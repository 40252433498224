import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import { CreateOtherCardInput } from '@lub0v/santa-utils';
import {
  ButtonService,
  Hint,
  RowEnd,
  TextArea,
  TextSecondaryMedium,
  validators,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { useState } from 'react';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState } from '@src/state';

export function EditOtherCardWishHint(props: BoxPageProps) {
  const [pokeMessage, setPokeMessage] = useState('');
  const cardInput: CreateOtherCardInput = props.box.editOtherCard;
  const { box } = props.box.info;

  if (!cardInput || box.isArchived) {
    return null;
  }

  if (!cardInput.isVerified) {
    return (
      <Hint translator={props.translator}>edit_other_card_wish_does_not_exist_not_verified</Hint>
    );
  }

  const sendPoke = async () => {
    const res = await santaClient.aminPoke({
      key: box.key,
      id: cardInput.id,
      message: pokeMessage,
    });
    if (res.ok) {
      setPokeMessage('');
      dispatchActionAppUpdateState({ box: { saved: 'admin_poke_sent' } });
    }
  };

  const hintText = t(
    TRANSLATIONS[
      cardInput.wish ? 'edit_other_card_wish_exists' : 'edit_other_card_wish_does_not_exist'
    ],
  );
  const hintButton = t(
    TRANSLATIONS[
      cardInput.wish ? 'edit_other_card_wish_ask_to_update' : 'edit_other_card_wish_ask_to_add'
    ],
  );
  const adminPokeMessage = t(
    TRANSLATIONS[
      cardInput.wish ? 'edit_other_card_ask_to_update_wish' : 'edit_other_card_ask_to_add_wish'
    ],
  );

  return (
    <>
      <Hint translator={props.translator}>
        {hintText}{' '}
        {
          <TextSecondaryMedium
            translator={props.translator}
            orange
            underline
            onClick={() => {
              setPokeMessage(pokeMessage ? '' : adminPokeMessage);
            }}
          >
            {hintButton}
          </TextSecondaryMedium>
        }
      </Hint>
      {pokeMessage ? (
        <>
          <TextArea
            translator={props.translator}
            label="edit_other_card_poke_title"
            value={pokeMessage}
            onChange={setPokeMessage}
            validators={[validators.required()]}
          />
          <RowEnd translator={props.translator}>
            <ButtonService style={{ marginRight: '0' }} onClick={sendPoke}>
              edit_other_card_poke_button
            </ButtonService>
          </RowEnd>
        </>
      ) : null}
    </>
  );
}
