import * as React from 'react';

import { BoxPageProps } from '@src/pages/box/BoxPage';
import { CardContainer, ColumnStart, BoxPic, PictureNotice } from '@src/components';

export function NoticeFutureChatWithSanta(props: BoxPageProps) {
  return (
    <ColumnStart style={{ height: '100%' }}>
      <CardContainer>
        <PictureNotice
          translator={props.translator}
          title="notice_its_chat_with_santa_title"
          hint={
            <>
              notice_its_chat_with_santa_hint
              <br />
              notice_its_future_chat_with_santa_hint
            </>
          }
        >
          <BoxPic type="santa" />
        </PictureNotice>
      </CardContainer>
    </ColumnStart>
  );
}
