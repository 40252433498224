import { BoxPageProps } from '@src/pages/box/BoxPage';
import { t, TRANSLATIONS } from '@src/translations';
import {
  colors,
  ColumnCenter,
  MyCard,
  MyCardAction,
  PictureNotice,
  RowCenter,
  TextWish,
} from '@src/components';
import * as React from 'react';
import { useState } from 'react';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState, dispatchActionModal, loadBoxCards } from '@src/state';
import { MessagesInfoOutput } from '@lub0v/santa-utils';
import { ChatWithWard } from '@src/pages/box/ward/ChatWithWard';
import { BoxberryAd } from '@src/pages/box/ward/BoxberryAd';
import { isDotCom } from '@src/host';

export function WardCardComponent(props: BoxPageProps) {
  const [showAddress, setShowAddress] = useState(false);
  const [confirmGiftSent, setConfirmGiftSent] = useState(false);
  const [chatOpened, setChatOpened] = useState(false);

  const { ward } = props.box;
  const { key } = props.box.info.box;

  const wardWish = ward.wish;

  const { useWish, usePost } = props.box.info.box;
  const hasAddress = ward.postalAddress && ward.postalCode && ward.postalRecipient;
  const hasPhone = ward.phone;
  const cardFilled = ward.wish || hasPhone || hasAddress || !usePost;

  const openChatActionComponent = (
    <MyCardAction
      icon={'message'}
      onClick={() => {
        setChatOpened(true);
      }}
      translator={props.translator}
    >
      card_write_to_ward
    </MyCardAction>
  );

  const openChatAction = isDotCom() && !props.css.mobile ? null : openChatActionComponent;

  const showAddressAction = (
    <MyCardAction icon={'home'} onClick={() => setShowAddress(true)}>
      {t(TRANSLATIONS.card_show_contacts)}
    </MyCardAction>
  );

  const isGiftSent = props.box.wardMessages
    ? !!props.box.wardMessages.find((m) => m.active && m.isGiftSent && m.isYou)
    : false;

  const giftAction = isGiftSent ? (
    <MyCardAction icon={'gift'} disabled translator={props.translator}>
      card_you_sent_gift
    </MyCardAction>
  ) : (
    <MyCardAction icon={'gift'} onClick={() => setGiftSent(true)} translator={props.translator}>
      card_i_sent_gift
    </MyCardAction>
  );

  const setMessages = (r: MessagesInfoOutput) => {
    if (r.ok) {
      dispatchActionAppUpdateState({ box: { santaMessages: r.data } });
    }
  };

  const setGiftSent = async (confirm: boolean) => {
    setConfirmGiftSent(confirm);

    dispatchActionModal({
      opened: confirm,
      title: 'modal_confirm_gift_sent_title',
      message: 'modal_confirm_gift_sent_message',
      buttonYes: 'modal_confirm_gift_sent_button_yes',
      buttonNo: 'modal_cancel',
      loading: false,
      onCancel: () => {
        setGiftSent(false);
      },
      onSubmit: async () => {
        const r = await santaClient.writeToWard({
          key,
          message: t(TRANSLATIONS.ward_chat_gift_sent_message),
          isGiftSent: true,
          isAutomatic: true,
        });
        if (r.ok) {
          setMessages(r);
          await loadBoxCards(props.box.info.box.key);
          setGiftSent(false);
          setChatOpened(true);
        }
      },
    });
  };

  if (chatOpened) {
    return (
      <ChatWithWard
        {...props}
        onClose={() => {
          setChatOpened(false);
        }}
      />
    );
  }

  if (!cardFilled) {
    return (
      <MyCard
        translator={props.translator}
        title="ward_card_title"
        picture={ward.picture}
        avatar={ward.avatar}
        name={ward.username}
        buttons={<>{openChatAction}</>}
      >
        <PictureNotice title="ward_card_not_filled_title" hint="ward_card_not_filled_hint" />
      </MyCard>
    );
  }

  if (showAddress) {
    const phoneNumber = hasPhone ? (
      <>
        <RowCenter style={{ marginBottom: '1rem' }}>
          <TextWish grey>Тел.:&nbsp;</TextWish>
          <TextWish>{ward.phone}</TextWish>
        </RowCenter>
      </>
    ) : null;

    const postalAddress = usePost ? (
      hasAddress ? (
        <>
          <ColumnCenter style={{ marginBottom: '0.5rem' }}>
            <TextWish style={{ marginBottom: '0.5rem' }}>
              {ward.postalAddress}, {ward.postalCode}
            </TextWish>
            <TextWish>
              <span style={{ color: colors.text.secondary }}>Получатель:&nbsp;</span>
              {ward.postalRecipient}
            </TextWish>
          </ColumnCenter>
        </>
      ) : (
        <>
          <ColumnCenter style={{ marginBottom: '0.5rem' }}>
            {t(TRANSLATIONS.ward_ask_to_add_address)}
          </ColumnCenter>
        </>
      )
    ) : null;

    let wish;
    if (hasPhone && hasAddress) {
      wish = (
        <>
          {postalAddress}
          {phoneNumber}
        </>
      );
    } else if (hasPhone) {
      wish = phoneNumber;
    } else if (hasAddress) {
      wish = postalAddress;
    } else {
      wish = t(TRANSLATIONS.ward_ask_to_add_contact);
    }

    return (
      <MyCard
        style={{ margin: 'auto' }}
        translator={props.translator}
        title="ward_card_title"
        useBg={true}
        picture={ward.picture}
        avatar={ward.avatar}
        name={ward.username}
        wish={wish}
        ad={<BoxberryAd />}
        buttons={
          <>
            <MyCardAction icon={'star'} onClick={() => setShowAddress(false)}>
              {t(TRANSLATIONS.card_back_to_wish)}
            </MyCardAction>
            {giftAction}
            {openChatAction}
          </>
        }
      />
    );
  }

  if (useWish && !wardWish) {
    return (
      <MyCard
        style={{
          margin: 'auto',
        }}
        translator={props.translator}
        title="ward_card_title"
        useBg={true}
        picture={ward.picture}
        avatar={ward.avatar}
        name={ward.username}
        wish={t(TRANSLATIONS.ward_no_wish_yet_title)}
        buttons={
          <>
            {showAddressAction}
            {giftAction}
            {openChatAction}
          </>
        }
      />
    );
  }

  return (
    <MyCard
      style={{ margin: 'auto' }}
      translator={props.translator}
      title="ward_card_title"
      useBg={true}
      picture={ward.picture}
      avatar={ward.avatar}
      name={ward.username}
      wishHint={wardWish && useWish ? t(TRANSLATIONS.ward_wish_title) : null}
      wish={useWish && wardWish ? wardWish : !useWish ? t(TRANSLATIONS.ward_no_wish_allowed) : null}
      buttons={
        <>
          {showAddressAction}
          {giftAction}
          {openChatAction}
        </>
      }
    />
  );
}
