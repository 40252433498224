import { BoxPageProps } from '@src/pages/box/BoxPage';
import { ButtonMain, ColumnStart, FormPageGroup, Hint, HintError, RowEnd } from '@src/components';
import * as React from 'react';
import { useState } from 'react';
import { santaClient } from '@src/state/client';
import { loadBoxInfo, loadNotifications, loadWard } from '@src/state';
import { randomize } from '@lub0v/santa-utils';

export function BoxDrawProblemAdditionalDraw(props: BoxPageProps) {
  const cards = props.box.cards.filter((c) => !c.wardId);
  if (cards.length < 2) {
    return null;
  }
  const [drawConfirm, setDrawConfirm] = useState(false);
  const [error, setError] = useState(undefined);

  const drawCards = async () => {
    const rCards = cards.map((c) => ({
      id: `${c.id}`,
    }));
    try {
      const result = randomize(rCards);
      const { key } = props.box.info.box;
      const res = await santaClient.draw({
        key,
        cards: result.map((r) => ({ id: +r.id, wardId: +r.wardId })),
      });
      if (res.ok) {
        setError(undefined);
        await loadBoxInfo(key);
        await loadWard(key);
        await loadNotifications();
      } else {
        setError(res.error.message);
      }
    } catch (e) {
      setError('RANDOMIZER_FAILED');
    }
  };

  return (
    <FormPageGroup title="draw_problem_additional_title" translator={props.translator}>
      <ColumnStart>
        <Hint>draw_problem_additional_hint</Hint>
        <HintError errors={[error]} />
        <RowEnd>
          <ButtonMain
            onClick={async () => {
              if (drawConfirm) {
                await drawCards();
              } else {
                setDrawConfirm(true);
              }
            }}
          >
            {drawConfirm ? 'draw_button_confirm' : 'draw_button'}
          </ButtonMain>
        </RowEnd>
      </ColumnStart>
    </FormPageGroup>
  );
}
