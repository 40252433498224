import _ from 'lodash-es';

// todo move to @lub0v/react-utils

export function combineReducersWithRoot(rootReducer, reducers) {
  return (state, action) => {
    const rt = rootReducer(state, action);
    const newState = { ...rt };
    Object.keys(reducers).forEach((domain) => {
      const obj = newState ? newState[domain] : undefined;
      newState[domain] = reducers[domain](obj, action);
    });
    return newState;
  };
}

function customizer(objValue, srcValue): any {
  if (Array.isArray(objValue)) {
    return srcValue;
  }
  return undefined;
}

export function updateState(state, newState) {
  return _.mergeWith(_.cloneDeep(state), newState, customizer);
}
