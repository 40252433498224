import * as React from 'react';
import { useEffect, useState } from 'react';
import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ColumnStart,
  GridColumn,
  GridContainer,
  Heading,
  HintError,
  SwitchPanel,
  Tip,
} from '@src/components';
import { getBoxPath } from '@src/pages/box/boxHelpers';
import { CardInfo } from '@lub0v/santa-utils';
import { DrawActiveNotice } from '@src/pages/box/draw/DrawActiveNotice';
import { hasExtendedSettings } from '@src/pages/box/draw/drawHelpers';
import { DrawExtendedSettings } from '@src/pages/box/draw/DrawExtendedSettings';
import { DrawButton } from '@src/pages/box/draw/DrawButton';
import { BoxDrawProblemComponent } from '@src/pages/box/draw/BoxDrawProblem';
import { LoadingCardSmall } from '@src/loading/LoadingComponents';
import { Redirect } from 'react-router';
import { DrawParticipantsListSimple } from '@src/pages/box/draw/DrawParticipantsListSimple';
import { DrawParticipantsListExtended } from '@src/pages/box/draw/DrawParticipantsListExtended';

export const BoxDrawComponent = (props: BoxPageProps) => {
  const [extended, setExtended] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (props.box.cards.length && extended === undefined) {
      setIsMounted(true);
      setExtended(hasExtendedSettings(props));
    }
  }, [props.box.cards]);

  const hasNoWards = props.box.info.isActiveDraw && !!props.box.cards.find((c) => !c.wardId);
  if (hasNoWards) {
    return <BoxDrawProblemComponent {...props} />;
  }

  if (props.box.info.isActiveDraw) {
    return <DrawActiveNotice {...props} />;
  }

  if (props.box.info.participantsNumber < 3) {
    return <Redirect to={getBoxPath('/')} />;
  }

  const hasUnverified = props.box.cards.find((c: CardInfo) => !c.isVerified);
  return (
    <ColumnStart translator={props.translator} style={{ height: '100%' }}>
      <Heading
        translator={props.translator}
        right={<DrawButton {...props} onSetError={setError} />}
      >
        draw_title
      </Heading>
      {error ? <HintError center style={{ marginBottom: '2rem' }} errors={[error]} /> : null}

      <GridContainer spacing={props.css.mobile ? 2 : 3}>
        <GridColumn key={`col-1`} xs={12} sm={12} md={12} lg={4}>
          {hasUnverified ? (
            <Tip yellow icon="info" noIcon>
              draw_warning_unverified
            </Tip>
          ) : null}
          <SwitchPanel
            value={extended}
            title="draw_extended_settings_title"
            hint={extended ? 'draw_extended_settings_hint' : null}
            onChange={setExtended}
          />
          {extended ? <DrawExtendedSettings {...props} onSetError={setError} /> : null}
        </GridColumn>
        <GridColumn
          xs={12}
          sm={12}
          md={12}
          lg={8}
          style={
            props.box.cards.length > 5
              ? { minHeight: '30rem', maxHeight: '100%' }
              : { minHeight: '20rem' }
          }
        >
          {!isMounted ? (
            <>
              <GridColumn xs={12} sm={6} md={6} lg={6}>
                <LoadingCardSmall />
              </GridColumn>
              <GridColumn xs={12} sm={6} md={6} lg={6}>
                <LoadingCardSmall />
              </GridColumn>
            </>
          ) : extended ? (
            <DrawParticipantsListExtended {...props} onError={setError} />
          ) : (
            <DrawParticipantsListSimple {...props} />
          )}
        </GridColumn>
      </GridContainer>
    </ColumnStart>
  );
};
