import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonMain,
  ColumnStart,
  FormPageGroup,
  GridColumn,
  GridContainer,
  Hint,
  HintError,
  RowEnd,
  Select,
  UserCardMini,
  UserCardMiniSimple,
} from '@src/components';
import * as React from 'react';
import { useState } from 'react';
import { t, TRANSLATIONS } from '@src/translations';
import { santaClient } from '@src/state/client';
import { loadBoxInfo, loadNotifications } from '@src/state';

export function BoxDrawSwapWard(props: BoxPageProps) {
  const noWards = props.box.cards.filter((c) => !c.wardId);
  if (noWards.length !== 1) {
    return null;
  }
  const card = noWards[0];
  const [wardId, setWardId] = useState(0);
  const [errorNewWard, setErrorNewWard] = useState(undefined);

  const santa = wardId > 0 ? props.box.cards.find((c) => +c.wardId === +wardId) : null;

  const getWardOptions = () => {
    const DRAW_SELECT_WARD = t(TRANSLATIONS.draw_select_ward);
    const options = props.box.cards
      .filter((c) => +c.id !== +card.id)
      .map((c) => ({
        key: c.id,
        value: c.username,
      }));
    return [{ key: '0', value: DRAW_SELECT_WARD }, ...options];
  };

  const saveWard = async () => {
    const r = await santaClient.updateOtherCard({
      id: card.id,
      key: props.box.info.box.key,
      wardId,
    });
    if (r.ok) {
      props.history.push(`/box/${props.box.info.box.key}`);
      await loadBoxInfo(props.box.info.box.key, props.box.join);
      await loadNotifications();
    } else {
      setErrorNewWard(r.error.message);
    }
  };

  return (
    <FormPageGroup title="draw_problem_manual_title" translator={props.translator}>
      <ColumnStart>
        <Hint>draw_problem_manual_hint</Hint>
        <GridContainer style={{ marginBottom: '1rem', marginTop: '1rem' }} spacing={3}>
          <GridColumn xs={12} sm={6} md={6} lg={6}>
            <UserCardMini picture={card.picture} name={card.username} verified line />
          </GridColumn>

          <GridColumn xs={12} sm={6} md={6} lg={6}>
            <UserCardMiniSimple>
              <Select
                options={getWardOptions()}
                value={wardId}
                onChange={(wId) => {
                  setWardId(wId);
                }}
              />
            </UserCardMiniSimple>
          </GridColumn>
          {santa ? (
            <>
              <GridColumn xs={12} sm={6} md={6} lg={6}>
                <UserCardMini picture={santa.picture} name={santa.username} verified line />
              </GridColumn>
              <GridColumn xs={12} sm={6} md={6} lg={6}>
                <UserCardMini picture={card.picture} name={card.username} verified />
              </GridColumn>
              <HintError errors={[errorNewWard]} />
              <RowEnd>
                <ButtonMain onClick={saveWard}>Сохранить</ButtonMain>
              </RowEnd>
            </>
          ) : null}
        </GridContainer>
      </ColumnStart>
    </FormPageGroup>
  );
}
