import * as React from 'react';
import { Header, Layout1 } from '@src/components';
import { t } from '@src/translations';
import { HeaderComponent } from '@src/pages/base/HeaderComponent';
import { FooterComponent } from '@src/pages/base/FooterComponent';
import { LoadingBarSmall } from '@src/loading/LoadingComponents';
import { BoxUnauthorized } from '@src/pages/box/parts/BoxUnauthorized';
import { BoxPageProps } from '@src/pages/box/BoxPage';

export function BoxPageUnauthorizedComponent(props: BoxPageProps) {
  return (
    <Layout1
      translator={t}
      headerFixed
      header={
        <Header shadow={false} link="/">
          {props.loading ? <LoadingBarSmall /> : <HeaderComponent {...props} />}
        </Header>
      }
      footer={<FooterComponent />}
    >
      <BoxUnauthorized {...props} />
    </Layout1>
  );
}
