import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import {
  ButtonDark,
  ColumnStart,
  FormCard,
  Input,
  RowSpaceBetween,
  RowStart,
  TextMedium,
  TextSecondaryMedium,
  Tip,
} from '@src/components';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { validateField } from '@src/utils/validators';
import { ResendGetNotificationsInput, ResendNotificationsInfo } from '@lub0v/santa-utils';
import { santaClient } from '@src/state/client';
import { ButtonService } from '@lub0v/ui-components';
import * as moment from 'moment';
import { t, TRANSLATIONS } from '@src/translations';
import { reachGoalYaMetrika } from '@src/utils/metrika';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface ResendPageProps extends BasePageProps {
  resend: ResendGetNotificationsInput;
}

function ResendPageComponent(props: ResendPageProps) {
  const [error, setError] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const findEmails = async () => {
    setError(undefined);
    const res = await santaClient.getResendNotifications(props.resend);
    if (res.ok) {
      const notifs = res.data;
      if (!notifs.length) {
        setError('resend_no_notifications_found');
        return;
      }
      const sorted = notifs;
      sorted.sort((a, b) => (a.notificationId > b.notificationId ? -1 : 1));
      setNotifications(sorted);
      const hasSending = sorted.find((n) => n.isSending);
      if (hasSending) {
        setTimeout(findEmails, 5000);
      }
    } else {
      setError(res.error);
    }
  };

  const resendEmail = async (notificationId) => {
    setError(undefined);
    reachGoalYaMetrika('resend_email_clicked');
    const res = await santaClient.resendNotifications({ notificationIds: [notificationId] });
    if (res.ok) {
      await findEmails();
    } else {
      setError(res.error);
    }
  };

  if (notifications.length) {
    return (
      <FormCard
        translator={props.translator}
        title="resend_title"
        onBack={() => {
          setNotifications([]);
        }}
        btnRightTitle="resend_done"
        btnLeft={
          <ButtonDark
            translator={props.translator}
            onClick={() => {
              setNotifications([]);
            }}
          >
            resend_back
          </ButtonDark>
        }
        error={error}
      >
        <Tip blueberry icon="info">
          resend_tip2
        </Tip>
        <ColumnStart>
          {notifications.map((notification: ResendNotificationsInfo) => {
            const wasRecentlySent = new Date().getTime() - notification.sentAt < 1000 * 60 * 20;

            let button = null;
            if (notification.resentCounter > 1) {
              button = (
                <ButtonService disabled style={{ marginRight: 0 }}>
                  {t(TRANSLATIONS.resend_counter, { count: notification.resentCounter })}
                </ButtonService>
              );
            } else if (notification.isSending) {
              button = (
                <ButtonService disabled style={{ marginRight: 0 }} translator={props.translator}>
                  resend_sending
                </ButtonService>
              );
            } else if (wasRecentlySent) {
              button = (
                <ButtonService disabled style={{ marginRight: 0 }} translator={props.translator}>
                  resend_sent_recently
                </ButtonService>
              );
            } else {
              button = (
                <ButtonService
                  disabled={notification.resentCounter > 1 || wasRecentlySent}
                  onClick={() => resendEmail(notification.notificationId)}
                  style={{ marginRight: 0 }}
                  translator={props.translator}
                >
                  resend_send
                </ButtonService>
              );
            }

            const sentAt = `${moment(notification.sentAt).format('DD/MM/YYYY')} ${moment(
              notification.sentAt,
            ).format('hh:mm:ss')}`;
            return (
              <RowSpaceBetween key={notification.notificationId} style={{ marginTop: '1rem' }}>
                <ColumnStart>
                  <RowStart style={{ marginBottom: '0.1rem' }}>
                    <TextMedium>{notification.title}</TextMedium>{' '}
                  </RowStart>
                  <RowStart>
                    <TextSecondaryMedium grey>
                      {t(TRANSLATIONS.resend_sent, {
                        at: sentAt,
                      })}
                    </TextSecondaryMedium>
                    {notification.isViewed ? (
                      <TextSecondaryMedium green style={{ marginLeft: '0.5rem' }}>
                        resend_viewed
                      </TextSecondaryMedium>
                    ) : null}
                  </RowStart>
                </ColumnStart>
                {button}
              </RowSpaceBetween>
            );
          })}
        </ColumnStart>
      </FormCard>
    );
  }
  return (
    <FormCard
      translator={props.translator}
      title="resend_title"
      onBack={() => {
        props.history.goBack();
      }}
      btnLeft={
        <ButtonDark
          translator={props.translator}
          onClick={() => {
            props.history.goBack();
          }}
        >
          resend_back
        </ButtonDark>
      }
      btnRightTitle="resend_next"
      onSubmit={findEmails}
      error={error}
    >
      <Tip blueberry icon="info">
        resend_tip
      </Tip>
      <Input
        label="your_email"
        value={props.resend.email}
        validators={[validateField(props.resend, 'email')]}
        onChange={(email) => {
          setError(undefined);
          dispatchActionAppUpdateState({ resend: { email } });
        }}
      />
      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </FormCard>
  );
}

function mapStateToProps(state: SantaAppState) {
  return {
    resend: state.resend,
    ...mapStateToPropsBasePage(state),
  };
}

export const ResendEmailPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(ResendPageComponent, { noFooter: true, wide: true }),
);
