import * as React from 'react';
import { useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { actionAppUpdateState, SantaAppState, store } from '@src/state';
import { validateField } from '@src/utils/validators';
import { santaClient } from '@src/state/client';
import { ResetPasswordInput, SantaApiResult } from '@lub0v/santa-utils';
import {
  ColumnCenter,
  ErrorForm,
  FormAuth,
  Input,
  PictureNotice,
  Validator,
  SantaLink,
  ButtonMain,
  BearWithRaisedHands,
  FormCard,
} from '@src/components';
import { t, TRANSLATIONS } from '@src/translations';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface ResetPasswordPageProps extends BasePageProps {
  reset: ResetPasswordInput;
  urlParams: any;
}

function ResetPasswordPageComponent(props: ResetPasswordPageProps) {
  const [passwordReset, setPassReset] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState(undefined);
  /* ******* methods ******* */

  const doReset = async () => {
    const res: SantaApiResult = await santaClient.resetPassword(props.reset);
    if (res.ok) {
      setPassReset(true);
    } else if (res.error.name === 'ValidationError') {
      const err = res.error.errors[0];
      setError(t(TRANSLATIONS[err.transKey], { field: err.field, ...err.params }));
    } else {
      setError(res.error.message);
    }
  };

  const validateSamePasswords: Validator = (val: string) => {
    const { reset } = props;
    if (!reset.password || !passwordConfirm) {
      return null;
    }
    if (reset.password === val && passwordConfirm === val) {
      return null;
    }
    return 'validations.passwordsDiffer';
  };
  if (!props.urlParams.a) {
    return (
      <ErrorForm errors={['PASSWORD_RESET_WRONG_PARAMETERS']} translator={props.translator}>
        <SantaLink to="/recover">
          <ButtonMain>reset_password_try_again</ButtonMain>
        </SantaLink>
      </ErrorForm>
    );
  }
  /* *********************** */
  return passwordReset ? (
    <ColumnCenter style={{ marginTop: '5rem' }}>
      <PictureNotice
        translator={props.translator}
        title="reset_password_done"
        hint="reset_password_done_hint"
        button="log_in_to_site"
        onClick={() => {
          props.history.push('/login');
        }}
      >
        <BearWithRaisedHands />
      </PictureNotice>
      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </ColumnCenter>
  ) : (
    <>
      <FormAuth
        title="reset_password"
        button="reset_password_button"
        translator={props.translator}
        error={error}
        onSubmit={doReset}
      >
        <Input
          label="new_password"
          password
          value={props.reset.password}
          validators={[validateField(props.reset, 'password'), validateSamePasswords]}
          onChange={(password) => {
            setError(undefined);
            store.dispatch(actionAppUpdateState({ reset: { password } }));
          }}
        />
        <Input
          label="confirm_password"
          password
          value={passwordConfirm}
          validators={[validateField(props.reset, 'password'), validateSamePasswords]}
          onChange={(password) => {
            setError(undefined);
            setPasswordConfirm(password);
          }}
        />
      </FormAuth>
      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    reset: state.reset,
    urlParams: state.urlParams,
  };
}

export const ResetPasswordPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(ResetPasswordPageComponent),
);
