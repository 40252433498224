import { BoxPageProps } from '@src/pages/box/BoxPage';
import {
  ButtonMain,
  ColumnStart,
  FormPageGroup,
  GridColumn,
  GridContainer,
  Hint,
  HintError,
  RowEnd,
  Select,
  TextMedium,
  UserCardMini,
  UserCardMiniSimple,
} from '@src/components';
import * as React from 'react';
import { useState } from 'react';
import { t, TRANSLATIONS } from '@src/translations';
import { santaClient } from '@src/state/client';
import { dispatchActionAppUpdateState, loadBoxInfo, loadNotifications } from '@src/state';

export function BoxDrawProblemReset(props: BoxPageProps) {
  const [isResetting, setIsResetting] = useState(false);

  const setConfirmResetDraw = (confirmResetDraw: boolean) => {
    dispatchActionAppUpdateState({
      modal: {
        opened: confirmResetDraw,
        title: 'modal_reset_draw_title',
        message: 'modal_reset_draw_message',
        buttonYes: 'modal_reset_draw_button_yes',
        buttonNo: 'modal_cancel',
        loading: false,
        onCancel: () => {
          setConfirmResetDraw(false);
        },
        onSubmit: async () => {
          if (!isResetting) {
            setIsResetting(true);
            dispatchActionAppUpdateState({ modal: { loading: true } });
            const r = await santaClient.resetDraw({ key: props.box.info.box.key });
            if (r.ok) {
              setConfirmResetDraw(false);
              await loadBoxInfo(props.box.info.box.key);
              setIsResetting(false);
            }
          }
        },
      },
    });
  };

  return (
    <FormPageGroup title="draw_problem_reset_title" translator={props.translator}>
      <ColumnStart>
        <Hint>draw_problem_reset_hint</Hint>
        <RowEnd>
          <ButtonMain onClick={() => setConfirmResetDraw(true)}>
            draw_active_button_reset
          </ButtonMain>
        </RowEnd>
      </ColumnStart>
    </FormPageGroup>
  );
}
