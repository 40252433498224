import * as React from 'react';

import { _ } from '@src/translations/utils.translations';

export const DONATE_TRANSLATIONS = {
  donate_title_site_improving_by: _(
    'Сайт разрабатывается и улучшается',
    'Site developed and enhanced by',
  ),
  donate_liubov: _('Любовью', 'Liubov'),
  donate_with_love: _('с любовью для вас!', 'with love for you!'),
  donate_message: _(
    <>
      Этот сайт абсолютно бесплатный и держится лишь на моем энтузиазме и любви к людям. Чтобы он
      исправно работал и был симпатичным, я плачу за сервера, CDN, за работу почты и за дизайн. На
      сайте появилась реклама, но пока что она не приносит достаточного дохода для полноценной
      поддержки сайта.
      <br />
      <br />
      Ваши пожертвования позволяют сайту развиваться, спасибо!
      <br />
      <br />
      Или, вы можете просмотреть рекламу, которая, возможно, появится под формой.
    </>,
    <>
      This site is absolutely free and is maintained only by my enthusiasm and love for people. To
      keep it running smoothly and looking great, I pay for servers, CDN, email services, and
      design. Advertising has been added to the site, but so far it does not generate enough revenue
      for full support of the site.
      <br />
      <br />
      Your donations allow the site to develop, thank you!
      <br />
      <br />
      Or, you can view the advertisement, which might appear right under the form.
    </>,
  ),
  donate_method_pc: _('Yoo Money', 'Yoo Money'),
  donate_method_ac: _('Банковская карта', 'Bank Card'),
  donate_method_mc: _('Cчет мобильного', 'Mobile Account'),
  donate_method_pp: _('PayPal', 'PayPal'),
  donate_other_amount: _('Другая сумма в рублях', 'Other amount in Rubles'),
  donate_err: _('Пожалуйста, укажите сумму.', 'Please specify the amount.'),
  donate_curr: _(' ₽', ' ₽'),
};
