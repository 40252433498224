import * as React from 'react';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { FormCard, RowCenter, TextMedium, TextSecondaryMedium, Tip } from '@src/components';
import { NewBoxProps } from '@src/pages/new_box/NewBoxProps';
import { RegisterInput, SantaApiResult } from '@lub0v/santa-utils';
import { RegisterComponent } from '@src/pages/auth/RegisterComponent';
import { santaClient } from '@src/state/client';

function NewBoxStep0Component(props: NewBoxProps & { register: RegisterInput }) {
  const [error, setError] = useState(undefined);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = async () => {
    const res: SantaApiResult = await santaClient.register({
      ...props.register,
      redirect: '/account/boxes',
    });
    if (res.ok) {
      dispatchActionAppUpdateState({
        register: { email: null, username: null, redirect: null },
        boxCreate: { input: { email: props.register.email } },
      });
      props.goNext();
    } else {
      setError(res.error.message);
    }
  };

  return (
    <FormCard
      title="new_box_step0_title"
      translator={props.translator}
      step={''}
      onSubmit={onSubmit}
      onBack={props.goBack}
      error={error}
    >
      <Tip blueberry icon="info">
        new_box_step0_tip
      </Tip>
      <RegisterComponent {...props} style={{ maxWidth: '30rem', margin: 'auto' }} />
      <RowCenter>
        <TextSecondaryMedium grey>register_policy_agreement_hint</TextSecondaryMedium>
      </RowCenter>
    </FormCard>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    register: state.register,
    boxCreate: state.boxCreate,
  };
}

export const NewBoxStep0 = connect(mapStateToProps)(NewBoxStep0Component);
